import React, { useState, useEffect } from "react";
import { Modal } from "react-bootstrap";
import axios from "../../components/axios";
import useFullPageLoader from "../../components/useFullPageLoader";
import Swal from "sweetalert2";
import { useNavigate, Link } from "react-router-dom";
import { useUser } from "../../components/UserContext";
import JSZip from 'jszip';
import FileSaver from 'file-saver';



const AgentCustomer = ({ open, onClose, updateData,actionType }) => {
    const { userData } = useUser();
    const [loader, showLoader, hideLoader] = useFullPageLoader();
    const [show, setShow] = useState(open);
    const [errors, setErrors] = useState({});
    const navigate = useNavigate();
    const [documentName, setDocument] = useState([]);
    const [data, SetData] = useState();
    const [check, setCheck] = useState();

    console.log(updateData)

    const [customer, setCustomer] = useState({
        Customer_Company: "",
        Remarks: ""
    });

    const handleClose = () => {
        setShow(false);
        onClose();
    };

    const handleSubmit = async (event) => {
      event.preventDefault();
    
      showLoader();
      try {
        await axios
          .post(`/addAgentCustomerDetails/${updateData.Enquiry_Sr_No}`, customer)
          .then((res) => {
            Swal.fire("Success!", "Customer Details Added Successfully!!", "success").then(
              (result) => {
                if (result.isConfirmed) {
                  handleClose();
                }
              }
            );
            hideLoader();
          });
      } catch (err) {
        // error("Server is Busy!!");
        Swal.fire({
          title: "Error",
          text: "Failed to add Customer Details. Please try again later.",
          icon: "error",
          confirmButtonText: "OK",
        });
        hideLoader();
      }
    };
    
    
    

    return (
        <Modal
            show={show}
            onHide={handleClose}
            size="xl"
            centered
            style={{ boxShadow: "none !important" }}
        >

            <Modal.Body>
                <div className="row align-items-center">
                    <h5 className="col-md-6">Add Customer Details - ({updateData.Certificate_Name||""})</h5>
                    <div className="col-md-6 text-end d-none">
                        {/* <div className="mb-3">
                            <span className="text-dark">Download All Documents Zip : </span>
                            {customer.Documents.length > 0 ? (
                                <>
                                    <a style={{ color: "blue", cursor: 'pointer' }} download={true} onClick={handleDownloadAllDocuments} className="cursor-pointer">
                                        <i className="ti ti-download"></i>
                                    </a>
                                </>
                            ) : (
                                <>
                                    <i className="ti ti-download-off"></i>
                                </>
                            )}
                        </div> */}
                    </div>
                </div>
                <hr className="p-0 mb-1" />
                <form onSubmit={handleSubmit}>
                    <div className="row">
                    <div className="col-md-6">
                    <div className="mb-2">
                      <label htmlFor="Customer_Company" className="form-label">
                      Company Name<span style={{ color: "red" }}>*</span>
                      </label>
                      <input
                        type="text"
                        id="Customer_Company"
                        className="form-control"
                        placeholder="Enter Company Name"
                        value={customer.Customer_Company}
                        required
                        onChange={(e) =>{
                            const input = e.target.value;
                            setCustomer({
                            ...customer,
                            Customer_Company: input,
                          })
                        }
                         
                        }
                      />
                    </div>
                  </div>
                  <div className="col-md-6">
  <div className="mb-2">
    <label htmlFor="Remarks" className="form-label">
      Remarks
    </label>
    <textarea
      id="Remarks"
      className="form-control"
      placeholder="Enter Remarks"
      required
      value={customer.Remarks}
      onChange={(e) => {
        const input = e.target.value;
        setCustomer({
          ...customer,
          Remarks: input,
        });
      }}
    />
  </div>
</div>

                    </div>
                    <div className="row justify-content-center mt-4">
  <div className="col-md-2 col-sm-3 col-xs-3 col-3 d-flex justify-content-center">
    <button type="submit" className="btn btn-primary" >
Add 
       </button>
  </div>
</div>
                </form>

                {loader}
            </Modal.Body>

            {/* <div className="text-danger p-3">
                Note: Upload documents less than 2MB in size and please ensure that the files are in jpg, jpeg, png, doc, docx, xls, xlsx or pdf formats.
            </div> */}

        </Modal>
    );
}
export default AgentCustomer;
