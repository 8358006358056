import React, { useState } from 'react';
import { Modal } from 'react-bootstrap';
import axios from '../../components/axios';
import { useUser } from '../../components/UserContext';
import useFullPageLoader from '../../components/useFullPageLoader';
import Swal from 'sweetalert2';

const CustomPasswordModal = ({ showModal, onClose, Sr_No, Name, UserId , actionType}) => {
  const [password, setPassword] = useState('');
  const [showPassword, setShowPassword] = useState(false);
  const [error, setError] = useState('');
  const { userData } = useUser();
  const [loader, showLoader, hideLoader] = useFullPageLoader();

  const handleTogglePasswordVisibility = () => {
    setShowPassword(!showPassword);
  };

  const handlePasswordChange = (e) => {
    setPassword(e.target.value);
  };

  // Check if the password is valid by making an API request
  const checkPassword = async (password, UserId) => {
    showLoader(); // Show loader before making API call

    try {
      const response = await axios.post('/validatePassword', { password, UserId });
      return response.data.isValid;
    } catch (error) {
      console.error('Error validating password:', error);
      return false;
    } finally {
      hideLoader(); // Hide loader after the API call completes (whether successful or not)
    }
  };
  // Function to handle password confirmation and deletion
  const handleConfirmPassword = async () => {
    if (!password) {
      setError('Please enter your password');
      return;
    }

    setError(''); // Reset error message

    const isPasswordValid = await checkPassword(password, UserId);

    if (isPasswordValid) {
      if (actionType === 'deleteUser') {
        await handleDelete(Sr_No, Name); // Call handleDelete if the action type is deleteUser
      } else if (actionType === 'deleteAnnualReturn') {
        await handleDeleteAnnualReturn(Sr_No, Name); // Call handleDeleteAnnualReturn if action type is deleteAnnualReturn
      }
      onClose(); // Close the modal after successful action
    } else {
      setError('Incorrect password');
    }
  };


  // Function to handle deletion of the user
  const handleDelete = async (Sr_No, Name) => {
    showLoader();
    const obj = {
      Org_Id: userData.Org_Id,
      User_Role: userData.Role,
      UserName: userData.Username,
      Added_By_Id: userData.UserId,
      Added_By: `${userData.First_Name} ${userData.Last_Name}`,
      Name: Name,
    };
    try {
      await axios.delete(`/deleteCustomerEnquiries/${Sr_No}`, { data: obj });

      Swal.fire("Deleted!", "Customer Enquiry has been deleted.", "success");
      setPassword('')
    } catch (error) {
      console.error(error);
      Swal.fire(
        "Error!",
        "An error occurred while deleting the user.",
        "error"
      );
    } finally {
      hideLoader();
    }
  };


  const handleDeleteAnnualReturn = async (Sr_No, Name) => {
    showLoader();
    const obj = {
      Org_Id: userData.Org_Id,
      User_Role: userData.Role,
      UserName: userData.Username,
      Added_By_Id: userData.UserId,
      Added_By: `${userData.First_Name} ${userData.Last_Name}`,
      Name: Name,
    };
    try {
      await axios.delete(`/deleteAnnualReturns/${Sr_No}`, { data: obj });

      Swal.fire("Deleted!", "Annual Return has been deleted.", "success");
      setPassword('')
    } catch (error) {
      console.error(error);
      Swal.fire(
        "Error!",
        "An error occurred while deleting the user.",
        "error"
      );
    } finally {
      hideLoader();
    }
  };
  

  if (!showModal) return null;  // Don't render modal if showModal is false

  return (
    <Modal
      show={showModal}
      onHide={onClose}
      size="md"  // Set modal size to 'md' for medium size
      centered
      style={{ boxShadow: 'none !important' }}
    >
      <Modal.Body>
        <div className="row align-items-center">
          <h5 className="col-md-12">Confirm Your Password</h5>
        </div>
        <hr className="p-0 mb-1" />
        <div className="row">
        <div className="col-md-12">
      <div className="form-group mb-3">
        <label htmlFor="password" className="form-label">
          Enter your password <span style={{ color: 'red' }}>*</span>
        </label>
        <div className="input-group">
          <input
            type={showPassword ? 'text' : 'password'} // Toggle input type
            className="form-control"
            id="password"
            value={password}
            onChange={handlePasswordChange}
            placeholder="Enter your password"
            required
            autoComplete="off"
           />
          {/* Password visibility toggle icon inside the input field */}
          <span
            className="input-group-text"
            style={{ cursor: 'pointer' }}
            onClick={handleTogglePasswordVisibility}
          >
            {showPassword ? (
              <i className="ti ti-eye-off"></i>
            ) : (
              <i className="ti ti-eye"></i>
            )}
          </span>
        </div>
        {error && <p className="text-danger mt-2">{error}</p>}
      </div>
    </div>
        </div>
        <div className="row justify-content-center">
  <div className="col-4">
    <button type="button" className="btn btn-md btn-primary w-100" onClick={handleConfirmPassword}>
      Confirm
    </button>
  </div>
  <div className="col-4">
    <button type="button" className="btn btn-md btn-secondary w-100" onClick={onClose}>
      Cancel
    </button>
  </div>
</div>

        {loader}
      </Modal.Body>
    </Modal>
  );
};

export default CustomPasswordModal;
