import React, { useEffect } from "react";
import { useState } from "react";
import axios from "./axios";
import { Nav, NavDropdown } from 'react-bootstrap';
import { Link, Outlet, useNavigate } from "react-router-dom";
import ChangePasswordModal from "./ChangePasswordModal";
import ChangeCustomerPassword from "./ChangeCustomerPassword";
import { useUser } from "./UserContext";
import { apiUrl } from "./axios";
import useFullPageLoader from "./useFullPageLoader";
import $ from "jquery";
import UpdateCustomerProfile from "./UpdateCustomerProfile";
import Apply from "./Apply";

const Dashboard = () => {
  const [loader, showLoader, hideLoader] = useFullPageLoader();
  const navigate = useNavigate();
  const { setData } = useUser();
  const [userData, setUserData] = useState(null);
  const { setStaff } = useUser();
  const { staffData, setStaffData } = useState(null)
  const [IsModal, setIsModal] = useState(null);
  const [isModal2, setIsModal2] = useState(false);
  const [isModal3, setIsModal3] = useState(false);


  axios.defaults.withCredentials = true;
  axios.defaults.baseURL = `${apiUrl}/`;

  useEffect(() => {
    showLoader();  // Assuming you have a function to show a loading indicator
    axios.get(`/dashboard`)
      .then((res) => {
        if (res.data.Success === "Success") {
          const userData = {
            UserId: res.data.UserId ? res.data.UserId : res.data.Sr_No,
            Agent_Id: res.data.Sr_No,
            AgentName: res.data.Role ==="Agent" ? res.data.Name : null,
            AgentMobile: res.data.Role ==="Agent" ? res.data.Mobile : null,
            AgentCompany: res.data.Role ==="Agent" ? res.data.Company_Name : null,
            AgentEmail: res.data.Role ==="Agent" ? res.data.Email : null,
            AgentStaff1Name: res.data.Role ==="Agent" ? res.data.Staff_1_Name : null,
            AgentStaff1Mobile: res.data.Role ==="Agent" ? res.data.Staff_1_Mobile : null,
            AgentStaff1Email: res.data.Role ==="Agent" ? res.data.Staff_1_Email : null,
            AgentStaff2Name: res.data.Role ==="Agent" ? res.data.Staff_2_Name : null,
            AgentStaff2Mobile: res.data.Role ==="Agent" ? res.data.Staff_2_Mobile : null,
            AgentStaff2Email: res.data.Role ==="Agent" ? res.data.Staff_1_Email : null,
            AgentAddedBy: res.data.Role ==="Agent" ? res.data.Added_By : null,
            AgentAddedById: res.data.Role ==="Agent" ? res.data.Added_By_Id : null,
            Vendor_Id: res.data.Vendor_Id || null,
            Role: res.data.Role ? res.data.Role : null,
            First_Name: res.data.First_Name || null,
            Last_Name: res.data.Last_Name || null,
            Email: res.data.Email,
            Org_Id: res.data.Org_Id ? res.data.Org_Id : null,
            Status: res.data.Status || null,
            Username: res.data.Username,
            Password: res.data.Password,
            Mobile: res.data.Mobile,
            VendorStaff1Name:res.data.Vendor_Id ? res.data.Staff_1_Name : null,
            VendorStaff1Mobile: res.data.Vendor_Id ?res.data.Staff_1_Mobile : null,
            VendorStaff1Email:res.data.Vendor_Id ? res.data.Staff_1_Email : null,
            VendorStaff2Name:res.data.Vendor_Id ? res.data.Staff_2_Name : null,
            VendorStaff2Mobile:res.data.Vendor_Id ? res.data.Staff_2_Mobile : null,
            VendorStaff2Email:res.data.Vendor_Id ? res.data.Staff_2_Email : null,
            VendorCompany:res.data.Vendor_Id ? res.data.Company_Name : null,
            VendorName:res.data.Vendor_Id ? res.data.Name : null,

          };

          hideLoader();  // Assuming you have a function to hide the loading indicator
          setUserData(userData);
          setData(userData);

          // console.log(userData);

          // If Vendor_Id exists, navigate to /viewEnquiry
          if (res.data.Vendor_Id) {
            navigate("/viewEnquiry");
          }else if(res.data.Role === "Agent"){
            navigate('/agentEnquiries')
          }
        } else {
          navigate("/login");
        }
      })
      .catch((error) => {
        console.error("Error fetching data:", error);
        hideLoader();
      });
  }, []);

  const [isChangePasswordModalOpen, setIsChangePasswordModalOpen] =
    useState(false);

  const handleOpenChangePasswordModal = () => {
    setIsChangePasswordModalOpen(true);
  };

  const handleCloseChangePasswordModal = () => {
    setIsChangePasswordModalOpen(false);
  };

  const handleLogout = () => {
    showLoader();
    axios
      .get(`/logout`)
      .then((res) => {
        setUserData(null);
        setData(null);
        navigate("/login");
      })
      .catch((err) => console.log(err))
      .finally(() => hideLoader());
  };
  
  const handleCustomerLogout = () => {
    showLoader();
    axios
      .get(`/logoutCustomer`)
      .then((res) => {
        setUserData(null);
        setData(null);
        navigate("/login");
      })
      .catch((err) => console.log(err))
      .finally(() => hideLoader());
  };
  
  const handleStaffLogout = () => {
    showLoader();
    axios
      .get(`/logoutStaff`)
      .then((res) => {
        setUserData(null);
        setData(null);
        navigate("/login");
      })
      .catch((err) => console.log(err))
      .finally(() => hideLoader());
  };
  
  const handleVendorLogout = () => {
    showLoader();
    axios
      .get(`/logoutVendor`)
      .then((res) => {
        setUserData(null);
        setData(null);
        navigate("/login");
      })
      .catch((err) => console.log(err))
      .finally(() => hideLoader());
  };
  
  const handleAgentLogout = () => {
    showLoader();
    axios
      .get(`/logoutAgent`)
      .then((res) => {
        setUserData(null);
        setData(null);
        navigate("/login");
      })
      .catch((err) => console.log(err))
      .finally(() => hideLoader());
  };
  


  useEffect(() => {
    const handleSidebarToggle = () => {
      $("body").toggleClass("sidebar-toggled");
      $(".sidebar").toggleClass("toggled");
    };
    $("#sidebarToggle, #sidebarToggleTop").on("click", handleSidebarToggle);
    return () => {
      $("#sidebarToggle, #sidebarToggleTop").off("click", handleSidebarToggle);
    };
  }, []);


  const showModal2 = () => {
    setIsModal2(true);
  };
  const closed2 = () => {
    setIsModal2(false);
    window.location.reload();
  };


  const showModal3 = () => {
    setIsModal3(true);
  };
  const closed3 = () => {
    setIsModal3(false);
    window.location.reload();
  };

  console.log("User Data", userData);
  

  return (
    <div className="layout-wrapper layout-content-navbar  ">
      <div className="layout-container">

        <aside id="layout-menu" className="layout-menu menu-vertical menu bg-menu-theme">
          <div className="app-brand demo ">


            <a className="layout-menu-toggle menu-link text-large ms-auto">
              {/* <i className="ti menu-toggle-icon d-none d-xl-block ti-sm align-middle"></i>
              <i className="ti ti-x d-block d-xl-none ti-sm align-middle"></i> */}
            </a>
          </div>
          <div className="menu-inner-shadow"></div>


          <ul className="menu-inner py-1">
            {userData?.Vendor_Id ?  (
              // Only show Enquiries if Vendor_Id is present
              <>
              <li className="menu-item" style={{marginLeft:"-2px"}}>
                <Link to="/viewEnquiry" className="menu-link">
                  <i className="menu-icon tf-icons ti ti-file-text"></i>
                  <div data-i18n="Enquiries">Enquiries</div>
                </Link>
              </li>
              <li className="menu-item" style={{marginLeft:"-2px"}}>
              <Link to="/viewAnnualReturn" className="menu-link">
                <i className="menu-icon tf-icons ti ti-calendar-event"></i> {/* Suitable icon for Annual Return */}
                <span className="menu-text" style={{ marginLeft: '0px', whiteSpace: 'nowrap' }}>Annual Returns</span> {/* Prevent text wrapping */}
              </Link>
            </li>
            </>

            ) : userData?.Role === "Admin" || userData?.Role === "Staff" ? (
              <>
                <li className="menu-item">
                  <Link to="" className="menu-link">
                    <i className="menu-icon tf-icons ti ti-layout-dashboard"></i>
                    <div data-i18n="Dashboards">Dashboard</div>
                  </Link>
                </li>

                <li className="menu-item">
                  <Link to="/viewUser" className="menu-link">
                    <i className="menu-icon tf-icons ti ti-users"></i>
                    <div data-i18n="User">Admin/Staff</div>
                  </Link>
                </li>

                <li className="menu-item">
                  <Link to="/viewCustomers" className="menu-link">
                    <i className="menu-icon tf-icons ti ti-users-group"></i>
                    <div data-i18n="ViewCustomers">Customers</div>
                  </Link>
                </li>

                <li className="menu-item">
                  <Link to="/viewEnquiry" className="menu-link">
                    <i className="menu-icon tf-icons ti ti-file-text"></i>
                    <div data-i18n="Enquiries">Enquiries</div>
                  </Link>
                </li>

                <li className="menu-item">
                  <Link to="/ViewAllInvoices" className="menu-link">
                    <i className="menu-icon tf-icons ti ti-receipt"></i>
                    <div data-i18n="Invoices">Invoices</div>
                  </Link>
                </li>
                <li className="menu-item">
  <Link to="/viewAnnualReturn" className="menu-link">
    <i className="menu-icon tf-icons ti ti-calendar-event"></i> {/* Suitable icon for Annual Return */}
    <span className="menu-text" style={{ marginLeft: '0px', whiteSpace: 'nowrap' }}>Annual Returns</span> {/* Prevent text wrapping */}
  </Link>
</li>


                <li className="menu-item mb-1">
                  <a className="menu-link collapsed cursor-pointer menu-toggle" data-bs-toggle="collapse" data-bs-target="#vendors-collapse" aria-expanded="false" aria-current="true">
                    &nbsp;<i className="fas fa-store"></i>&nbsp;
                    <div data-i18n="Vendors">&nbsp;Vendors</div>
                  </a>
                  <div className="collapse" id="vendors-collapse" style={{ backgroundColor: 'rgb(243 237 237 / 34%)' }}>
                    <ul className="list-unstyled fw-normal pb-1 small" style={{ paddingLeft: "20px" }}>
                      <li className="menu-item">
                        <Link to="/addVendors" className="menu-link" style={{ margin: "0px -20px" }}>
                          <i className="menu-icon tf-icons ti ti-plus"></i>
                          <div data-i18n="AddVendor" style={{ fontSize: "13px" }}>Add Vendor</div>
                        </Link>
                      </li>
                      <li className="menu-item">
                        <Link to="/viewVendors" className="menu-link" style={{ margin: "0px -20px" }}>
                          <i className="menu-icon tf-icons ti ti-users"></i>
                          <div data-i18n="ViewVendor" style={{ fontSize: "13px" }}>View Vendors</div>
                        </Link>
                      </li>
                      <li className="menu-item">
                        <Link to="/vendorPayment" className="menu-link" style={{ margin: "0px -20px" }}>
                          <i className="menu-icon tf-icons ti ti-wallet"></i>
                          <div data-i18n="VendorPayment" style={{ fontSize: "13px" }}>Vendors Payment</div>
                        </Link>
                      </li>
                    </ul>
                  </div>
                </li>

                <li className="menu-item mb-1">
                  <a className="menu-link collapsed cursor-pointer menu-toggle" data-bs-toggle="collapse" data-bs-target="#agents-collapse" aria-expanded="false" aria-current="true">
                    &nbsp;<i className="fas fa-users-cog"></i>&nbsp;
                    <div data-i18n="Agents">&nbsp;Agents</div>
                  </a>
                  <div className="collapse" id="agents-collapse" style={{ backgroundColor: 'rgb(243 237 237 / 34%)' }}>
                    <ul className="list-unstyled fw-normal pb-1 small" style={{ paddingLeft: "20px" }}>
                      <li className="menu-item">
                        <Link to="/addAgents" className="menu-link" style={{ margin: "0px -20px" }}>
                          <i className="menu-icon tf-icons ti ti-user-plus"></i>
                          <div data-i18n="viewAgents" style={{ fontSize: "13px" }}>Add Agent</div>
                        </Link>
                      </li>
                      <li className="menu-item">
                        <Link to="/viewAgents" className="menu-link" style={{ margin: "0px -20px" }}>
                          <i className="menu-icon tf-icons ti ti-users"></i>
                          <div data-i18n="viewAgents" style={{ fontSize: "13px" }}>View Agents</div>
                        </Link>
                      </li>
                    </ul>
                  </div>
                </li>

                <li className="menu-item mb-1">
                  <a className="menu-link collapsed cursor-pointer menu-toggle" data-bs-toggle="collapse" data-bs-target="#reports-collapse" aria-expanded="false" aria-current="true">
                    &nbsp; <i className="fa fa-chart-bar"></i>
                    <div data-i18n="Reports"> &nbsp; &nbsp;Reports</div>
                  </a>
                  <div className="collapse" id="reports-collapse" style={{ backgroundColor: 'rgb(243 237 237 / 34%)' }}>
                    <ul className="list-unstyled fw-normal pb-1 small" style={{ paddingLeft: "20px" }}>
                      <li className="menu-item">
                        <Link to="/viewAllDuePayments" className="menu-link" style={{ margin: "0px -20px" }}>
                          <i className="menu-icon tf-icons ti ti-alert-circle"></i>
                          <div data-i18n="OverdueReports" style={{ fontSize: "13px" }}>Overdue-Reports</div>
                        </Link>
                      </li>
                    </ul>
                  </div>
                </li>

                <li className="menu-item mb-1">
                  <a className="menu-link collapsed cursor-pointer menu-toggle" data-bs-toggle="collapse" data-bs-target="#components-collapse" aria-expanded="false" aria-current="true">
                    <i className="menu-icon tf-icons ti ti-settings"></i>
                    <div data-i18n="Setting">Settings</div>
                  </a>
                  <div className="collapse" id="components-collapse" style={{ backgroundColor: 'rgb(243 237 237 / 34%)' }}>
                    <ul className="list-unstyled fw-normal pb-1 small" style={{ paddingLeft: "20px" }}>
                      <li className="menu-item">
                        <Link to="/certificateDocument" className="menu-link" style={{ margin: "0px -20px" }}>
                          <i className="menu-icon tf-icons ti ti-file-orientation"></i>
                          <div data-i18n="CertificateDocument" style={{ fontSize: "13px" }}>Certificate-Document</div>
                        </Link>
                      </li>
                      <li className="menu-item">
                        <Link to="/certificateType" className="menu-link" style={{ margin: "0px -20px" }}>
                          <i className="menu-icon tf-icons ti ti-file-certificate"></i>
                          <div data-i18n="CertificateType" style={{ fontSize: "13px" }}>Certificate Type</div>
                        </Link>
                      </li>
                      <li className="menu-item">
                        <Link to="/docType" className="menu-link" style={{ margin: "0px -20px" }}>
                          <i className="menu-icon tf-icons ti ti-clipboard-text"></i>
                          <div data-i18n="DocumentType" style={{ fontSize: "13px" }}>Document Type</div>
                        </Link>
                      </li>

                      <li className="menu-item">
                        <Link to="/bank" className="menu-link" style={{ margin: "0px -20px" }}>
                          <i className="menu-icon tf-icons ti ti-building-bank"></i>
                          <div data-i18n="CertificateDocument" style={{ fontSize: "13px" }}>Bank</div>
                        </Link>
                      </li>
                    </ul>
                  </div>
                </li>

              </>
            ) : (
              <>
{userData?.Agent_Id ? (
  // If userData.Agent_Id exists, show only the View Enquiry menu
  <>
      <li className="menu-item">
      <Link to="#" className="menu-link" style={{ margin: "0px -20px" }}>
      &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;<i className="menu-icon tf-icons ti ti-clipboard-text"></i>
        <div data-i18n="Applications" style={{ fontSize: "13px" }}>
          Your Certificates
        </div>
      </Link>
    </li>  <li className="menu-item" style={{marginLeft:"-12px"}}>
    <Link to="/agentEnquiries" className="menu-link">
      <i className="menu-icon tf-icons ti ti-file-text"></i>
      <div data-i18n="Enquiries">Enquiries</div>
    </Link>
  </li>
  <li className="menu-item" style={{marginLeft:"-12px"}}>
  <Link to="/viewAnnualReturn" className="menu-link">
    <i className="menu-icon tf-icons ti ti-calendar-event"></i> {/* Suitable icon for Annual Return */}
    <span className="menu-text" style={{ marginLeft: '0px', whiteSpace: 'nowrap' }}>Annual Returns</span> {/* Prevent text wrapping */}
  </Link>
</li>
</>
) : (
  <>
    

    <li className="menu-item">
      <Link to="" className="menu-link" style={{ margin: "0px -20px" }}>
        <i className="menu-icon tf-icons ti ti-clipboard-text"></i>
        <div data-i18n="Applications" style={{ fontSize: "13px" }}>
          Your Applications
        </div>
      </Link>
    </li>
    <li className="menu-item">
      <Link to="/customerProfile" className="menu-link">
        <i className="menu-icon tf-icons ti ti-user-circle"></i>
        <div data-i18n="Profile">Profile</div>
      </Link>
    </li>
  </>
)}


                
              </>
            )}
          </ul>


        </aside>

        <div className="layout-page">

          {/* <!-- Navbar --> */}
          <nav className="layout-navbar container-xxl navbar navbar-expand-xl navbar-detached align-items-center bg-navbar-theme" id="layout-navbar">
            <div className="layout-menu-toggle navbar-nav align-items-xl-center me-3 me-xl-0   d-xl-none ">
              <a className="nav-item nav-link px-0 me-xl-4" href="javascript:void(0)">
                <i className="ti ti-menu-2 ti-sm"></i>
              </a>
            </div>

            <div className="navbar-nav-right d-flex align-items-center" id="navbar-collapse">
              <a href="/" className="app-brand-link mt-2 mb-2" style={{ textAlign: 'left', width: '100%', padding: '0', display: 'block', margin: '0' }}>
                <img
                  id="logoimg"
                  className="brand-logo border-1"
                  src={`/assets/img/logo.jpg`}
                  style={{ width: '175px' }}
                />
              </a>
              <ul className="navbar-nav flex-row align-items-center ms-auto">
                {!userData?.Role || userData?.Vendor_Id  && (<button className="btn btn-primary" onClick={showModal3}>Apply</button>)}
                <li className="nav-item navbar-dropdown dropdown-user dropdown">
                  <a className="nav-link dropdown-toggle hide-arrow d-flex align-items-center" data-bs-toggle="dropdown" style={{ cursor: "pointer" }}>
                    <div className="avatar avatar-online me-2">
                      <img src="/assets/img/avatar.png" alt="" className="h-auto rounded-circle" />
                    </div>
                   <span className="align-middle">
  {
    (userData?.Vendor_Id ? userData?.VendorName : 
    `${userData?.First_Name ? userData?.First_Name : ''} ${userData?.Last_Name ? userData?.Last_Name : ""}`)
  }
</span>

                  </a>

                  <ul className="dropdown-menu dropdown-menu-end">
                  {!userData?.Role && !userData?.Agent_Id && !userData?.Vendor_Id && (
  <li>
    <Link className="dropdown-item" onClick={() => showModal2()}>
      <i className="ti ti-user-circle me-2 ti-sm"></i>
      <span className="align-middle">Profile</span>
    </Link>
  </li>
)}

{!userData?.Agent_Id && !userData?.Vendor_Id && (
  <li>
    <Link
      className="dropdown-item"
      onClick={() => {
        handleOpenChangePasswordModal();
        setIsModal(true);
      }}
    >
      <i className="ti ti-key me-2 ti-sm"></i>
      <span className="align-middle">Change Password</span>
    </Link>
  </li>
)}

                    <li>
                    <Link
                      className="dropdown-item"
                      onClick={() => {
                        if (userData?.Vendor_Id) {
                          handleVendorLogout(); // Handle vendor logout if Vendor_Id exists
                        } else if (userData?.Agent_Id) {
                          handleAgentLogout(); // Handle staff logout if Role is Staff
                        } else if (userData?.Role === "Staff") {
                          handleStaffLogout(); // Handle staff logout if Role is Staff
                        } else if (userData?.Role === "Admin") {
                          handleLogout(); // Handle logout for other roles
                        } else {
                          handleCustomerLogout(); // Handle customer logout if no role or vendor id
                        }
                      }}
                    >
                        <i className="ti ti-logout me-2 ti-sm"></i>
                        <span className="align-middle">Log Out</span>
                      </Link>
                    </li>
                  </ul>
                </li>
              </ul>
              </div>
          </nav>
          {/* <!-- / Navbar -->
            <!-- Content wrapper --> */}
          <div className="content-wrapper">
            {/* <!-- Content --> */}

            <div className="container-xxl flex-grow-1 container-p-y">

              <Outlet />

            </div>


            <div className="content-backdrop fade"></div>
          </div>
        </div>
      </div>


      <div className="layout-overlay layout-menu-toggle"></div>


      <div className="drag-target"></div>
      {IsModal && (
        userData?.Role ? (
          <ChangePasswordModal
            open={isChangePasswordModalOpen}
            onClose={handleCloseChangePasswordModal}
            UserId={userData?.UserId}
            Role={userData?.Role}
          />
        ) : (
          <ChangeCustomerPassword
            open={isChangePasswordModalOpen}
            onClose={handleCloseChangePasswordModal}
            UserId={userData?.UserId}
          />
        )
      )}
      {isModal2 && (
        <UpdateCustomerProfile
          open={isModal2}
          onClose={closed2}
        />
      )}
      {isModal3 && (
        <Apply
          open={isModal3}
          onClose={closed3}
          customerData={{}}
        />
      )}
      {loader}
    </div>
  );
};

export default Dashboard;
