import React, { useState,useEffect } from "react";
import axios from "../../components/axios";
import { useUser } from "../../components/UserContext";
import Swal from 'sweetalert2';
import useFullPageLoader from "../../components/useFullPageLoader";
import { useParams, useNavigate } from "react-router-dom";

const AddAgents = () => {

  const { userData } = useUser();   
  const [errors, setErrors] = useState({});
  const [staffData, setStaffData] = useState([]);
  const [loader, showLoader, hideLoader] = useFullPageLoader();
  const {Sr_No} = useParams()
  const navigate = useNavigate();
  const [formData, setFormData] = useState({
    Company_Name: "",
    Name: "",
    Mobile: "",
    Telephone: "",
    Email: "",
    GST_No: "",
    Address: "",
    Staff_1_Name: "",
    Staff_1_Mobile: "",
    Staff_1_Email: "",
    Staff_2_Name: "",
    Staff_2_Mobile: "",
    Staff_2_Email: "",
    Agent_Designation:"",
    Staff_1_Designation:"",
    Staff_2_Designation:"",
    Purchase_Data:""
  });  
  

  const handleInputChange = (e) => {
    const { id, value } = e.target;
    setFormData((prevState) => ({
      ...prevState,
      [id]: value,
    }));
  };
  





  useEffect(() => {
    if (Sr_No) {
      const fetchAgentDetails = async () => {
        showLoader(); // Trigger the loader function if needed
  
        try {
          const response = await axios.get(`/viewAgents/${Sr_No}`);
  
          if (response.status === 200) {
            const { data } = response.data; // Destructure to access `data` directly
  
            console.log(data); // Check the structure of the data
  
            // Check if `data` contains the agent details
            if (data) {
              const rawagents = data; // Assign the agent details to `agents`
  
              // Sanitize null values: Replace null with an empty string
              const agents = Object.fromEntries(
                Object.entries(rawagents).map(([key, value]) => [key, value === null ? "" : value])
              );
  
              // Set the agent details in formData
              setFormData({
                Name: `${agents.First_Name} ${agents.Last_Name}` || "", // If name is null, set as empty string
                Company_Name: agents.Company_Name || "",
                Mobile: agents.Mobile || "",
                Telephone: agents.Telephone || "",
                Email: agents.Email || "",
                GST_No: agents.GST_No || "",
                Address: agents.Address || "",
                Staff_1_Name: agents.Staff_1_Name || "",
                Staff_1_Mobile: agents.Staff_1_Mobile || "",
                Staff_1_Email: agents.Staff_1_Email || "",
                Staff_2_Name: agents.Staff_2_Name || "",
                Staff_2_Mobile: agents.Staff_2_Mobile || "",
                Staff_2_Email: agents.Staff_2_Email || "",
                Agent_Designation: agents.Agent_Designation || "",
                Staff_1_Designation: agents.Staff_1_Designation || "",
                Staff_2_Designation: agents.Staff_2_Designation || "",
                Purchase_Data: agents.Purchase_Data || ""
              });
            } else {
              console.error("Agent details not found in response data");
            }
          }
        } catch (error) {
          console.error('Error fetching agent details:', error);
          Swal.fire({
            icon: 'error',
            title: 'Error',
            text: 'Failed to fetch agent details.',
          });
        } finally {
          hideLoader(); // Trigger the hide loader function if needed
        }
      };
  
      fetchAgentDetails();
    }
  }, [Sr_No]);
  
  


  const handleChange = (e) => {
      const { name, value } = e.target;
      setFormData((prev) => ({
        ...prev,
        [name]: value
      }));
    };

    

    const validate = () => {
      let tempErrors = {};

      // Required field validations
      if (!formData.Company_Name) tempErrors.Company_Name = "Company Name is required.";
      if (!formData.Name) tempErrors.Name = "Vendor Name is required.";
      if (!formData.Mobile) {
        tempErrors.Mobile = "Mobile number is required.";
      } else if (!/^\d{10}$/.test(formData.Mobile)) {
        tempErrors.Mobile = "Mobile number must be 10 digits.";
      }
      

      if (!formData.Email && !/\S+@\S+\.\S+/.test(formData.Email)) {
        tempErrors.Email = "Invalid email format.";
      }

      if (!formData.GST_No && !/^\d{15}$/.test(formData.GST_No)) {
        tempErrors.GST_No = "GST Number must be 15 characters.";
      }

      // Staff validations

      
      if (formData.Staff_1_Name && !/^[a-zA-Z\s]+$/.test(formData.Staff_1_Name)) {
        tempErrors.Staff_1_Name = "Staff 1 Name is invalid."; // Example for name format validation
      }
      
      if (formData.Staff_1_Mobile && !/^[6-9]\d{9}$/.test(formData.Staff_1_Mobile)) {
        tempErrors.Staff_1_Mobile = "Staff 1 Mobile is invalid."; // Mobile number format validation
      }      
      
      if (formData.Staff_1_Email && !/\S+@\S+\.\S+/.test(formData.Staff_1_Email)) {
        tempErrors.Staff_1_Email = "Staff 1 Email is invalid."; // Email format validation
      }
      
      if (formData.Staff_2_Name && !/^[a-zA-Z\s]+$/.test(formData.Staff_2_Name)) {
        tempErrors.Staff_2_Name = "Staff 2 Name is invalid."; // Example for name format validation
      }
      
      if (formData.Staff_2_Mobile && !/^[6-9]\d{9}$/.test(formData.Staff_2_Mobile)) {
        tempErrors.Staff_2_Mobile = "Staff 2 Mobile is invalid."; // Mobile number format validation
      }
      
      if (formData.Staff_2_Email && !/\S+@\S+\.\S+/.test(formData.Staff_2_Email)) {
        tempErrors.Staff_2_Email = "Staff 2 Email is invalid."; // Email format validation
      }

      if(!formData.Agent_Designation){
        tempErrors.Agent_Designation = "Designation is required."
      }
      
      

      // Address validation
    // if (!formData.Address) tempErrors.Address = "Address is required.";

      setErrors(tempErrors);

      return Object.keys(tempErrors).length === 0; // Returns true if no errors
    };



    async function fetchLastCustomerId() {
      try {
        // Make the GET request to fetch the last customer ID
        const response = await axios.get('/getCustomerId');
        
        // Assuming the response contains a field 'lastCustomerId' that has the format 'EPR-XXXX'
        const lastCustomerId = response.data;
        console.log(lastCustomerId);
        
    
        if (!lastCustomerId) {
          throw new Error('No customer ID found');
        }
    
        // Extract the number part of the last customer ID
        const lastNumber = parseInt(lastCustomerId.split("-")[1]); // E.g., from 'EPR-0001', extract '0001'
        
        if (isNaN(lastNumber)) {
          throw new Error('Invalid customer ID format');
        }
    
        // Increment the number to get the next customer ID
        const nextNumber = lastNumber + 1;
    
        // Format the next customer ID with leading zeros (4 digits)
        const nextCustomerId = `EPR-${String(nextNumber).padStart(4, "0")}`;
    
        console.log('Next Customer ID:', nextCustomerId);
    
        return nextCustomerId;
      } catch (error) {
        console.error('Error fetching last customer ID:', error);
        return 'EPR-0001'; // Default to 'EPR-0001' if there's an error or no customer ID exists
      }
    }
    



    const handleSubmit = async (e) => {
      e.preventDefault();
    
      // Show loader
      showLoader();
    
      if (!validate()) {
        Swal.fire({
          icon: 'error',
          title: 'Validation Failed',
          text: 'Please fill all required fields correctly.',
        });
        hideLoader();
        return;
      }
    
      try {
        // Fetch the next customer ID
        const customerId = await fetchLastCustomerId();
    
        const FormDataPayload = new FormData();
        FormDataPayload.append('CustomerId', customerId); // Use the newly generated customer ID
        FormDataPayload.append('Name', formData.Name);
        FormDataPayload.append('Company_Name', formData.Company_Name);
        FormDataPayload.append('Mobile', formData.Mobile);
        FormDataPayload.append('Telephone', formData.Telephone);
        FormDataPayload.append('Email', formData.Email);
        FormDataPayload.append('GST_No', formData.GST_No);
        FormDataPayload.append('Address', formData.Address);
        FormDataPayload.append('Staff_1_Name', formData.Staff_1_Name);
        FormDataPayload.append('Staff_1_Mobile', formData.Staff_1_Mobile);
        FormDataPayload.append('Staff_1_Email', formData.Staff_1_Email);
        FormDataPayload.append('Staff_2_Name', formData.Staff_2_Name);
        FormDataPayload.append('Staff_2_Mobile', formData.Staff_2_Mobile);
        FormDataPayload.append('Staff_2_Email', formData.Staff_2_Email);
        FormDataPayload.append('Org_Id', userData?.Org_Id);
        FormDataPayload.append('Added_By_Id', userData?.UserId);
        FormDataPayload.append('Added_By', `${userData.First_Name} ${userData.Last_Name}`);
        FormDataPayload.append('Agent_Designation', formData.Agent_Designation)
        FormDataPayload.append('Staff_1_Designation', formData.Staff_1_Designation)
        FormDataPayload.append('Staff_2_Designation', formData.Staff_2_Designation)
        FormDataPayload.append('Purchase_Data', formData.Purchase_Data)
    
        const endpoint = Sr_No ? `/editAgent/${Sr_No}` : '/addAgents';
        const method = Sr_No ? 'put' : 'post';
    
        // Log FormData
        console.log('FormData Contents:');
        for (let [key, value] of FormDataPayload.entries()) {
          console.log(`${key}: ${value}`);
        }
    
        const response = await axios({
          method,
          url: endpoint,
          data: FormDataPayload,
          headers: {
            'Content-Type': 'application/json',
          },
        });
    
        if (response.status === 200 || response.status === 201) {
          Swal.fire({
            icon: 'success',
            title: Sr_No ? 'Agent Updated Successfully' : 'Agent Added Successfully',
            text: `Agent ${Sr_No ? 'updated' : 'added'} successfully.`,
          });
          setFormData({
            Name: '',
            Company_Name: '',
            Mobile: '',
            Telephone: '',
            Email: '',
            GST_No: '',
            Address: '',
            Staff_1_Name: '',
            Staff_1_Mobile: '',
            Staff_1_Email: '',
            Staff_2_Name: '',
            Staff_2_Mobile: '',
            Staff_2_Email: '',

          });
    
          navigate(`/viewAgents`);
    
        } else {
          Swal.fire({
            icon: 'error',
            title: 'Submission Failed',
            text: 'Failed to save the Agent. Please try again.',
          });
        }
      } catch (error) {
        console.error('Error while submitting form:', error);
        Swal.fire({
          icon: 'error',
          title: 'Error',
          text: 'Something went wrong. Please try again later.',
        });
      } finally {
        hideLoader();
      }
    };
    
  
    





  


  

  





  return (
    <div className="row">
  <div className="col-12">
    <div className="page-title-box justify-content-between d-flex align-items-md-right flex-md-row flex-column">
    <h4 className="page-title">{Sr_No ? 'Update Agent' : 'Add Agent'}</h4>
    </div>
    <div className="card">
      <div className="card-body">
        <form onSubmit={handleSubmit}>
          {/* Vendor Details Section */}
          <div className="row ">
            <div className="col-12">
              <h5 className="section-title"  style={{marginBottom:"-10px"}}>Agent Details</h5>
              <hr className="mb-1" />
            </div>
          </div>
          <div className="row">
            <div className="col-md-3">
              <div className="mb-3">
                <label htmlFor="Name" className="form-label">
                  Agent Name<span style={{ color: "red" }}>*</span>
                </label>
                <input
                  type="text"
                  id="Name"
                  name="Name"
                  className="form-control"
                  placeholder="Enter Agent Name"
                  required
                  onChange={handleChange}
                  value={formData.Name}
                />
                  {errors.Name && <small className="text-danger">{errors.Name}</small>}
              </div>
            </div>
            <div className="col-md-3">
              <div className="mb-3">
                <label htmlFor="Company_Name" className="form-label">
                  Company Name<span style={{ color: "red" }}>*</span>
                </label>
                <input
                  type="text"
                  id="Company_Name"
                  name="Company_Name"
                  className="form-control"
                  placeholder="Enter Company Name"
                  required
                  onChange={handleChange}
                  value={formData.Company_Name}
                />
              {errors.Company_Name && <small className="text-danger">{errors.Company_Name}</small>}       
              </div>
            </div>
            <div className="col-md-3">
              <div className="mb-3">
                <label htmlFor="Mobile" className="form-label">
                  Mobile<span style={{ color: "red" }}>*</span>
                </label>
                <input
                  type="text"
                  maxLength={10}
                  id="Mobile"
                  name="Mobile"
                  className="form-control"
                  placeholder="Enter Mobile Number"
                  required
                  onChange={handleChange}
                  value={formData.Mobile}
                />
            {errors.Mobile && <small className="text-danger">{errors.Mobile}</small>}         
              </div>
            </div>
            <div className="col-md-3">
  <div className="mb-3">
    <label htmlFor="Agent_Designation" className="form-label">
      Designation<span style={{ color: "red" }}>*</span>
    </label>
    <select
      className="form-select"
      id="Agent_Designation"
      value={formData.Agent_Designation || ""}
      onChange={handleInputChange}
      aria-label="Select Vendor Designation"
    >
      <option value="" disabled>Select Designation</option>
      <option value="Director">Director</option>
      <option value="Staff">Staff</option>
      <option value="Accountant">Accountant</option>
      <option value="Others">Others</option>
    </select>
    {errors.Agent_Designation && <small className="text-danger">{errors.Agent_Designation}</small>}
  </div>
</div>
            
          </div>

          {/* Address Section */}
          <div className="row">
            <div className="col-md-3">
              <div className="mb-3">
                <label htmlFor="Email" className="form-label">
                  Email<span style={{ color: "red" }}>*</span>
                </label>
                <input
                  type="email"
                  className="form-control"
                  name="Email"
                  id="Email"
                  placeholder="Enter Email"
                  required
                  onChange={handleChange}
                  value={formData.Email}
                />
              {errors.Email && <small className="text-danger">{errors.Email}</small>}         
              </div>
            </div>
            <div className="col-md-3">
              <div className="mb-3">
                <label htmlFor="GST_No" className="form-label">
                  GST Number<span style={{ color: "red" }}>*</span>
                </label>
                <input
                  type="text"
                  className="form-control"
                  name="GST_No"
                  id="GST"
                  placeholder="Enter GST Number"
                  onChange={handleChange}
                  value={formData.GST_No}
                />
                {errors.GST_No && <small className="text-danger">{errors.GST_No}</small>}  
              </div>
            </div>
            <div className="col-md-3">
                    <div className="mb-1">
                      <label htmlFor="Purchase_Data" className="form-label">
                        Purchase Data
                      </label>
                      <select className="form-select"
                      id="Purchase_Data" 
                      value={formData.Purchase_Data || ""} 
                      required 
                      aria-label="Select Purchase Data"

                      onChange={handleInputChange}
                      >
                        <option disabled label="Select Purchase Data" value=""></option>
                        <option label="Monthly" value="Monthly"></option>
                        <option label="Quarterly" value="Quarterly"></option>
                        <option label="Half-Yearly" value="Half-Yearly"></option>
                        <option label="Annually" value="Annually"></option>
                      </select>
                     
                    </div>
                  </div>
            <div className="col-md-3">
              <div className="mb-3">
                <label htmlFor="Telephone" className="form-label">
                  Telephone
                </label>
                <input
                  type="text"
                  id="Telephone"
                  name="Telephone"
                  className="form-control"
                  placeholder="Enter Telephone Number"
                  onChange={handleChange}
                  value={formData.Telephone}
                />
              </div>
            </div>
           
          </div>

          <div className="row">
            <div className="col-md-12">
              <div className="mb-3">
                <label htmlFor="txtAddress" className="form-label">
                  Address
                </label>
                <textarea
                  id="txtAddress"
                  name="Address"
                  className="form-control"
                  placeholder="Enter Address"
                  onChange={handleChange}
                  value={formData.Address}
                />
              </div>
            </div>
          </div>

          <div className="row ">
            <div className="col-12">
              <h5 className="section-title" style={{marginBottom:"-10px"}}>Staff 1 Details</h5>
              <hr className="mb-1" />
            </div>
          </div>
          <div className="row">
            <div className="col-md-3">
              <div className="mb-3">
                <label htmlFor="Staff_1_Name" className="form-label">
                  Staff Name
                </label>
                <input
                  type="text"
                  id="Staff1Name"
                  name="Staff_1_Name"
                  className="form-control"
                  placeholder="Enter Staff Name"
                  onChange={handleChange}
                  value={formData.Staff_1_Name}
                />
               {errors.Staff_1_Name && <small className="text-danger">{errors.Staff_1_Name}</small>}  
              </div>
            </div>
            <div className="col-md-3">
              <div className="mb-3">
                <label htmlFor="Staff_1_Mobile" className="form-label">
                  Staff Mobile
                </label>
                <input
                  type="text"
                  id="Staff_1_Mobile"
                  name="Staff_1_Mobile"
                  className="form-control"
                  placeholder="Enter Staff Mobile Number"
                  onChange={handleChange}
                  value={formData.Staff_1_Mobile}
                />
              {errors.Staff_1_Mobile && <small className="text-danger">{errors.Staff_1_Mobile}</small>}        
              </div>
            </div>
            <div className="col-md-3">
              <div className="mb-3">
                <label htmlFor="Staff_1_Email" className="form-label">
                  Staff Email
                </label>
                <input
                  type="email"
                  id="Staff_1_Email"
                  name="Staff_1_Email"
                  className="form-control"
                  placeholder="Enter Staff Email"
                  onChange={handleChange}
                  value={formData.Staff_1_Email}
                />
              {errors.Staff_1_Email && <small className="text-danger">{errors.Staff_1_Email}</small>}  
              </div>
            </div>
            <div className="col-md-3">
  <div className="mb-3">
    <label htmlFor="Staff_1_Designation" className="form-label">
      Designation
    </label>
    <select
      className="form-select"
      id="Staff_1_Designation"
      value={formData.Staff_1_Designation || ""}
      onChange={handleInputChange}
      aria-label="Select Staff Designation"
    >
      <option value="" >Select Designation</option>
      <option value="Director">Director</option>
      <option value="Staff">Staff</option>
      <option value="Accountant">Accountant</option>
      <option value="Others">Others</option>
    </select>
  </div>
</div>
          </div>
          <div className="row ">
            <div className="col-12">
              <h5 className="section-title"  style={{marginBottom:"-10px"}}>Staff 2 Details</h5>
              <hr className="mb-1" />
            </div>
          </div>
          <div className="row">
            <div className="col-md-3">
              <div className="mb-3">
                <label htmlFor="Staff_2_Name" className="form-label">
                  Staff Name
                </label>
                <input
                  type="text"
                  id="Staff_2_Name"
                  name="Staff_2_Name"
                  className="form-control"
                  placeholder="Enter Staff Name"
                  onChange={handleChange}
                  value={formData.Staff_2_Name}
                />
              {errors.Staff_2_Name && <small className="text-danger">{errors.Staff_2_Name}</small>}  
              </div>
            </div>
            <div className="col-md-3">
              <div className="mb-3">
                <label htmlFor="Staff_2_Mobile" className="form-label">
                  Staff Mobile
                </label>
                <input
                  type="text"
                  id="Staff_2_Mobile"
                  name="Staff_2_Mobile"
                  className="form-control"
                  placeholder="Enter Staff Mobile Number"
                  onChange={handleChange}
                  value={formData.Staff_2_Mobile}
                />
              {errors.Staff_2_Mobile && <small className="text-danger">{errors.Staff_2_Mobile}</small>}  
              </div>
            </div>
            <div className="col-md-3">
  <div className="mb-3">
    <label htmlFor="Staff_2_Email" className="form-label">
      Staff Email
    </label>
    <input
      type="email"
      id="Staff_2_Email"
      name="Staff_2_Email" 
      className="form-control"
      placeholder="Enter Staff Email"
      onChange={handleChange}  
      value={formData.Staff_2_Email}  
    />
    {errors.Staff_2_Email && <small className="text-danger">{errors.Staff_2_Email}</small>}
  </div>
</div>
<div className="col-md-3">
  <div className="mb-3">
    <label htmlFor="Staff_2_Designation" className="form-label">
      Designation
    </label>
    <select
      className="form-select"
      id="Staff_2_Designation"
      value={formData.Staff_2_Designation || ""}
      onChange={handleInputChange}
      aria-label="Select Staff Designation"
    >
      <option value="" >Select Designation</option>
      <option value="Director">Director</option>
      <option value="Staff">Staff</option>
      <option value="Accountant">Accountant</option>
      <option value="Others">Others</option>
    </select>
  </div>
</div>

          </div>
      
          <div className="row justify-content-center">
  <div className="col-md-2 col-sm-3 col-xs-3 col-3 d-flex justify-content-center">
    <button type="submit" className="btn btn-primary" >
    {Sr_No ? 'Update' : 'Submit'}
    </button>
  </div>
</div>


        </form>
      </div>
    </div>
    {loader}
  </div>
    </div>

  );
};

export default AddAgents;
