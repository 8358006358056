import React, { useState } from "react";
import axios from '../components/axios';

function TestReminder() {
  const [loading, setLoading] = useState(false);
  const [message, setMessage] = useState("");

  const triggerTestReminder = async () => {
    setLoading(true);
    setMessage("");

    try {
      const response = await axios.get("/test-reminder"); // Replace with your backend URL
      if (response.data.success) {
        setMessage("Reminder check triggered successfully!");
      } else {
        setMessage("Failed to trigger reminder check.");
      }
    } catch (error) {
      setMessage(`Error: ${error.message}`);
    } finally {
      setLoading(false);
    }
  };

  return (
    <div style={{ textAlign: "center", padding: "20px" }}>
      <h1>Test Reminder Check</h1>
      <button
        onClick={triggerTestReminder}
        style={{
          padding: "10px 20px",
          fontSize: "16px",
          color: "white",
          backgroundColor: loading ? "gray" : "#007BFF",
          border: "none",
          borderRadius: "5px",
          cursor: loading ? "not-allowed" : "pointer",
        }}
        disabled={loading}
      >
        {loading ? "Processing..." : "Trigger Reminder Check"}
      </button>
      {message && <p style={{ marginTop: "20px", fontSize: "16px" }}>{message}</p>}
    </div>
  );
}

export default TestReminder;
