import React, { useState, useEffect } from "react";
import { Modal } from "react-bootstrap";
import axios from "../../components/axios";
import { apiUrl } from "../../components/axios";
import useFullPageLoader from "../../components/useFullPageLoader";
import Swal from "sweetalert2";
import { useUser } from "../../components/UserContext";
import { useParams, useNavigate, Link } from "react-router-dom";
import dayjs from "dayjs";
import { formatDate } from "../../components/dateFormate";
import Select from 'react-select'


const UploadCertificate = ({ open, onClose, updateData }) => {
  const { userData } = useUser();
  const [show, setShow] = useState(open);
  const navigate = useNavigate();
  const [loader, showLoader, hideLoader] = useFullPageLoader();
  const [errors, setErrors] = useState({});
  const [Type, setType] = useState("");
  const [BankOption, setBankOption] = useState();
  const [enqData, setEnqData] = useState();
  const [installmentData, setInstallmentData] = useState([]);
  const [ENQ, setENQData] = useState({});

  const CheckoutHandle = async (name, amount, Installment_Id) => {
    const res = await axios.post(`/payment/Checkout`, {
      name, amount
    });

    var options = {
      "key": "rzp_test_KEnswjAHSqsIcD",
      "amount": res.data.amount,
      "currency": res.data.currency,
      "name": "EPR Certificates",
      "description": "Payment has been made for the certificate of ${name} for the amount of ${amount} Rs.",
      "image": "https://environaturesolution.com/assets/img/logo.png",
      "order_id": res.data.id,
      "callback_url": `${apiUrl}/paymentVerification`,
      "prefill": {
        "name": `${userData?.First_Name} ${userData?.Last_Name}`,
        "email": userData?.Email,
        "contact": userData?.Mobile
      },
      "notes": {
        "address": ``
      },
      "theme": {
        "color": "#3399cc"
      },
      "handler": function (response) {
        const paymentDetails = {
            razorpay_payment_id: response.razorpay_payment_id,
            razorpay_order_id: response.razorpay_order_id,
            razorpay_signature: response.razorpay_signature
        };
    
        // Set the cookie with payment verification details
        document.cookie = `onComplete=${JSON.stringify(paymentDetails)}; path=/`;
    
        // Redirect to payment verification endpoint with payment details in query parameters
        window.location.href = `${apiUrl}/paymentVerification/${updateData}/${Installment_Id}?razorpay_payment_id=${response.razorpay_payment_id}&razorpay_order_id=${response.razorpay_order_id}&razorpay_signature=${response.razorpay_signature}`;
    }
    
    }
    var rzp1 = new window.Razorpay(options);
    rzp1.open()
  };

  const handleClose = () => {
    setShow(false);
    onClose();
  };


  const [data, setData] = useState({
    Receipt_No: "",
    Installment_Id: "",
    Enq_Id: "",
    Certificate_Id: "",
    Certificate: "",
    Customer_Id: "",
    Customer_Name: "",
    Net_Amount: "",
    Amount: "",
    Payment_Date: "",
    Payment_Mode: "",
    Bank_Name: "",
    Cheque_No: "",
    Cheque_Date: "",
    Transaction_Id: ""
  });

  const loadEnquiry = async () => {
    showLoader();
    try {

      await axios.get(`/getEnquiry/${updateData}`).then((res) => {

        console.log(res.data)
        setENQData(res.data[0]);
      });

      if (data.Installment_Id) {
        const payementlog = await axios.get(`/getPaymentLog/${data.Installment_Id}`);
        console.log(payementlog.data);
        setData(prevData => ({
          ...prevData,
          ...payementlog.data
        }));
      }

      const ReceiptNo = await axios.get(`/getReceiptNo`);
      console.log(ReceiptNo.data);
      setData(prevData => ({
        ...prevData,
        Receipt_No: ReceiptNo.data
      }));


      const installmentData = await axios.get(`/getInstallment/${updateData}`);
      setInstallmentData(installmentData.data);
      console.log(installmentData.data);

      const enqData = await axios.get(`/getEnquiryDetail/${updateData}`);
      setEnqData(enqData.data[0]);

      const result = await axios.get(`/getBank`);
      const op = result.data.map((category) => ({
        label: category.Bank_Name,
        value: category.Bank_Name,
      }));
      setBankOption(op);
      console.log(enqData.data[0])
      hideLoader();
    } catch (error) {
      hideLoader();
      console.error("Error fetching data: ", error);
    }
  };

  useEffect(() => {
    loadEnquiry();
  }, [updateData, data.Installment_Id]);
  



  const handleSubmit = (event) => {
    event.preventDefault();
    const postPaymentDetails = {
      ...data,
      Enq_Id: updateData,
      Org_Id: userData.Org_Id,
      User_Role: userData.Role,
      UserName: userData.Username,
      Added_By_Id: userData.UserId,
      Added_By: `${userData.First_Name} ${userData.Last_Name}`
    };

    const validationErrors = {};


    if (Object.keys(validationErrors).length === 0) {
      showLoader();
      axios
        .post(`/makePayment`, postPaymentDetails)
        .then((res) => {
          Swal.fire("Success!", "Payment Made Successfully!!", "success").then(
            (result) => {
              if (result.isConfirmed) {
                //navigate("/viewEnquiry");
                loadEnquiry();
                setType("");
              }
            }
          );
          hideLoader();
        })
        .catch((err) => {
          console.log(err);
          Swal.fire({
            title: "Error",
            text: "Failed to payment. Please try again later.",
            icon: "error",
            confirmButtonText: "OK",
          });
          hideLoader();
        });
    } else {
      setErrors(validationErrors);
    }
  };


  const deleteUser = (Id, Name) => {
    Swal.fire({
      title: "Are you sure?",
      text: "You won't be able to revert this!",
      icon: "warning",
      showCancelButton: true,
      confirmButtonColor: "#3085d6",
      cancelButtonColor: "#d33",
      confirmButtonText: "Yes, delete it!",
    }).then((result) => {
      if (result.value) {
        handleDelete(Id, Name);
      }
    });
  };

  const handleDelete = async (Id, Name) => {
    const obj = {
      Enq_Id: updateData,
      Org_Id: userData.Org_Id,
      User_Role: userData.Role,
      UserName: userData.Username,
      Added_By_Id: userData.UserId,
      Added_By: `${userData.First_Name} ${userData.Last_Name}`,
      Name: Name,
    };
    try {
      await axios.delete(`/deletePayment/${Id}`, { data: obj });

      Swal.fire("Deleted!", "Payment has been deleted.", "success");
      loadEnquiry();
    } catch (error) {
      console.error(error);
      Swal.fire(
        "Error!",
        "An error occurred while deleting the user.",
        "error"
      );
    }
  };

  const handleUpdate = (event) => {
    event.preventDefault();

    const updatePayment = {
      ...data,
      Org_Id: userData.Org_Id,
      User_Role: userData.Role,
      UserName: userData.Username,
      Added_By_Id: userData.UserId,
      Added_By: `${userData.First_Name} ${userData.Last_Name}`,
    };

    const validationErrors = {};

    if (Object.keys(validationErrors).length === 0) {
      showLoader();
      axios
        .put(`/updatePayment`, updatePayment)
        .then((res) => {
          Swal.fire("Success!", "Payment Updated Successfully!!", "success").then(
            (result) => {
              if (result.isConfirmed) {
                loadEnquiry();
                setType("");
              }
            }
          );
          hideLoader();
        })
        .catch((err) => {
          console.log(err);
          Swal.fire({
            title: "Error",
            text: "Failed to update payment. Please try again later.",
            icon: "error",
            confirmButtonText: "OK",
          });
          hideLoader();
        });
    } else {
      setErrors(validationErrors);
    }
  };


  const handleEdit = (item) => {
    setType('Update');
    setData({
      ...data,
      Payment_Mode: item.Payment_Mode,
      Payment_Date: item.Payment_Date,
      Installment_Id: item.Sr_No
    });
  };




  return (
    <Modal
      show={show}
      onHide={handleClose}
      size="xl"
      centered
      style={{ boxShadow: "none !important" }}
    >

      <Modal.Body>
        <h5>Make Payment - {`(${ENQ.Certificate_Name})`}</h5>
        <hr className="p-0 mb-2" />
        <div className="row">

          <div className="row justify-content-center mt-4">

            <table
              className="table table-bordered table-hover table-responsive"
              cellSpacing="0"
              cellPadding="4"
              align="Center"
              rules="cols"
              style={{
                color: "Black",
                backgroundColor: "White",
                borderColor: "#DEDFDE",
                borderWidth: "1px",
                borderStyle: "None",
                borderCollapse: "collapse",
              }}
            >
              <tbody>
                <tr align="center">
                  <th scope="col">Due Date</th>
                  <th scope="col">Amount</th>
                  <th scope="col">Receipt No</th>
                  <th scope="col">Payment Date</th>
                  <th scope="col"></th>
                </tr>
                {installmentData.map((item, index) => (
                  <tr key={index}>
                    <td className="text-center">
                      {formatDate(item.Due_Date)}
                    </td>
                    <td className="text-center">
                      {item.Amount}
                    </td>
                    <td className="text-center">
                      {item.Receipt_Number ? item.Receipt_Number : "--"}
                    </td>
                    <td className="text-center">
                      {item.Payment_Date ? formatDate(item.Payment_Date) : "--"}
                    </td>
                    <td className="text-center">
                      {item.Payment_Status === "Unpaid" ? (
                        <button
                          className={'btn btn-sm btn-primary'}
                          onClick={() => {
                            CheckoutHandle(item.Certificate, item.Amount, item.Sr_No);
                            showLoader();
                          }}
                        >
                          Pay
                        </button>
                      ) : (
                        <>
                          {/* <Link onClick={() => handleEdit(item)}>
                              <i className="fas fa-edit" style={{ fontSize: "18px", color: "blue" }}></i>
                            </Link>
                            &nbsp;
                            &nbsp;
                            <Link    onClick={() =>
                deleteUser(item.Sr_No, `${item.Customer_Name} of certificate ${item.Certificate}`)
              }>
                              <i className="fas fa-trash cursor-pointer" style={{ fontSize: "18px", color: "red" }}></i>
                            </Link> */}
                        </>
                      )}
                    </td>

                  </tr>
                ))}
              </tbody>
            </table>


          </div>

          <div>
            {(Type === 'Submit' || Type === 'Update') && (
              <>
                <hr />
                <form onSubmit={Type === 'Update' ? handleUpdate : handleSubmit}>
                  <div className="row">
                    {Type === 'Update' ? (
                      <>
                        <div className="mb-3 col-md-6">
                          <label className="form-label" htmlFor="Payment_Mode">
                            Payment Mode :
                          </label>
                          <select
                            className={`form-select`}
                            type="text"
                            name="Payment_Mode"
                            id="Payment_Mode"
                            value={data.Payment_Mode}
                            onChange={(e) => {
                              setData({
                                ...data,
                                Payment_Mode: e.target.value,
                              });
                            }}
                          >
                            <option value="">--Select--</option>
                            <option value="Cash">Cash</option>
                            <option value="Cheque">Cheque</option>
                            <option value="Card">Card</option>
                            <option value="Online">Online</option>
                          </select>
                        </div>
                        <div className="mb-3 col-md-6">
                          <label className="form-label" htmlFor="Payment_Date">
                            Payment Date :
                          </label>
                          <input
                            className={`form-control`}
                            type="date"
                            name="Payment_Date"
                            id="Payment_Date"
                            value={data.Payment_Date ? dayjs(data.Payment_Date).format("YYYY-MM-DD") : ""}
                            onChange={(e) => {
                              setData({
                                ...data,
                                Payment_Date: e.target.value,
                              });
                            }}
                          />
                        </div>
                      </>
                    ) : (
                      <>
                        <div className="mb-3 col-md-4">
                          <label className="form-label" htmlFor="Amount">
                            Amount :
                          </label>
                          <input
                            className={`form-control`}
                            type="text"
                            name="Amount"
                            id="Amount"
                            disabled
                            value={data.Amount}
                            onChange={(e) => {
                              const input = e.target.value;
                              const decimalInput = input.replace(/[^0-9.]/g, "");
                              const amount = decimalInput.replace(
                                /^(\d*\.\d*)\..*$/,
                                "$1"
                              );
                              // setInstallment({
                              //   ...installment,
                              //   Full_Payment_Amt: amount,
                              //  // Amount: net_Amount,
                              // });

                            }}
                          />
                        </div>
                        <div className="mb-3 col-md-4">
                          <label className="form-label" htmlFor="Payment_Date">
                            Payment Date :
                          </label>
                          <input
                            className={`form-control`}
                            type="date"
                            name="Payment_Date"
                            id="Payment_Date"
                            value={data.Payment_Date ? dayjs(data.Payment_Date).format("YYYY-MM-DD") : ""}
                            onChange={(e) => {
                              setData({
                                ...data,
                                Payment_Date: e.target.value,
                              });
                            }}
                          />
                        </div>
                        <div className="mb-3 col-md-4">
                          <label className="form-label" htmlFor="Payment_Mode">
                            Payment Mode :
                          </label>
                          <select
                            className={`form-select`}
                            type="text"
                            name="Payment_Mode"
                            id="Payment_Mode"
                            onChange={(e) => {
                              setData({
                                ...data,
                                Payment_Mode: e.target.value,
                              });
                            }}
                          >
                            <option value="">--Select--</option>
                            <option value="Cash">Cash</option>
                            <option value="Cheque">Cheque</option>
                            <option value="Card">Card</option>
                            <option value="Online">Online</option>
                          </select>
                        </div>
                      </>)}
                    {["Cheque"].includes(data.Payment_Mode) && (
                      <>
                        <div className="mb-3 col-md-12">
                          <label className="form-label" htmlFor="Bank">
                            Bank Name :
                          </label>
                          <Select
                            name="Bank"
                            id="Bank"
                            options={BankOption}
                            value={data.Bank_Name ? [{ value: data.Bank_Name, label: data.Bank_Name }] : null}
                            onChange={(e) => {
                              setData({
                                ...data,
                                Bank_Name: e.value,
                              });
                            }}
                          />
                        </div>
                        <div className="mb-3 col-md-4">
                          <label
                            className="form-label"
                            htmlFor="Cheque_No"
                          >
                            Cheque No :
                          </label>
                          <input
                            className={`form-control`}
                            type="text"
                            name="Cheque_No"
                            id="Cheque_No"
                            placeholder="Enter Cheque No"
                            value={data.Cheque_No}
                            onChange={(e) => {
                              const input = e.target.value;
                              const decimalInput = input.replace(/[^0-9]/g, "");
                              setData({
                                ...data,
                                Cheque_No: decimalInput,
                              });
                            }}

                          />
                        </div>
                        <div className="mb-3 col-md-4">
                          <label
                            className="form-label"
                            htmlFor="Cheque_Date"
                          >
                            Cheque Date :
                          </label>
                          <input
                            className={`form-control`}
                            type="date"
                            name="Cheque_Date"
                            id="Cheque_Date"
                            value={data.Cheque_Date ? dayjs(data.Cheque_Date).format("YYYY-MM-DD") : ""}
                            onChange={(e) => {
                              setData({
                                ...data,
                                Cheque_Date: e.target.value,
                              });
                            }}
                          />
                        </div>
                        <div className="mb-3 col-md-4">
                          <label className="form-label" htmlFor="Cheque_Status">
                            Cheque Status :
                          </label>
                          <select
                            className={`form-select`}
                            type="text"
                            name="Cheque_Status"
                            id="Cheque_Status"
                            value={data.Cheque_Status}
                            onChange={(e) => {
                              setData({
                                ...data,
                                Cheque_Status: e.target.value,
                              });
                            }}
                          >
                            <option value="">--Select--</option>
                            <option value="Uncleared">Uncleared</option>
                            <option value="Honoured">Honoured</option>
                            <option value="Dishonoured">Dishonoured</option>

                          </select>
                        </div>
                      </>
                    )}

                    {["Cheque", "Card", "Online"].includes(
                      data?.Payment_Mode
                    ) && (
                        <>
                          <div className="mb-3 col-md-12">
                            <label className="form-label" htmlFor="Transaction_Id">
                              Refrence No:
                            </label>
                            <input
                              className={`form-control`}
                              type="text"
                              name="Transaction_Id"
                              id="Transaction_Id"
                              placeholder="Enter Refrence No"
                              onChange={(e) => {
                                setData({
                                  ...data,
                                  Transaction_Id: e.target.value,
                                });
                              }}
                            />
                          </div>
                        </>
                      )}

                  </div>
                  <div className="row">
                    <div className="col-md-12 text-center mt-3">
                      <button type="submit" className="btn btn-sm btn-primary">
                        {Type === 'Update' ? 'Update Payment' : 'Make Payment'}
                      </button>
                    </div>
                  </div>
                </form>
              </>

            )}
          </div>

        </div>
        {loader}
      </Modal.Body>
    </Modal>
  );
}
export default UploadCertificate;
