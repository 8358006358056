import React, { useState, useEffect } from "react";
import { Modal } from "react-bootstrap";
import axios from "../../components/axios";
import useFullPageLoader from "../../components/useFullPageLoader";
import Swal from "sweetalert2";
import { useNavigate, Link } from "react-router-dom";
import { useUser } from "../../components/UserContext";
import JSZip from 'jszip';
import FileSaver from 'file-saver';



const UploadCertificate = ({ open, onClose, updateData, Role }) => {
    const { userData } = useUser();
    const [loader, showLoader, hideLoader] = useFullPageLoader();
    const [show, setShow] = useState(open);
    const [errors, seterrors] = useState({});
    const navigate = useNavigate();
    const [documentName, setDocument] = useState([]);
    const [data, SetData] = useState();
    const [check, setCheck] = useState();



    //console.log(updateData)

    const [customer, setCustomer] = useState({
        Documents: [],
        Customer_Id: Role === 'Customer' ? updateData.Customer_Id : updateData.Sr_No,
        Doc_Type: updateData.Certificate_Name
    });

    const handleClose = () => {
        setShow(false);
        onClose();
    };


    useEffect(() => {
        (async () => {
            showLoader();
            try {
                let length;
                if (updateData) {
                    const response = await axios.get(`/getCustomerDocuments/${Role === 'Customer' ? updateData?.Sr_No : updateData?.Enquiry_Sr_No}`);

                    setCustomer((customer) => ({
                        ...customer,
                        Documents: response.data,
                    }));
                    //console.log(response.data);
                   // console.log(response.data.length);
                    length = response.data.length;
                    setCheck(response.data.length > 0 ? true : false);
                }
                
                if (length !== 0 && updateData?.Certificate_Id) {
                    const result = await axios.get(`/getDocReupload?CerId=${updateData?.Certificate_Id}&EnqId=${Role === 'Customer' ? updateData?.Sr_No : updateData?.Enquiry_Sr_No}`);
                    //console.log(result.data);
                    setDocument(result.data);
                }
                if (length === 0 && updateData?.Certificate_Id) {
                    const result = await axios.get(`/getCertificateDocByCerIdForCustomer?CerId=${updateData.Certificate_Id}`);
                   // console.log(result.data);
                    setDocument(result.data);
                }

                if (updateData) {
                    const result = await axios.get(`/getCustomerById/${Role === 'Customer' ? updateData.Customer_Id : updateData.Sr_No}`);
                    //console.log(result.data);
                    SetData(result.data);
                }
            } catch (error) {
                console.error("Error fetching:", error);
            }

            hideLoader();
        })();
    }, [userData, updateData, updateData.Certificate_Id, check]);


    const UploadCertificateHandle = async (event) => {
        event.preventDefault();
        showLoader();

        if (customer?.Documents?.length === 0) {
            Swal.fire({
                title: "No Documents Uploaded",
                text: "Please upload at least one document.",
                icon: "warning",
                confirmButtonText: "OK",
            });
            return;
        }

        const formdata = new FormData();
        customer.Documents.forEach(document => {
            for (const [documentType, documentData] of Object.entries(document || {})) {
                if (typeof documentData === 'object' && documentData !== null) {
                    const { Doc_Id, file } = documentData;
                    formdata.append("Doc_Type", documentType);
                    formdata.append("Doc_Id", Doc_Id);
                    formdata.append('Document_Name', file);
                } else {
                    formdata.append("Doc_Type", documentType);
                    formdata.append('Document_Name', documentData);
                }
            }
        });

        formdata.append("Customer_Id", customer.Customer_Id);
        formdata.append("Customer_Name", `${userData.First_Name} ${userData.Last_Name}`);
        formdata.append("Certificate_Name", updateData.Certificate_Name);

        await axios
            .put(`/updateCustomerDocument/${updateData.Sr_No}`, formdata)
            .then((res) => {
                handleClose();
                Swal.fire("Success!", "Documents Uploaded Successfully.", "success");
                hideLoader();
            })
            .catch((err) => {
                console.error(err);
                Swal.fire({
                    title: "Error",
                    text: "Failed to Upload. Please try again later.",
                    icon: "error",
                    confirmButtonText: "OK",
                });
                hideLoader();
            });
    };

    const UploadDocByFacility = async (event) => {
        event.preventDefault();

        showLoader();

        if (customer?.Documents?.length === 0) {
            Swal.fire({
                title: "No Documents Uploaded",
                text: "Please upload at least one document.",
                icon: "warning",
                confirmButtonText: "OK",
            });
            return;
        }

        const formdata = new FormData();
        customer?.Documents?.forEach(document => {
            for (const [documentType, documentData] of Object.entries(document || {})) {
                if (typeof documentData === 'object' && documentData !== null) {
                    const { Doc_Id, file } = documentData;
                    formdata.append("Doc_Type", documentType);
                    formdata.append("Doc_Id", Doc_Id);
                    formdata.append('Document_Name', file);
                } else {
                    formdata.append("Doc_Type", documentType);
                    formdata.append('Document_Name', documentData);
                }
            }
        });

        formdata.append("Customer_Id", customer?.Customer_Id);
        formdata.append("Customer_Name", `${userData?.First_Name} ${userData?.Last_Name}`);
        formdata.append("Certificate_Name", updateData?.Certificate_Name);

        await axios
            .put(`/updateCustomerDocumentByFacility/${updateData?.Enquiry_Sr_No}`, formdata)
            .then((res) => {
                handleClose();
                Swal.fire("Success!", "Documents Uploaded Successfully.", "success");
                hideLoader();
            })
            .catch((err) => {
                console.error(err);
                Swal.fire({
                    title: "Error",
                    text: "Failed to Upload. Please try again later.",
                    icon: "error",
                    confirmButtonText: "OK",
                });
                hideLoader();
            });
    };

    const ReuploadCertificateHandle = async (event) => {
        event.preventDefault();
        showLoader();

        const formdata = new FormData();
        customer?.Documents?.forEach(document => {
            for (const [documentType, documentData] of Object.entries(document || {})) {
                if (typeof documentData === 'object' && documentData !== null) {
                    const { Doc_Id, file } = documentData;
                    formdata.append("Doc_Type", documentType);
                    formdata.append("Doc_Id", Doc_Id);
                    formdata.append('Document_Name', file);
                } else {
                    formdata.append("Doc_Type", documentType);
                    formdata.append('Document_Name', documentData);
                }
            }
        });

        formdata.append("Customer_Id", customer.Customer_Id);
        formdata.append("Customer_Name", `${userData.First_Name} ${userData.Last_Name}`);
        formdata.append("Certificate_Name", updateData.Certificate_Name);

        await axios
            .put(`/reuploadCustomerDocument/${updateData?.Sr_No}`, formdata)
            .then((res) => {
                handleClose();
                Swal.fire("Success!", "Documents Uploaded Successfully.", "success");
                hideLoader();
            })
            .catch((err) => {
                console.error(err);
                Swal.fire({
                    title: "Error",
                    text: "Failed to Upload. Please try again later.",
                    icon: "error",
                    confirmButtonText: "OK",
                });
                hideLoader();
            });
    };


    const handleDocumentChange = (index, field, Doc_Id, file) => {
        const newDocuments = Array.isArray(customer.Documents) ? [...customer.Documents] : [];
        if (!newDocuments[index]) {
            newDocuments[index] = {};
        }

        // Check if the file size is less than 2MB
        if (file && file.size && file.size > 2 * 1024 * 1024) {
            Swal.fire({
                title: "Warning",
                text: "File size exceeds 2MB limit. Please select a smaller file.",
                icon: "warning",
                confirmButtonText: "OK",
            });
            return;
        }

        // File size is acceptable, continue adding to FormData
        newDocuments[index][field] = { Doc_Id, file };
        setCustomer({
            ...customer,
            Documents: newDocuments
        });
    };



    const handleDownloadAllDocuments = async () => {
        if (!customer.Documents || customer.Documents.length === 0) {
            alert('No Documents Found!!');
            return;
        }

        const zip = new JSZip();
        const promises = [];

        customer.Documents.forEach(document => {
            const fileName = document.Document_Name;
            const fileUrl = `/downloadDoc/${fileName}`;

            // Push each axios.get request promise to an array
            promises.push(
                axios.get(fileUrl, { responseType: 'arraybuffer' })
                    .then(response => {
                        zip.file(fileName, response.data, { binary: true });
                    })
                    .catch(error => {
                        console.error('Error fetching document:', error);
                    })
            );
        });

        // Wait for all requests to complete
        Promise.all(promises)
            .then(() => {
                // Generate the zip file after all requests are done
                zip.generateAsync({ type: 'blob' })
                    .then(blob => {
                        FileSaver.saveAs(blob, `${data.First_Name} ${data.Last_Name} - ${updateData.Certificate_Name}.zip`);
                    })
                    .catch(error => {
                        console.error('Error generating zip file:', error);
                    });
            });
    };

    const Download = (file, name) => {
        debugger;
        if (file && name) {
            const blob = new Blob([file], { type: file.type });
            const url = URL.createObjectURL(blob);

            // Create a temporary anchor element to view the file
            const a = document.createElement('a');
            a.href = url;
            a.target = "_blank"; // Open in a new tab
            //a.download = name;
            document.body.appendChild(a);
            a.click();

            // Clean up
            document.body.removeChild(a);
            URL.revokeObjectURL(url);
        }
    }


    return (
        <Modal
            show={show}
            onHide={handleClose}
            size="xl"
            centered
            style={{ boxShadow: "none !important" }}
        >

            <Modal.Body>
                <div className="row align-items-center">
                    <h5 className="col-md-6">Upload Document - {updateData.Certificate_Name}</h5>
                    <div className="col-md-6 text-end d-none">
                        <div className="mb-3">
                            <span className="text-dark">Download All Documents Zip : </span>
                            {customer.Documents.length > 0 ? (
                                <>
                                    <a style={{ color: "blue", cursor: 'pointer' }} download={true} onClick={handleDownloadAllDocuments} className="cursor-pointer">
                                        <i className="ti ti-download"></i>
                                    </a>
                                </>
                            ) : (
                                <>
                                    <i className="ti ti-download-off"></i>
                                </>
                            )}
                        </div>
                    </div>
                </div>


                <hr className="p-0 mb-1" />
                <form onSubmit={
                    Role === 'Customer'
                        ? (check ? ReuploadCertificateHandle : UploadCertificateHandle)
                        : UploadDocByFacility
                }
                >
                    <div className="row">
                        {documentName?.map((row, index) => (

                            <div className="col-md-4" key={index}>
                                <div className="mb-3">
                                    <label htmlFor={`${row.Doc_Name}_${index}`} className="form-label">
                                        {row.Doc_Name}
                                    </label>
                                    <input
                                        type="file"
                                        id={`${row.Doc_Name}_${index}`}
                                        className="form-control"
                                        required
                                        accept=".jpg, .jpeg, .png, .pdf, .docx, .doc, .xls, .xlsx"
                                        onChange={(e) => {
                                            seterrors({});
                                            handleDocumentChange(index, `${row.Doc_Name}`, `${check ? row.Id : row.Doc_Id}`, e.target.files[0]);
                                        }}
                                    />
                                </div>
                            </div>


                        ))}
                    </div>

                    <div className="row justify-content-center mt-4">
  <div className="col-md-2 col-sm-3 col-xs-3 col-3 d-flex justify-content-center">
    <button type="submit" className="btn btn-primary" >
       Upload
        </button>
  </div>
</div>
                </form>

                {loader}
            </Modal.Body>

            <div className="text-danger p-3">
                Note: Upload documents less than 2MB in size and please ensure that the files are in jpg, jpeg, png, doc, docx, xls, xlsx or pdf formats.
            </div>

        </Modal>
    );
}
export default UploadCertificate;
