import React, { useState, useEffect } from "react";
import axios from "../../components/axios";
import useFullPageLoader from "../../components/useFullPageLoader";
import { useParams, useNavigate, Link } from "react-router-dom";
import Swal from "sweetalert2";
import { formatDate } from "../../components/dateFormate";
import PaymentModal from "./PaymentModal";
import OfflinePaymentModal from "./OfflinePaymentModal";
import { useUser } from "../../components/UserContext";
import html2pdf from "html2pdf.js";

const Invoice = () => {
  const [loader, showLoader, hideLoader] = useFullPageLoader();
  const { Enq_Id, Payment_Id, Installment_Id, logId, Perfoma, Facility } =
    useParams();
  const navigate = useNavigate();
  const { userData } = useUser();
  const [installment, setInstallment] = useState({});
  const [invoiceDate, setInvoiceDate] = useState("");
  const [invoiceNumber, setInvoiceNumber] = useState("");
  const [invoice, setInvoice] = useState([]);
  const [logs, setLogs] = useState([]);
  const [mode, setMode] = useState("Online");
  const [allPaid, setallPaid] = useState(false);
  const [isModal1, setIsModal1] = useState(false);
  const [isModal2, setIsModal2] = useState(false);




  const showModal = () => {
    setIsModal1(true);
  };

  const closed = () => {
    setIsModal1(false);
    loadData();
  };

  const showModal2 = () => {
    setIsModal2(true);
  };
  const closed2 = () => {
    setIsModal2(false);
    loadData();
  };

  useEffect(() => {
    loadData();
  }, [userData, Enq_Id]);

  const generateAndSavePDF = async (invoice) => {
    const element = document.getElementById("printablediv");
    const opt = {
      margin: 10,
      filename: "Invoice.pdf",
      image: { type: "jpeg", quality: 0.98 },
      html2canvas: { scale: 2 },
      jsPDF: { unit: "mm", format: "a4", orientation: "portrait" },
    };

    try {
      const pdfBlob = await html2pdf().from(element).set(opt).outputPdf("blob");

      const whatsapp = {
        Customer: invoice?.Customer_Name,
        Certificate: invoice?.Certificate_Name,
        number: invoice?.Mobile,
        Email: invoice?.Email,
        Company_Name: invoice?.Company_Name
      };
      //console.log(whatsapp);
      showLoader();
      const formData = new FormData();
      formData.append(
        "pdf",
        pdfBlob,
        allPaid ? "TaxInvoice.pdf" : "Invoice.pdf"
      );
      formData.append("Customer", whatsapp.Customer);
      formData.append("Certificate", whatsapp.Certificate);
      formData.append("Email", whatsapp.Email);
      formData.append("number", whatsapp.number);
      formData.append("CompanyName",whatsapp.Company_Name)

      const response = await axios.post(`/savePerfomaInvoice`, formData);
      // Handle the response from the server if needed
      Swal.fire({
        icon: "success",
        title: "Perfoma Invoice!",
        text: "Perfoma sent Successfully",
        confirmButtonText: "OK",
        allowOutsideClick: false,
        allowEscapeKey: false,
      }).then((result) => {
        if (result.isConfirmed) {
          navigate("/viewEnquiry");
        }
      });
      hideLoader();
      //console.log("PDF saved on server.", response.data);
    } catch (error) {
      // Handle any errors
      console.error("Error saving PDF:", error);
      hideLoader();
    }
  };

  const generateInvoiceAndSavePDF = async (invoice) => {
    const element = document.getElementById("printablediv");
    const DebitNoteElement = document.getElementById("printDebitNote");

    const opt = {
      margin: 10,
      filename: "TaxInvoice.pdf",
      image: { type: "jpeg", quality: 0.98 },
      html2canvas: { scale: 2 },
      jsPDF: { unit: "mm", format: "a4", orientation: "portrait" },
    };
    const opt1 = {
      margin: 10,
      filename: "DebitNote.pdf",
      image: { type: "jpeg", quality: 0.98 },
      html2canvas: { scale: 2 },
      jsPDF: { unit: "mm", format: "a4", orientation: "portrait" },
    };

    try {
      const pdfBlob = await html2pdf().from(element).set(opt).outputPdf("blob");
      const pdfBlob1 = await html2pdf()
        .from(DebitNoteElement)
        .set(opt1)
        .outputPdf("blob");
      showLoader();
      const formData = new FormData();
      formData.append("TaxInvoice", pdfBlob, "TaxInvoice.pdf");
      formData.append("DebitNote", pdfBlob1, "DebitNote.pdf");
      formData.append("Customer", invoice?.Customer_Name);
      formData.append("Certificate", invoice?.Certificate_Name);
      formData.append("Email", invoice?.Email);
      formData.append("number", invoice?.Mobile);

      const response = await axios.post(`/downLoadInvoice`, formData);

      if (response?.data?.taxInvoiceURL) {
        await axios
          .get(`/downloadInvoicePDF/${response.data.taxInvoiceURL}`, {
            responseType: "blob", // Ensure response is treated as blob
          })
          .then((response) => {
            const url = window.URL.createObjectURL(new Blob([response.data]));
            const link = document.createElement("a");
            link.href = url;
            link.setAttribute("download", "TaxInvoice.pdf");
            document.body.appendChild(link);
            link.click();
            document.body.removeChild(link);
          });
      }

      if (response?.data?.debitNoteURL) {
        await axios
          .get(`/downloadInvoicePDF/${response.data.debitNoteURL}`, {
            responseType: "blob", // Ensure response is treated as blob
          })
          .then((response) => {
            const url = window.URL.createObjectURL(new Blob([response.data]));
            const link = document.createElement("a");
            link.href = url;
            link.setAttribute("download", "DebitNote.pdf");
            document.body.appendChild(link);
            link.click();
            document.body.removeChild(link);
          });
      }
      hideLoader();
      console.log("PDFs downloaded successfully.");
    } catch (error) {
      console.error("Error saving or downloading PDFs:", error);
      hideLoader();
    }
  };

  const generateTaxInvoiceAndSavePDF = async (invoice) => {
    const element = document.getElementById("printablediv");
    const opt = {
      margin: 10,
      filename: "TaxInvoice.pdf",
      image: { type: "jpeg", quality: 0.98 },
      html2canvas: { scale: 2 },
      jsPDF: { unit: "mm", format: "a4", orientation: "portrait" },
    };

    try {
      const pdfBlob = await html2pdf().from(element).set(opt).outputPdf("blob");

      const whatsapp = {
        Customer: invoice?.Customer_Name,
        Certificate: invoice?.Certificate_Name,
        number: invoice?.Mobile,
        Email: invoice?.Email,
        Company_Name: invoice?.Company_Name
      };
      //console.log(whatsapp);
      showLoader();

      const formData = new FormData();
      formData.append("pdf", pdfBlob, "TaxInvoice.pdf");
      formData.append("Customer", whatsapp.Customer);
      formData.append("Certificate", whatsapp.Certificate);
      formData.append("Email", whatsapp.Email);
      formData.append("number", whatsapp.number);
      formData.append("CompanyName",whatsapp.Company_Name);

      const response = await axios.post(`/saveTaxInvoice`, formData);
      hideLoader();
      // Handle the response from the server if needed
      // Swal.fire({
      //   icon: "success",
      //   title: "Perfoma Invoice!",
      //   text: "Perfoma sent Successfully",
      //   confirmButtonText: "OK",
      //   allowOutsideClick: false,
      //   allowEscapeKey: false,
      // }).then((result) => {
      //   if (result.isConfirmed) {
      //     navigate("/viewEnquiry");
      //   }
      // });

      //console.log("PDF saved on server.", response.data);
    } catch (error) {
      // Handle any errors
      console.error("Error saving PDF:", error);
      hideLoader();
    }
  };

  const generateDebitNoteAndSavePDF = async (invoice) => {
    const element = document.getElementById("printDebitNote");
    const opt = {
      margin: 10,
      filename: "DebitNote.pdf",
      image: { type: "jpeg", quality: 0.98 },
      html2canvas: { scale: 2 },
      jsPDF: { unit: "mm", format: "a4", orientation: "portrait" },
    };

    try {
      const pdfBlob = await html2pdf().from(element).set(opt).outputPdf("blob");

      const whatsapp = {
        Customer: invoice?.Customer_Name,
        Certificate: invoice?.Certificate_Name,
        number: invoice?.Mobile,
        Email: invoice?.Email,
        Company_Name: invoice?.Company_Name
      };
      //console.log(whatsapp);
      showLoader();
      const formData = new FormData();
      formData.append("pdf", pdfBlob, "DebitNote.pdf");
      formData.append("Customer", whatsapp.Customer);
      formData.append("Certificate", whatsapp.Certificate);
      formData.append("Email", whatsapp.Email);
      formData.append("number", whatsapp.number);
      formData.append("CompanyName",whatsapp.Company_Name)

      const response = await axios.post(`/saveDebitNote`, formData);
      hideLoader();
      // Handle the response from the server if needed
      // Swal.fire({
      //   icon: "success",
      //   title: "Perfoma Invoice!",
      //   text: "Perfoma sent Successfully",
      //   confirmButtonText: "OK",
      //   allowOutsideClick: false,
      //   allowEscapeKey: false,
      // }).then((result) => {
      //   if (result.isConfirmed) {
      //     navigate("/viewEnquiry");
      //   }
      // });

      //console.log("PDF saved on server.", response.data);
    } catch (error) {
      // Handle any errors
      console.error("Error saving PDF:", error);
      hideLoader();
    }
  };

  useEffect(() => {
    if (Perfoma === "Perfoma" && invoice.length > 0) {
      generateAndSavePDF(invoice[0]);
    }
  }, [Perfoma, invoice]);

  const loadData = async () => {
    showLoader();
    try {
      if (Installment_Id) {
        const installmentResponse = await axios.get(
          `/getInstallmentById/${Installment_Id}`
        );
        console.log(installmentResponse.data[0]);
        setInstallment(installmentResponse.data[0]);
      }
      const logsResponse = await axios.get(
        `/getInstallmentWithPaymentLog/${Enq_Id}`
      );
      console.log(logsResponse.data);
      setLogs(logsResponse.data);
      const allPaid = logsResponse.data.every(
        (log) => log.Payment_Status === "Paid"
      );
      // console.log(allPaid);
      setallPaid(allPaid);
      const invoiceResponse = await axios.get(`/getPaymentSummary/${Enq_Id}`);
      console.log(invoiceResponse.data);
      setInvoice(invoiceResponse.data);

      if (Payment_Id) {
        Swal.fire({
          icon: "success",
          title: "Payment Success!",
          text: "Your payment has been successfully processed.",
          confirmButtonText: "OK",
          allowOutsideClick: false,
          allowEscapeKey: false,
        }).then((result) => {
          if (result.isConfirmed) {
            handleSubmit();
          }
        });
      } else if (Payment_Id === "NotFound") {
        Swal.fire({
          icon: "error",
          title: "Payment Failed",
          text: "We were unable to process your payment. Please try again.",
          confirmButtonText: "Retry",
          allowOutsideClick: false,
          allowEscapeKey: false,
        }).then((result) => {
          if (result.isConfirmed) {
            navigate(`/invoice/${Enq_Id}`);
          }
        });
      }

      hideLoader();
    } catch (error) {
      hideLoader();
      console.error("Error fetching data: ", error);
    }
  };

  useEffect(() => {
    (async () => {
      if (invoice[0]?.Invoice_No === null) {
        showLoader();
        try {
          const result = await axios.get(`/getInvoiceNo`);
          console.log(result.data);
          const nextInvoiceNumber = generateNextInvoiceNumber(
            result.data ? result.data : ""
          );
          setInvoiceNumber(nextInvoiceNumber);
        } catch (error) {
          console.error("Error fetching invoices:", error);
        }

        hideLoader();
      } else {
        setInvoiceNumber(invoice[0]?.Invoice_No);
        setInvoiceDate(invoice[0]?.Invoice_Date);
      }
    })();
  }, [invoice]);

  function generateNextInvoiceNumber(existingInvoices) {
    if (existingInvoices === "") {
      return "0001";
    }

    // Split the invoice number if it contains a "-"
    const parts = existingInvoices.split("-");
    const lastNumber =
      parts.length > 1 ? parseInt(parts[1], 10) : parseInt(parts[0], 10);

    if (isNaN(lastNumber)) {
      throw new Error("Invalid invoice number format");
    }

    const nextNumber = lastNumber + 1;

    // Format the next invoice number with leading zeros
    const nextInvoiceNumber = `${String(nextNumber).padStart(4, "0")}`;

    return nextInvoiceNumber;
  }
  const handleSubmit = async () => {
    const postPaymentDetails = {
      Enq_Id: Enq_Id,
      logId: logId,
      Rpayment_Id: Payment_Id,
      Installment_Id: Installment_Id,
      Org_Id: "",
      User_Role: "Customer",
      UserName: userData?.Username,
      Added_By_Id: userData?.UserId,
      Added_By: `${userData?.First_Name} ${userData?.Last_Name}`,
    };

    const validationErrors = {};

    if (Object.keys(validationErrors).length === 0) {
      showLoader();
      await axios
        .put(`/onlinePayment`, postPaymentDetails)
        .then((res) => {
          navigate(`/invoice/${Enq_Id}`);
          window.location.reload();
        })
        .catch((err) => {
          console.log(err);
          Swal.fire({
            title: "Error",
            text: "Failed to payment. Please try again later.",
            icon: "error",
            confirmButtonText: "OK",
          });
          hideLoader();
        });
    }
  };

  const GenerateInvoiceNo = async () => {
    showLoader();
    await axios
      .put(`/generateInvoiceNo/${Enq_Id}/${invoiceNumber}`)
      .then((res) => {
        //navigate(`/invoice/${Enq_Id}`);
        loadData();
        hideLoader();
      })
      .catch((err) => {
        console.log(err);
        Swal.fire({
          title: "Error",
          text: "Failed to generate Invoice No. Please try again later.",
          icon: "error",
          confirmButtonText: "OK",
        });
        hideLoader();
      });
  };

  function number2text(value) {
    var fraction = Math.round(frac(value) * 100);
    var f_text = "";

    if (fraction > 0) {
      f_text = "AND " + convert_number(fraction) + " PAISE";
    }

    return convert_number(value) + " RUPEES " + f_text + " ONLY";
  }

  function frac(f) {
    return f % 1;
  }

  function convert_number(number) {
    if (number < 0 || number > 999999999) {
      return "NUMBER OUT OF RANGE!";
    }
    var Gn = Math.floor(number / 10000000); /* Crore */
    number -= Gn * 10000000;
    var kn = Math.floor(number / 100000); /* lakhs */
    number -= kn * 100000;
    var Hn = Math.floor(number / 1000); /* thousand */
    number -= Hn * 1000;
    var Dn = Math.floor(number / 100); /* Tens (deca) */
    number = number % 100; /* Ones */
    var tn = Math.floor(number / 10);
    var one = Math.floor(number % 10);
    var res = "";

    if (Gn > 0) {
      res += convert_number(Gn) + " CRORE";
    }
    if (kn > 0) {
      res += (res === "" ? "" : " ") + convert_number(kn) + " LAKH";
    }
    if (Hn > 0) {
      res += (res === "" ? "" : " ") + convert_number(Hn) + " THOUSAND";
    }

    if (Dn) {
      res += (res === "" ? "" : " ") + convert_number(Dn) + " HUNDRED";
    }

    var ones = [
      "",
      "ONE",
      "TWO",
      "THREE",
      "FOUR",
      "FIVE",
      "SIX",
      "SEVEN",
      "EIGHT",
      "NINE",
      "TEN",
      "ELEVEN",
      "TWELVE",
      "THIRTEEN",
      "FOURTEEN",
      "FIFTEEN",
      "SIXTEEN",
      "SEVENTEEN",
      "EIGHTEEN",
      "NINETEEN",
    ];
    var tens = [
      "",
      "",
      "TWENTY",
      "THIRTY",
      "FORTY",
      "FIFTY",
      "SIXTY",
      "SEVENTY",
      "EIGHTY",
      "NINETY",
    ];

    if (tn > 0 || one > 0) {
      if (!(res === "")) {
        res += " AND ";
      }
      if (tn < 2) {
        res += ones[tn * 10 + one];
      } else {
        res += tens[tn];
        if (one > 0) {
          res += "-" + ones[one];
        }
      }
    }

    if (res === "") {
      res = "ZERO";
    }
    return res;
  }

  const createCustomInvoicePDF = () => {
    let printContents = document.getElementById("printablediv").innerHTML;
    let originalContents = document.body.innerHTML;
    document.body.innerHTML = printContents;

    // Print the document
    window.print();

    // Restore the original contents
    document.body.innerHTML = originalContents;
  };

  const generatePerfomaAndSavePDF = async () => {
    const element = document.getElementById("printablediv");
    const opt = {
      margin: 10,
      filename: `${invoice[0]?.Company_Name}_${invoice[0]?.Certificate_Name}_${invoice[0]?.Enq_Id}.pdf`,
      image: { type: "jpeg", quality: 0.98 },
      html2canvas: { scale: 2 },
      jsPDF: { unit: "mm", format: "a4", orientation: "portrait" },
    };

    try {
      const pdfBlob = await html2pdf().from(element).set(opt).outputPdf("blob");
      const link = document.createElement("a");
      link.href = window.URL.createObjectURL(pdfBlob);
      link.download = `${invoice[0]?.Company_Name}_${invoice[0]?.Certificate_Name}_${invoice[0]?.Enq_Id}.pdf`;
      link.click();
    } catch (error) {
      console.error("Error generating PDF:", error);
    }
  };
console.log(invoice[0])
  return (
    <div className="row">
      <div className="col-12">
        <div className="row">
          <div className="col-md-12">
            {Perfoma !== "Perfoma" && (
              <div className="row mb-4">
                {!allPaid && !userData?.Role && (
                  <>
                    <div className="col-md-6"></div>
                    <div className="col-md-2 ms-auto">
                      <select
                        className="form-select"
                        id="mode"
                        value={mode}
                        onChange={(e) => {
                          setMode(e.target.value);
                        }}
                      >
                        <option selected value="Online">
                          Online
                        </option>
                        <option value="Offline">Offline</option>
                      </select>
                    </div>
                    <div className="col-md-2">
                      <button
                        className="btn btn-primary"
                        onClick={() => {
                          if (mode === "Online") {
                            showModal();
                          } else {
                            showModal2();
                          }
                        }}
                      >
                        Pay Installment
                      </button>
                    </div>
                  </>
                )}
                {allPaid ? (
                  <>
                    {" "}
                    <div className="col-md-2 ms-auto">
                      <button
                        className="btn btn-primary"
                        onClick={() => {
                          generateInvoiceAndSavePDF(invoice[0]);

                          if (invoice[0]?.Invoice_No === null) {
                            GenerateInvoiceNo();
                          }
                        }}
                      >
                        Download
                      </button>
                    </div>
                    {userData?.Role && (
                      <div className="col-md-2">
                        <button
                          className="btn btn-primary"
                          onClick={() => {
                            generateTaxInvoiceAndSavePDF(invoice[0]);
                            generateDebitNoteAndSavePDF(invoice[0]);
                            if (invoice[0]?.Invoice_No === null) {
                              GenerateInvoiceNo();
                            }

                            Swal.fire({
                              icon: "success",
                              title: "Tax Invoice!",
                              text: "Tax Invoice sent Successfully",
                              confirmButtonText: "OK",
                              allowOutsideClick: false,
                              allowEscapeKey: false,
                            }).then((result) => {
                              if (result.isConfirmed) {
                                navigate("/viewEnquiry");
                              }
                            });
                          }}
                        >
                          Send
                        </button>
                      </div>
                    )}{" "}
                  </>
                ) : (
                  <div className="col-md-2 ms-auto">
                    <button
                      className="btn btn-primary"
                      onClick={() => {
                        generatePerfomaAndSavePDF();
                      }}
                    >
                      Download
                    </button>
                  </div>
                )}
              </div>
            )}
          </div>
        </div>

        <div className="card">
          <div className="card-body">
            <div className="container">
              <div
                id="printablediv"
                style={{
                  borderStyle: "solid",
                  borderColor: "#709a08", // Choose any color for the border
                  borderWidth: "2px", // Adjust thickness
                  borderRadius: "5px", // Add rounded corners if needed
                  padding: "10px", // Add padding to space content from border
                }}
              >
                <div
                  style={{
                    backgroundColor: "#709a08",
                    color: "white",
                    padding: "1px",
                    margin: "-10px",
                  }}
                >
                  <h3
                    style={{
                      textAlign: "center",
                      marginTop: "1px",
                      marginBottom: "0px",
                    }}
                  >
                    {" "}
                    {allPaid ? "Tax Invoice" : "Proforma Invoice"}
                  </h3>
                </div>
                <div className="div mt-4">
                  <div className="row mb-1">
                    <div className="col-md-4 col-sm-4">
                      <img
                        src="/assets/img/logo1.jpeg"
                        alt="logo"
                        style={{
                          maxWidth: "215px",
                          height: "auto",
                          width: "100%",
                          paddingLeft: 0,
                        }}
                      />
                    </div>
                    <div
                      className="col-md-8 col-sm-8 text-center "
                      style={{ lineHeight: "1.5" }}
                    >
                      <h4 className="mb-1">ENVIRONATURE SOLUTION</h4>
                      <p style={{ marginBottom: 0, color: "#333" }}>
                        Office No 128, Devi Annapurna Society, Plot No.08,
                        Sector 18, Mafco Market Road, Near Sanpada Railway
                        Station, Vashi, Mumbai 400705.
                      </p>
                      <div className="text-center" style={{ color: "#333" }}>
                        <strong>State Name :</strong> Maharashtra &nbsp;&nbsp;
                        <strong>Code :</strong> 27
                      </div>{" "}
                      <div className="text-center" style={{ color: "#333" }}>
                        <strong>GSTIN/UIN :</strong> 27AQUPS8980K2ZH
                        <br />
                        <strong>Phone :</strong> 02235220348 / 9326897902
                      </div>
                      <div className="text-center" style={{ color: "#333" }}>
                        <strong>Email :</strong>{" "}
                        <a href="mailto:info@environaturesolution.com">
                          info@environaturesolution.com
                        </a>
                      </div>
                    </div>
                  </div>
                  <hr
                    className="mb-2"
                    style={{ borderWidth: "1px", margin: 0 }}
                  />
                  <div
                    className="row justify-content-between"
                    style={{ color: "#333", lineHeight: "1.5" }}
                  >
                    <div className="col-md-7 col-sm-7">
                      <address>
                        <strong>Bill To,</strong>
                        <br />
                        {/*  <strong>{invoice[0]?.Customer_Name}</strong>
                      <br /> */}
                        {invoice[0]?.Role === "Agent" ? invoice[0]?.Customer_Company : invoice[0]?.Company_Name}
                        <br />
                        {invoice[0]?.GST_No && (
                          <div>
                            <strong>GSTIN/UIN : </strong>
                            {invoice[0]?.GST_No ? invoice[0]?.GST_No : ""}
                            <br />
                          </div>
                        )}

                        {invoice[0]?.Address && (
                          <div>
                            {invoice[0]?.Address || ""}
                            <br />
                          </div>
                        )}
                        {/* {invoice[0]?.Mobile} */}
                      </address>
                    </div>
                    <div className="col-md-4 col-sm-4">
                      <table className="table-responsive">
                        <tbody>
                          {allPaid && (
                            <tr>
                              <td
                                className="pull-right"
                                style={{
                                  paddingRight: "8px",
                                  textAlign: "right",
                                }}
                              >
                                <strong>Invoice No. :</strong>
                              </td>
                              <td>{invoiceNumber ? invoiceNumber : ""}</td>
                            </tr>
                          )}
                          <tr>
                            <td
                              className="pull-right"
                              style={{
                                paddingRight: "8px",
                                textAlign: "right",
                              }}
                            >
                              <strong>Enquiry ID :</strong>
                            </td>
                            <td>{invoice[0]?.Enquiry_Id}</td>
                          </tr>

                          <tr>
                            <td
                              className="pull-right"
                              style={{
                                paddingRight: "8px",
                                textAlign: "right",
                              }}
                            >
                              <strong>State Name :</strong>
                            </td>
                            <td>{invoice[0]?.State}</td>
                          </tr>
                          <tr>
                            <td
                              className="pull-right"
                              style={{
                                paddingRight: "8px",
                                textAlign: "right",
                              }}
                            >
                              <strong>Date :</strong>
                            </td>
                            <td>
                              {formatDate(
                                invoiceDate ? invoiceDate : new Date()
                              )}
                            </td>
                          </tr>
                        </tbody>
                      </table>
                    </div>
                  </div>

                  {!allPaid ? (
                    <>
                      <div className="row col-sm-4">
                        <div className="span8">
                          <h2 style={{ fontSize: "25px", marginBottom: "5px" }}>
                            <strong></strong>
                          </h2>
                        </div>
                      </div>
                      <div className="row mb-2">
                        <div className="span8 well invoice-body">
                          <div className="col-md-12 col-sm-12 table-responsive">
                            <table className="table productTable display table-bordered">
                              <thead>
                                <tr>
                                  <th
                                    className="text-center"
                                    style={{ minWidth: "400px", padding: 5 }}
                                  >
                                    Certificate
                                  </th>
                                  <th
                                    className="text-center"
                                    style={{ padding: 5 }}
                                  >
                                    HSN Code
                                  </th>
                                  <th
                                    className="text-center"
                                    style={{ padding: 5 }}
                                  >
                                    Consultation Charges
                                  </th>
                                </tr>
                              </thead>
                              <tbody>
                                {invoice.map((pro, index) => (
                                  <>
                                    <tr key={index}>
                                      <td style={{ padding: 3 }}>
                                        {pro?.Certificate_Name}
                                      </td>
                                      <td
                                        className="text-center"
                                        style={{ padding: 3 }}
                                      >
                                        {pro?.HSN}
                                      </td>
                                      <td
                                        className="text-center"
                                        style={{ padding: 3 }}
                                      >
                                        {pro?.Consultation_Charges}
                                      </td>
                                    </tr>
                                    <tr>
                                      <td colSpan={3} style={{ padding: 3 }}>
                                        {pro?.Description}
                                      </td>
                                    </tr>
                                  </>
                                ))}
                                <tr>
                                  <td colSpan="2"></td>
                                </tr>
                                {invoice[0]?.State !== "Maharashtra" ? (
                                  <tr>
                                    <td
                                      style={{ padding: "0px, 5px" }}
                                      colSpan={2}
                                      align="right"
                                    >
                                      <strong>
                                        IGST
                                        {`(${invoice[0]?.Gst_Percent || 0}%)`}
                                      </strong>
                                    </td>
                                    <td
                                      style={{ padding: "0px, 5px" }}
                                      align="right"
                                    >
                                      <strong>
                                        {parseFloat(
                                          invoice[0]?.Gst_Amount || 0
                                        ).toFixed(2)}
                                      </strong>
                                    </td>
                                  </tr>
                                ) : (
                                  <tr>
                                    <td
                                      style={{ padding: 5 }}
                                      align="right"
                                      colSpan={2}
                                    >
                                      <strong>
                                        GST
                                        {`(${invoice[0]?.Gst_Percent || 0}%)`}
                                      </strong>
                                    </td>
                                    <td style={{ padding: 0 }} align="right">
                                      <table
                                        style={{
                                          border: "none",
                                          borderCollapse: "collapse",
                                          width: "100%",
                                        }}
                                      >
                                        <tbody>
                                          <tr
                                            style={{
                                              borderBottom: "1px solid #dbdade",
                                            }}
                                          >
                                            <td
                                              style={{
                                                padding: "0px 10px",
                                                border: "none",
                                              }}
                                              align="right"
                                            >
                                              <strong>
                                                SGST
                                                {`(${
                                                  invoice[0]?.Gst_Percent / 2 ||
                                                  0
                                                }%)`}
                                              </strong>
                                            </td>
                                            <td
                                              style={{
                                                padding: "0px 10px",
                                                border: "none",
                                              }}
                                              align="right"
                                            >
                                              <strong>
                                                {parseFloat(
                                                  invoice[0]?.Gst_Amount / 2
                                                ).toFixed(2) || 0}
                                              </strong>
                                            </td>
                                          </tr>
                                          <tr>
                                            <td
                                              style={{
                                                padding: "0px 10px",
                                                border: "none",
                                              }}
                                              align="right"
                                            >
                                              <strong>
                                                CGST
                                                {`(${
                                                  invoice[0]?.Gst_Percent / 2 ||
                                                  0
                                                }%)`}
                                              </strong>
                                            </td>
                                            <td
                                              style={{
                                                padding: "0px 10px",
                                                border: "none",
                                              }}
                                              align="right"
                                            >
                                              <strong>
                                                {parseFloat(
                                                  invoice[0]?.Gst_Amount / 2
                                                ).toFixed(2) || 0}
                                              </strong>
                                            </td>
                                          </tr>
                                        </tbody>
                                      </table>
                                    </td>
                                  </tr>
                                )}

                                <tr>
                                  <td
                                    style={{ padding: "0px 10px" }}
                                    colSpan={2}
                                    align="right"
                                  >
                                    <strong>
                                      Total Consultation Charges Incl. Tax
                                    </strong>
                                  </td>
                                  <td
                                    style={{ padding: "0px 10px" }}
                                    align="right"
                                  >
                                    <strong>
                                      {parseFloat(
                                        invoice[0]?.Total_Consultation || 0
                                      ).toFixed(2)}
                                    </strong>
                                  </td>
                                </tr>
                                <tr>
                                  <td
                                    style={{ padding: "0px 10px" }}
                                    colSpan={2}
                                    align="right"
                                  >
                                    <strong> Gvt. Charges</strong>
                                  </td>
                                  <td
                                    style={{ padding: "0px 10px" }}
                                    align="right"
                                  >
                                    <strong>
                                      {parseFloat(
                                        invoice[0]?.Gvt_Amount || 0
                                      ).toFixed(2)}
                                    </strong>
                                  </td>
                                </tr>
                                <tr>
                                  {/* <td style={{ padding: 7 }} colSpan={1}>{`${number2text(
                            parseFloat(invoice.Net_Total)
                        )}`}</td> */}
                                  <td
                                    style={{ padding: "0px 10px" }}
                                    align="right"
                                    colSpan={2}
                                  >
                                    <strong>Net Total</strong>
                                  </td>
                                  <td
                                    style={{ padding: "0px 10px" }}
                                    align="right"
                                  >
                                    <strong>
                                      {parseFloat(
                                        invoice[0]?.Net_Amount || 0
                                      ).toFixed(2)}
                                    </strong>
                                  </td>
                                </tr>
                                <tr>
                                  <td style={{ padding: 5 }} colSpan={3}>
                                    <strong>In Words: </strong>
                                    {`${number2text(
                                      parseFloat(invoice[0]?.Net_Amount || 0)
                                    )}`}
                                  </td>
                                </tr>
                              </tbody>
                            </table>
                          </div>
                        </div>
                      </div>

                      <div
                        className="row"
                        style={{ color: "#333", lineHeight: "1.5" }}
                      >
                        <div className="col-md-6 col-sm-6 mt-2">
                          <strong>Account Details :</strong>
                          <br />
                          A/c Name : ENVIRONATURE SOLUTION
                          <br />
                          A/c No : 924020009540955
                          <br />
                          Bank Name : Axis Bank
                          <br />
                          Branch & IFS Code : Navi Mumbai & UTIB0000885
                          <br />
                        </div>

                        {/* <div className="col-md-6   col-sm-6">
              <div align="right" >
                <img
                  src="/assets/images/QQR.png"
                  alt="logo"
                  style={{
                    maxWidth: "150px",
                    height: "auto",
                    width: "100%",
                    paddingLeft: 0,
                  }}
                />
              </div>
            </div> */}

<div className="col-md-6  col-sm-6">
                      <p align="right" style={{ paddingRight: "30px" }}>
                        <> FOR ENVIRONATURE SOLUTION</>
                      </p>
                        <p
                          align="right"
                          style={{ paddingRight: "30px", marginBottom: 0 }}
                        >
                          <img
                            src="/assets/img/stamp.png"
                            alt="stamp"
                            style={{
                              maxWidth: "197px",
                              height: "auto",
                              width: "100%",
                              paddingLeft: 0,
                              marginTop:"-15px"
                            }}
                          />
                        </p>
                        <p align="right" style={{ paddingRight: "30px" }}>
                        <> Authorized Signatory</>
                      </p>
                      </div>
                      </div>

                      <div className="col-md-12">
                        <div>
                          <strong style={{ color: "#333" }}>
                            Installment Details :{" "}
                          </strong>
                          <table
                            className="tables productTable display dataTable table-bordered "
                            cellspacing="0"
                            style={{
                              borderCollapse: "collapse",
                              border: "1px solid rgba(33, 33, 33, 0.1)",
                              color: "#333",
                            }}
                          >
                            <thead>
                              <tr align="center">
                                <th className="text-center" scope="col">
                                  Amount
                                </th>
                                <th className="text-center" scope="col">
                                  Due Date
                                </th>

                                <th className="text-center" scope="col">
                                  Payment Status
                                </th>

                                <th className="text-center" scope="col">
                                  Remarks
                                </th>
                              </tr>
                            </thead>
                            <tbody>
                              {logs ? (
                                logs.map((row, index) => (
                                  <tr align="center" key={index}>
                                    <td>
                                      <span>
                                        {row.Amount ? row.Amount : "--"}
                                      </span>
                                    </td>
                                    <td>
                                      <span className="f-12">
                                        {row.Due_Date
                                          ? formatDate(row.Due_Date)
                                          : "--"}
                                      </span>
                                    </td>

                                    <td>
                                      <span>
                                        {row.Payment_Status
                                          ? row.Payment_Status
                                          : "--"}
                                      </span>
                                    </td>

                                    <td>
                                      <span>
                                        {row.Remarks ? row.Remarks : "--"}
                                      </span>
                                    </td>
                                  </tr>
                                ))
                              ) : (
                                <tr>
                                  <td colSpan="4">No data available</td>
                                </tr>
                              )}
                            </tbody>
                          </table>
                        </div>
                      </div>
                    </>
                  ) : (
                    <>
                      {/* <div className="col-md-12">
                      <div>
                        <strong style={{ color: "#333" }}>
                          Payment Details :{" "}
                        </strong>
                        <table
                          className="tables productTable display dataTable table-bordered "
                          cellspacing="0"
                          style={{
                            borderCollapse: "collapse",
                            border: "1px solid rgba(33, 33, 33, 0.1)",
                            color: "#333",
                          }}
                        >
                          <thead>
                            <tr align="center">
                              <th className="text-center" scope="col">
                                Amount
                              </th>
                              <th className="text-center" scope="col">
                                Payment Date
                              </th>
                              <th className="text-center" scope="col">
                                Payment Status
                              </th>
                              <th className="text-center" scope="col">
                                Payment Mode
                              </th>

                              <th className="text-center" scope="col">
                                Cheque Details
                              </th>

                              <th className="text-center" scope="col">
                                Transaction Id
                              </th>
                            </tr>
                          </thead>
                          <tbody>
                            {logs ? (
                              logs.map((row, index) => (
                                <tr align="center" key={index}>
                                  <td>
                                    <span>
                                      {row.Amount ? row.Amount : "--"}
                                    </span>
                                  </td>
                                  <td>
                                    <span className="f-12">
                                      {row.Due_Date
                                        ? formatDate(row.Payment_Date)
                                        : "--"}
                                    </span>
                                  </td>
                                  <td>
                                    <span>
                                      {row.Payment_Status
                                        ? row.Payment_Status
                                        : "--"}
                                    </span>
                                  </td>
                                  <td>
                                    <span>
                                      {row.Payment_Mode
                                        ? row.Payment_Mode
                                        : "--"}
                                    </span>
                                  </td>
                                  <td>
                                    {row.Bank_Name ? (
                                      <span>
                                        Bank Name: {row.Bank_Name}
                                        <br />
                                        Cheque No: {row.Cheque_No}
                                        <br />
                                        Cheque Date:{" "}
                                        {formatDate(row.Cheque_Date)}
                                      </span>
                                    ) : (
                                      "--"
                                    )}
                                  </td>

                              
                                  <td>
                                    <span>
                                      {row.OrgId
                                        ? row.Transaction_Id
                                        : row.Rpayment_Id}
                                    </span>
                                  </td>
                                </tr>
                              ))
                            ) : (
                              <tr>
                                <td colSpan="5">No data available</td>
                              </tr>
                            )}
                          </tbody>
                        </table>
                      </div>
                    </div> */}

                      <div className="row mb-2">
                        <div className="span8 well invoice-body">
                          <div className="col-md-12 col-sm-12 table-responsive">
                            <table className="table productTable display table-bordered">
                              <thead>
                                <tr>
                                  {/* Gvt_AmountConsultation_ChargesTotal_ConsultationNet_AmountGst_PercentGst_Amount */}
                                  <th
                                    className="text-center"
                                    style={{ minWidth: "400px" }}
                                  >
                                    Certificate
                                  </th>
                                  <th className="text-center">HSN Code</th>
                                  <th className="text-center">
                                    Consultation Charges
                                  </th>
                                </tr>
                              </thead>
                              <tbody>
                                {invoice.map((pro, index) => (
                                  <>
                                    <tr key={index}>
                                      <td style={{ padding: 5 }}>
                                        {pro?.Certificate_Name}
                                      </td>
                                      <td
                                        className="text-center"
                                        style={{ padding: 5 }}
                                      >
                                        {pro?.HSN}
                                      </td>
                                      <td
                                        className="text-center"
                                        style={{ padding: 5 }}
                                      >
                                        {pro?.Consultation_Charges}
                                      </td>
                                    </tr>
                                    <tr>
                                      <td colSpan={3} style={{ padding: 3 }}>
                                        {pro?.Description}
                                      </td>
                                    </tr>
                                  </>
                                ))}
                                <tr>
                                  <td colSpan="2"></td>
                                </tr>
                                {invoice[0]?.State !== "Maharashtra" ? (
                                  <tr>
                                    <td
                                      style={{ padding: 7 }}
                                      colSpan={2}
                                      align="right"
                                    >
                                      <strong>
                                        IGST
                                        {`(${invoice[0]?.Gst_Percent || 0}%)`}
                                      </strong>
                                    </td>
                                    <td style={{ padding: 7 }} align="right">
                                      <strong>
                                        {parseFloat(
                                          invoice[0]?.Gst_Amount || 0
                                        ).toFixed(2)}
                                      </strong>
                                    </td>
                                  </tr>
                                ) : (
                                  <tr>
                                    <td
                                      style={{ padding: 7 }}
                                      align="right"
                                      colSpan={2}
                                    >
                                      <strong>
                                        GST
                                        {`(${invoice[0]?.Gst_Percent || 0}%)`}
                                      </strong>
                                    </td>
                                    <td style={{ padding: 0 }} align="right">
                                      <table
                                        style={{
                                          border: "none",
                                          borderCollapse: "collapse",
                                          width: "100%",
                                        }}
                                      >
                                        <tbody>
                                          <tr
                                            style={{
                                              borderBottom: "1px solid #dbdade",
                                            }}
                                          >
                                            <td
                                              style={{
                                                padding: 7,
                                                border: "none",
                                              }}
                                              align="right"
                                            >
                                              <strong>
                                                SGST
                                                {`(${
                                                  invoice[0]?.Gst_Percent / 2 ||
                                                  0
                                                }%)`}
                                              </strong>
                                            </td>
                                            <td
                                              style={{
                                                padding: 7,
                                                border: "none",
                                              }}
                                              align="right"
                                            >
                                              <strong>
                                                {parseFloat(
                                                  invoice[0]?.Gst_Amount / 2
                                                ).toFixed(2) || 0}
                                              </strong>
                                            </td>
                                          </tr>
                                          <tr>
                                            <td
                                              style={{
                                                padding: 7,
                                                border: "none",
                                              }}
                                              align="right"
                                            >
                                              <strong>
                                                CGST
                                                {`(${
                                                  invoice[0]?.Gst_Percent / 2 ||
                                                  0
                                                }%)`}
                                              </strong>
                                            </td>
                                            <td
                                              style={{
                                                padding: 7,
                                                border: "none",
                                              }}
                                              align="right"
                                            >
                                              <strong>
                                                {parseFloat(
                                                  invoice[0]?.Gst_Amount / 2
                                                ).toFixed(2) || 0}
                                              </strong>
                                            </td>
                                          </tr>
                                        </tbody>
                                      </table>
                                    </td>
                                  </tr>
                                )}

                                {/* <tr>
                            <td
                              style={{ padding: 7 }}
                              colSpan={2}
                              align="right"
                            >
                              <strong>
                                Total Consultation Charges Incl. Tax
                              </strong>
                            </td>
                            <td style={{ padding: 7 }} align="right">
                              <strong>
                                {parseFloat(
                                  invoice[0]?.Total_Consultation || 0
                                ).toFixed(2)}
                              </strong>
                            </td>
                          </tr> */}
                                {/* <tr>
                            <td
                              style={{ padding: 7 }}
                              colSpan={2}
                              align="right"
                            >
                              <strong> Gvt. Charges</strong>
                            </td>
                            <td style={{ padding: 7 }} align="right">
                              <strong>
                                {parseFloat(
                                  invoice[0]?.Gvt_Amount || 0
                                ).toFixed(2)}
                              </strong>
                            </td>
                          </tr> */}
                                <tr>
                                  <td
                                    style={{ padding: 7 }}
                                    align="right"
                                    colSpan={2}
                                  >
                                    <strong>Net Total</strong>
                                  </td>
                                  <td style={{ padding: 7 }} align="right">
                                    <strong>
                                      {parseFloat(
                                        invoice[0]?.Total_Consultation || 0
                                      ).toFixed(2)}
                                    </strong>
                                  </td>
                                </tr>
                                <tr>
                                  <td style={{ padding: 7 }} colSpan={3}>
                                    <strong>In Words: </strong>
                                    {`${number2text(
                                      parseFloat(
                                        invoice[0]?.Total_Consultation || 0
                                      )
                                    )}`}
                                  </td>
                                </tr>
                              </tbody>
                            </table>
                          </div>
                        </div>
                      </div>

                      <div
                        className="row mb-1"
                        style={{ color: "#333", lineHeight: "1.5" }}
                      >
                        <div className="col-md-6 col-sm-6">
                          <strong>Account Details :</strong>
                          <br />
                          A/c Name : ENVIRONATURE SOLUTION
                          <br />
                          A/c No : 924020009540955
                          <br />
                          Bank Name : Axis Bank
                          <br />
                          Branch & IFS Code : Navi Mumbai & UTIB0000885
                          <br />
                        </div>
                        {/* <div className="col-md-6   col-sm-6">
              <div align="right" >
                <img
                  src="/assets/images/QQR.png"
                  alt="logo"
                  style={{
                    maxWidth: "150px",
                    height: "auto",
                    width: "100%",
                    paddingLeft: 0,
                  }}
                />
              </div>
            </div> */}

                        <div className="col-md-6  col-sm-6">
                          <p align="right" style={{ paddingRight: "30px" }}>
                            FOR ENVIRONATURE SOLUTION
                          </p>
                          <p
                            align="right"
                            style={{ paddingRight: "30px", marginBottom: 0 }}
                          >
                            <img
                              src="/assets/img/stamp.png"
                              alt="stamp"
                              style={{
                                maxWidth: "197px",
                                height: "auto",
                                width: "100%",
                                paddingLeft: 0,
                                marginTop:"-15px"
                              }}
                            />
                          </p>
                          <p align="right" style={{ paddingRight: "30px" }}>
                        <> Authorized Signatory</>
                      </p>
                        </div>
                        <div
                          className="row mb-1 mt-0"
                          style={{ color: "#333" }}
                        ></div>
                      </div>
                    </>
                  )}

                  <div className="row mt-2" style={{ lineHeight: "1.5" }}>
                    {allPaid && (
                      <p style={{ color: "#333" }}>
                        <strong>Declaration :</strong>&nbsp; We declare that
                        this invoice shows the actual price of the goods
                        described and that all particulars are true and correct.
                      </p>
                    )}
                    <div className="text-center" style={{ color: "#333" }}>
                      <h6 style={{ marginBottom: "5px" }}>
                        SUBJECT TO NAVI MUMBAI JURISDICTION
                      </h6>
                      <p>*This is a Computer Generated Invoice*</p>
                    </div>
                  </div>
                </div>
              </div>

              <div className={allPaid ? "" : "d-none"}>
                <hr />
                <div  id="printDebitNote"
                  style={{
                    borderStyle: "solid",
                    borderColor: "#709a08", // Choose any color for the border
                    borderWidth: "2px", // Adjust thickness
                    borderRadius: "5px", // Add rounded corners if needed
                    padding: "10px", // Add padding to space content from border
                  }}
                >
                  <div
                    style={{
                      backgroundColor: "#709a08",
                      color: "white",
                      padding: "5px",
                      margin: "-10px",
                    }}
                  >
                    <h3
                      style={{
                        textAlign: "center",
                        marginTop: "5px",
                        marginBottom: "0px",
                      }}
                    >
                      Debit Note
                    </h3>
                  </div>

                  <div className="div mt-4">
                    <div className="row mb-1">
                      <div className="col-md-4 col-sm-4">
                        <img
                          src="/assets/img/logo1.jpeg"
                          alt="logo"
                          style={{
                            maxWidth: "215px",
                            height: "auto",
                            width: "100%",
                            paddingLeft: 0,
                          }}
                        />
                      </div>
                      <div
                        className="col-md-8 col-sm-8 text-center"
                        style={{ lineHeight: "1.5" }}
                      >
                        <h4 className="mb-1">ENVIRONATURE SOLUTION</h4>
                        <p style={{ marginBottom: 0, color: "#333" }}>
                          Office No 128, Devi Annapurna Society, Plot No.08,
                          Sector 18, Mafco Market Road, Near Sanpada Railway
                          Station, Vashi, Mumbai 400705.
                        </p>
                        <div className="text-center" style={{ color: "#333" }}>
                          <strong>State Name :</strong> Maharashtra &nbsp;&nbsp;
                          <strong>Code :</strong> 27
                        </div>{" "}
                        <div className="text-center" style={{ color: "#333" }}>
                          <strong>GSTIN/UIN :</strong> 27AQUPS8980K2ZH
                          <br />
                          <strong>Phone :</strong> 02235220348 / 9326897902
                        </div>
                        <div className="text-center" style={{ color: "#333" }}>
                          <strong>Email :</strong>{" "}
                          <a href="mailto:info@environaturesolution.com">
                            info@environaturesolution.com
                          </a>
                        </div>
                      </div>
                    </div>
                    <hr
                      className="mb-2"
                      style={{ borderWidth: "1px", margin: 0 }}
                    />
                    <div
                      className="row justify-content-between"
                      style={{ color: "#333", lineHeight: "1.5" }}
                    >
                      <div className="col-md-7 col-sm-7">
                        <address>
                          <strong>Bill To,</strong>
                          <br />
                          {/* <strong>{invoice[0]?.Customer_Name}</strong> 
                        <br /> */}
                          {invoice[0]?.Role === "Agent" ? invoice[0]?.Customer_Company : invoice[0]?.Company_Name}
                          <br />
                          {invoice[0]?.GST_No && (
                            <div>
                              <strong>GSTIN/UIN : </strong>
                              {invoice[0]?.GST_No ? invoice[0]?.GST_No : ""}
                              <br />
                            </div>
                          )}

                          {invoice[0]?.Address && (
                            <div>
                              {invoice[0]?.Address}
                              <br />
                            </div>
                          )}
                          {/* {invoice[0]?.Mobile} */}
                        </address>
                      </div>
                      <div className="col-md-4 col-sm-4">
                        <table className="table-responsive">
                          <tbody>
                            {allPaid && (
                              <tr>
                                <td
                                  className="pull-right"
                                  style={{
                                    paddingRight: "8px",
                                    textAlign: "right",
                                  }}
                                >
                                  <strong>Debit Note No. :</strong>
                                </td>
                                <td>{invoiceNumber ? invoiceNumber : ""}</td>
                              </tr>
                            )}
                            <tr>
                              <td
                                className="pull-right"
                                style={{
                                  paddingRight: "8px",
                                  textAlign: "right",
                                }}
                              >
                                <strong>Enquiry ID :</strong>
                              </td>
                              <td>{invoice[0]?.Enquiry_Id}</td>
                            </tr>

                            <tr>
                              <td
                                className="pull-right"
                                style={{
                                  paddingRight: "8px",
                                  textAlign: "right",
                                }}
                              >
                                <strong>State Name :</strong>
                              </td>
                              <td>{invoice[0]?.State}</td>
                            </tr>
                            <tr>
                              <td
                                className="pull-right"
                                style={{
                                  paddingRight: "8px",
                                  textAlign: "right",
                                }}
                              >
                                <strong>Date :</strong>
                              </td>
                              <td>
                                {formatDate(
                                  invoiceDate ? invoiceDate : new Date()
                                )}
                              </td>
                            </tr>
                          </tbody>
                        </table>
                      </div>
                    </div>

                    <div className="row mb-2">
                      <div className="span8 well invoice-body">
                        <div className="col-md-12 col-sm-12 table-responsive">
                          <table className="table productTable display table-bordered">
                            <thead>
                              <tr>
                                {/* Gvt_AmountConsultation_ChargesTotal_ConsultationNet_AmountGst_PercentGst_Amount */}
                                <th
                                  className="text-center"
                                  style={{ minWidth: "400px" }}
                                >
                                  Certificate
                                </th>
                                <th className="text-center">HSN Code</th>
                                <th className="text-center">Gvt. Charges</th>
                              </tr>
                            </thead>
                            <tbody>
                              {invoice.map((pro, index) => (
                                <>
                                  <tr key={index}>
                                    <td style={{ padding: 5 }}>
                                      {pro?.Certificate_Name}
                                    </td>
                                    <td
                                      className="text-center"
                                      style={{ padding: 5 }}
                                    >
                                      {pro?.HSN}
                                    </td>
                                    <td
                                      className="text-center"
                                      style={{ padding: 5 }}
                                    >
                                      {parseFloat(
                                        invoice[0]?.Gvt_Amount || 0
                                      ).toFixed(2)}
                                    </td>
                                  </tr>
                                  <tr>
                                    <td colSpan={3} style={{ padding: 3 }}>
                                      {pro?.Description}
                                    </td>
                                  </tr>
                                </>
                              ))}
                              <tr>
                                <td colSpan="2"></td>
                              </tr>

                              {/* <tr>
                              <td
                                style={{ padding: 7 }}
                                colSpan={2}
                                align="right"
                              >
                                <strong>
                                  Total Consultation Charges Incl. Tax
                                </strong>
                              </td>
                              <td style={{ padding: 7 }} align="right">
                                <strong>
                                  {parseFloat(
                                    invoice[0]?.Total_Consultation || 0
                                  ).toFixed(2)}
                                </strong>
                              </td>
                            </tr>
                            <tr>
                              <td
                                style={{ padding: 7 }}
                                colSpan={2}
                                align="right"
                              >
                                <strong> Gvt. Charges</strong>
                              </td>
                              <td style={{ padding: 7 }} align="right">
                                <strong>
                                  {parseFloat(
                                    invoice[0]?.Gvt_Amount || 0
                                  ).toFixed(2)}
                                </strong>
                              </td>
                            </tr> */}
                              <tr>
                                <td
                                  style={{ padding: 7 }}
                                  align="right"
                                  colSpan={2}
                                >
                                  <strong>Net Total</strong>
                                </td>
                                <td style={{ padding: 7 }} align="right">
                                  <strong>
                                    {parseFloat(
                                      invoice[0]?.Gvt_Amount || 0
                                    ).toFixed(2)}
                                  </strong>
                                </td>
                              </tr>
                              <tr>
                                <td style={{ padding: 7 }} colSpan={3}>
                                  <strong>In Words: </strong>
                                  {`${number2text(
                                    parseFloat(invoice[0]?.Gvt_Amount || 0)
                                  )}`}
                                </td>
                              </tr>
                            </tbody>
                          </table>
                        </div>
                      </div>
                    </div>

                    <div
                      className="row mb-1"
                      style={{ color: "#333", lineHeight: "1.5" }}
                    >
                      <div className="col-md-6  col-sm-6">
                        <strong>Account Details :</strong>
                        <br />
                        A/c Name : ENVIRONATURE SOLUTION
                        <br />
                        A/c No : 924020009540955
                        <br />
                        Bank Name : Axis Bank
                        <br />
                        Branch & IFS Code : Navi Mumbai & UTIB0000885
                        <br />
                      </div>

                      <div className="col-md-6  col-sm-6">
                      <p align="right" style={{ paddingRight: "30px" }}>
                        <> FOR ENVIRONATURE SOLUTION</>
                      </p>
                        <p
                          align="right"
                          style={{ paddingRight: "30px", marginBottom: 0 }}
                        >
                          <img
                            src="/assets/img/stamp.png"
                            alt="stamp"
                            style={{
                              maxWidth: "197px",
                              marginTop:"-15px",
                              height: "auto",
                              width: "100%",
                              paddingLeft: 0,
                            }}
                          />
                        </p>
                        <p align="right" style={{ paddingRight: "30px" }}>
                        <> Authorized Signatory</>
                      </p>
                      </div>
                    </div>

                    <div className="row mb-1 mt-0" style={{ color: "#333" }}>
                     
                    </div>
                    {!allPaid ? (
                      <>
                        {/* <div className="col-md-12">
                      <div>
                        <strong style={{ color: "#333" }}>
                          Installment Details :{" "}
                        </strong>
                        <table
                          className="tables productTable display dataTable table-bordered "
                          cellspacing="0"
                          style={{
                            borderCollapse: "collapse",
                            border: "1px solid rgba(33, 33, 33, 0.1)",
                            color: "#333",
                          }}
                        >
                          <thead>
                            <tr align="center">
                              <th className="text-center" scope="col">
                                Amount
                              </th>
                              <th className="text-center" scope="col">
                                Due Date
                              </th>
                            

                              <th className="text-center" scope="col">
                                Payment Status
                              </th>
                        
                              <th className="text-center" scope="col">
                                Remarks
                              </th>
                            </tr>
                          </thead>
                          <tbody>
                            {logs ? (
                              logs.map((row, index) => (
                                <tr align="center" key={index}>
                                  <td>
                                    <span>
                                      {row.Amount ? row.Amount : "--"}
                                    </span>
                                  </td>
                                  <td>
                                    <span className="f-12">
                                      {row.Due_Date
                                        ? formatDate(row.Due_Date)
                                        : "--"}
                                    </span>
                                  </td>
                                

                                  <td>
                                    <span>
                                      {row.Payment_Status
                                        ? row.Payment_Status
                                        : "--"}
                                    </span>
                                  </td>
                                
                                  <td>
                                    <span>
                                      {row.Remarks ? row.Remarks : "--"}
                                    </span>
                                  </td>
                                </tr>
                              ))
                            ) : (
                              <tr>
                                <td colSpan="4">No data available</td>
                              </tr>
                            )}
                          </tbody>
                        </table>
                      </div>
                    </div> */}
                      </>
                    ) : (
                      <>
                        {/* <div className="col-md-12">
                        <div>
                          <strong style={{ color: "#333" }}>
                            Payment Details :{" "}
                          </strong>
                          <table
                            className="tables productTable display dataTable table-bordered "
                            cellspacing="0"
                            style={{
                              borderCollapse: "collapse",
                              border: "1px solid rgba(33, 33, 33, 0.1)",
                              color: "#333",
                            }}
                          >
                            <thead>
                              <tr align="center">
                                <th className="text-center" scope="col">
                                  Amount
                                </th>
                                <th className="text-center" scope="col">
                                  Payment Date
                                </th>
                                <th className="text-center" scope="col">
                                  Payment Status
                                </th>
                                <th className="text-center" scope="col">
                                  Payment Mode
                                </th>

                                <th className="text-center" scope="col">
                                  Cheque Details
                                </th>

                                <th className="text-center" scope="col">
                                  Transaction Id
                                </th>
                              </tr>
                            </thead>
                            <tbody>
                              {logs ? (
                                logs.map((row, index) => (
                                  <tr align="center" key={index}>
                                    <td>
                                      <span>
                                        {row.Amount ? row.Amount : "--"}
                                      </span>
                                    </td>
                                    <td>
                                      <span className="f-12">
                                        {row.Due_Date
                                          ? formatDate(row.Payment_Date)
                                          : "--"}
                                      </span>
                                    </td>
                                    <td>
                                      <span>
                                        {row.Payment_Status
                                          ? row.Payment_Status
                                          : "--"}
                                      </span>
                                    </td>
                                    <td>
                                      <span>
                                        {row.Payment_Mode
                                          ? row.Payment_Mode
                                          : "--"}
                                      </span>
                                    </td>
                                    <td>
                                      {row.Bank_Name ? (
                                        <span>
                                          Bank Name: {row.Bank_Name}
                                          <br />
                                          Cheque No: {row.Cheque_No}
                                          <br />
                                          Cheque Date:{" "}
                                          {formatDate(row.Cheque_Date)}
                                        </span>
                                      ) : (
                                        "--"
                                      )}
                                    </td>

                              
                                    <td>
                                      <span>
                                        {row.OrgId
                                          ? row.Transaction_Id
                                          : row.Rpayment_Id}
                                      </span>
                                    </td>
                                  </tr>
                                ))
                              ) : (
                                <tr>
                                  <td colSpan="5">No data available</td>
                                </tr>
                              )}
                            </tbody>
                          </table>
                        </div>
                      </div> */}
                      </>
                    )}

                    <div className="row" style={{ lineHeight: "1.5" }}>
                      {allPaid && (
                        <p style={{ color: "#333" }}>
                          <strong>Declaration :</strong>&nbsp; We declare that
                          this invoice shows the actual price of the goods
                          described and that all particulars are true and
                          correct.
                        </p>
                      )}
                      <div className="text-center" style={{ color: "#333" }}>
                        <h6 style={{ marginBottom: "5px" }}>
                          SUBJECT TO NAVI MUMBAI JURISDICTION
                        </h6>
                        <p>*This is a Computer Generated Invoice*</p>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          {isModal1 && (
            <PaymentModal
              open={isModal1}
              onClose={closed}
              updateData={Enq_Id}
            />
          )}
          {isModal2 && (
            <OfflinePaymentModal
              open={isModal2}
              onClose={closed2}
              updateData={Enq_Id}
            />
          )}

          {loader}
        </div>
      </div>
    </div>
  );
};

export default Invoice;
