import React, { useState, useEffect } from "react";
import { Modal } from "react-bootstrap";
import axios from "../../components/axios";
import useFullPageLoader from "../../components/useFullPageLoader";
import { Link } from "react-router-dom";
import { useUser } from "../../components/UserContext";
import { apiUrl } from "../../components/axios";
import Swal from "sweetalert2";
import { formatDate } from "../../components/dateFormate";
import PictureAsPdfIcon from "@mui/icons-material/PictureAsPdf";
import jsPDF from "jspdf";
import Tooltip from "@mui/material/Tooltip";
import "jspdf-autotable";
import * as XLSX from "xlsx";
import { mdiFileExcel } from "@mdi/js";
import Icon from "@mdi/react";



const SummaryModal = ({ open, onClose, updateData }) => {
    const { userData } = useUser();
    const [loader, showLoader, hideLoader] = useFullPageLoader();
    const [show, setShow] = useState(open);
    const [staffData, setStaffData] = useState([]);
    const [followup, setFollowupData] = useState([])
    const [formData, setFormData] = useState({
        Remarks:""
    })

    const handleChange = (e) => {
        const { name, value } = e.target;
        setFormData((prev) => ({
          ...prev,
          [name]: value
        }));
      };

      console.log(updateData);
      

    // Function to load staff data
    const loadStaffData = async () => {
        showLoader();
        try {
            const result = await axios.get(`/getSummaryCustomerEnq/${updateData?.Enquiry_Sr_No}`);
            setStaffData(result.data || []);
        } catch (error) {
            console.error("Error fetching staff data: ", error);
            alert("Failed to load staff data. Please try again later.");
        } finally {
            hideLoader();
        }
    };

  const loadFollowupData = async () => {
    showLoader();
    try {
      const result = await axios.get(`/getFollowupByEnquiryId/${updateData?.Enquiry_Sr_No}`);
      console.log(result);
      
      
      // Check if the result has the expected data format
      if (Array.isArray(result.data?.followupData)) {
        // Sort the follow-up data by Added_On in descending order (latest first)
        const sortedData = result.data.followupData.sort((a, b) => new Date(b.Added_On) - new Date(a.Added_On));
        setFollowupData(sortedData);
      } else {
        console.error('Invalid data format:', result.data);
      }
    } catch (error) {
      console.error("Error fetching followup data: ", error);
    } finally {
      hideLoader();
    }
  };

    useEffect(() => {
        if (updateData?.Enquiry_Sr_No) {
            loadStaffData();
            loadFollowupData();
        }
    }, [updateData]);

    

    const handleSubmit = async (e) => {
        e.preventDefault();
      
        // Create a new FormData instance (ensure it's not named `formData` to avoid conflict)
        const formDataToSend = new FormData();
      
        // Append form data
        formDataToSend.append('Remarks', formData.Remarks); // Use state variable here
        formDataToSend.append('Enquiry_Id', updateData?.Enquiry_Sr_No);
        formDataToSend.append('Added_By',`${userData?.First_Name && userData?.Last_Name ? `${userData.First_Name} ${userData.Last_Name}` : userData?.VendorName || "Unknown"}`);
        formDataToSend.append('Added_By_Id', userData?.UserId || userData.Vendor_Id);
        formDataToSend.append('Org_Id', userData?.Org_Id);
        formDataToSend.append('Role', userData?.Vendor_Id ? "Vendor" : userData?.Role);      
        // Log the appended form data
        for (let pair of formDataToSend.entries()) {
          console.log(pair[0] + ": " + pair[1]);
        }
      
        // Show loader
        showLoader();
      
        try {
          // Use axios to send a POST request with the formData
          const response = await axios.post('/addRemarks', formDataToSend, {
            headers: {
              'Content-Type': 'application/json', // Ensure the server knows it's receiving form data
            },
          });
      
          // Hide loader
          hideLoader();
      
          // Handle the response
          console.log('Form submitted successfully:', response.data);
      
          // Show success message using SweetAlert
          Swal.fire({
            title: 'Success!',
            text: 'Remark added successfully!',
            icon: 'success',
            confirmButtonText: 'OK',
          });
      
          // Optionally reset form data after successful submission
          setFormData({
            Remarks: "", // Reset the Remarks field
          });
          await loadFollowupData();


        } catch (error) {
          // Hide loader in case of error
          hideLoader();
      
          console.error('Error during form submission:', error);
      
          // Show error message using SweetAlert
          Swal.fire({
            title: 'Error!',
            text: 'Something went wrong. Please try again later.',
            icon: 'error',
            confirmButtonText: 'OK',
          });
        }
      };
      
      

    const handleClose = () => {
        setShow(false);
        onClose();
    };

    const DownloadExcel = () => {
        // Define the columns (headers) for the Excel sheet
        const columns = [
          "Sr No",
          "Enquiry Id",
          "Customer Id",
          "Certificate Name",
          "Remarks",
          "Added On",
          "State",
          "GST No.",
          "Government",
          "Consultation",
          "Total Consultation",
          "Net Amount",
          "GST Amount",
          "Application No",
          "Application Date",
          "Link",
          "Mobile Number",
          "Certificate Number",
          "Certificate Date",
          "Renewal Date",
          "Financial Year",
        ];
      
        // Initialize rows with the headers
        const rows = [columns];
      
        // Map through the data to prepare each row for the Excel sheet
        staffData.forEach((row) => {
          rows.push([
            row.Sr_No,
            row.Enquiry_Id,
            row.CustomerId,
            row.Certificate_Name,
            row.Remarks ? row.Remarks : "--",
            formatDate(row.Added_On), // Format date fields
            row.State,
            row.GST_No,
            row.Government,
            row.Consultation,
            row.Total_Consultation,
            row.Net_Amount,
            row.Gst_Amount,
            row.Application_No ? row.Application_No : "--",
            formatDate(row.Application_Date), // Format date fields
            row.Link ? row.Link : "--",
            row.Mobile,
            row.Certificate_Number ? row.Certificate_Number : "--",
            formatDate(row.Certificate_Date), // Format date fields
            formatDate(row.Renewal_Date), // Format date fields
            row.Financial_Year ? row.Financial_Year : "--",
          ]);
        });
      
        // Create the worksheet and the workbook
        const workSheet = XLSX.utils.aoa_to_sheet(rows);
        const workBook = XLSX.utils.book_new();
        XLSX.utils.book_append_sheet(workBook, workSheet, "Summary");
      
        // Write the workbook to an Excel file
        XLSX.writeFile(workBook, "Enquiry_Summary.xlsx");
      };
      
    

    return (
        <Modal
            show={show}
            onHide={handleClose}
            size="xl"
            centered
            style={{ boxShadow: "none !important" }}
        >
            <Modal.Body>
                <div className="row align-items-center">
                    <h5 className="col-md-6">Enquiry Summary - ({updateData.Certificate_Name || ""})</h5>
                </div>

                {loader}

                <div className="row" style={{ justifyContent:"center", borderRadius: '8px' }}>
                    {/* Loop through staffData and display each detail */}
                    {staffData.length > 0 ? (
                        staffData.map((data) => (
                            <React.Fragment key={data.Sr_No}>
                                {/* First row with 3 columns */}
                                <div className="row" style={{ marginBottom: '8px' }}>
                                    
                                    <div style={{ flex: 1 }}>
                                        <div className="text-dark">
                                            <strong>Customer Id:</strong> {data.CustomerId}
                                        </div>
                                    </div>
                                    <div style={{ flex: 1 }}>
                                        <div className="text-dark">
                                            <strong>Enquiry Id:</strong> {data.Enquiry_Id}
                                        </div>
                                    </div>
                                    <div style={{ flex: 1 }}>
                                        <div className="text-dark">
                                            <strong>Certificate:</strong> {data.Certificate_Name}
                                        </div>
                                    </div>
                                    
                                    
                                </div>

                                {/* Second row with 3 columns */}
                                <div className="row" style={{ marginBottom: '10px' }}>
    {userData?.Vendor_Id ? null : (
        <div style={{ flex: 1 }}>
            <div className="text-dark">
                <strong>Company Name:</strong> {userData?.Role === "Agent" ? updateData?.Customer_Company : data.Company_Name}
            </div>
        </div>
    )}
    
    {userData?.Vendor_Id ? null : (
        userData?.Role !== "Agent" ? (
            <>
                <div style={{ flex: 1 }}>
                    <div className="text-dark">
                        <strong>First Name:</strong> {data.First_Name}
                    </div>
                </div>
                <div style={{ flex: 1 }}>
                    <div className="text-dark">
                        <strong>Last Name:</strong> {data.Last_Name}
                    </div>
                </div>
            </>
        ) : (
            <>
                <div style={{ flex: 1 }}>
                    <div className="text-dark">
                        <strong>State:</strong> {updateData?.State}
                    </div>
                </div>
                <div style={{ flex: 1 }}>
                    <div className="text-dark">
                        <strong>GST No:</strong> {updateData?.GST_No}
                    </div>
                </div>
            </>
        )
    )}
</div>



                                {/* Third row with 3 columns */}


                                <div className="row" style={{ marginBottom: '10px' }}>

                                    {userData?.Role ==! "Agent" && (
                                        <>
                                          <div style={{ flex: 1 }}>
                                        <div className="text-dark">
                                            <strong>Mobile:</strong> {data.Mobile}
                                        </div>
                                    </div>
                                    <div style={{ flex: 1 }}>
                                        <div className="text-dark">
                                            <strong>Email:</strong> {data.Email}
                                        </div>
                                    </div>
                                        </>
                                    )}
                                    
                                    <div style={{ flex: 1 }}>
                                        <div className="text-dark">
                                        </div>
                                    </div>
                                </div>

                                {/* Fourth row with 3 columns */}
                                <div className="row" style={{ marginBottom: '10px' }}>
                                <div style={{ flex: 1 }}>
                                        <div className="text-dark">
                                            <strong>Gvt Amount:</strong> {data.Gvt_Amount}
                                        </div>
                                    </div>
                                    
                                    <div style={{ flex: 1 }}>
                                        <div className="text-dark">
                                            <strong>Consultation:</strong> {data.Consultation}
                                        </div>
                                    </div>
                                    <div style={{ flex: 1 }}>
                                        <div className="text-dark">
                                            <strong>Net Amount:</strong> {data.Net_Amount}
                                        </div>
                                    </div>
                                </div>

                                <div className="row" style={{ marginBottom: '10px' }}>
                                    <div style={{ flex: 1 }}>
                                        <div className="text-dark">
                                        <strong>Application No:</strong> {data.Application_No}
                                        </div>
                                    </div>
                                    <div style={{ flex: 1 }}>
                                        <div className="text-dark">
                                            <strong>Application Date:</strong>  {data.Application_Date ? formatDate(data.Application_Date) : "Not Uploaded Yet"}
                                        </div>
                                    </div>
                                    <div style={{ flex: 1 }}>
                                        <div className="text-dark">
                                            <strong>Link:</strong> {data.Link}
                                        </div>
                                    </div>
                                </div>

                                <div className="row" style={{ marginBottom: '10px' }}>
                                    <div style={{ flex: 1 }}>
                                        <div className="text-dark">
                                        <strong>Attachment 1:</strong>  {data.Attachment1 ? (
                                                <>
                                                    {/* View Certificate Link */}
                                                    <a
                                                        style={{ color: "blue", cursor: "pointer", marginRight: '8px' }}
                                                        href={`${apiUrl}/viewAcknowledgement/${data.Attachment1}`}
                                                        target="_blank"
                                                        rel="noopener noreferrer"
                                                        className="cursor-pointer"
                                                    >
                                                        <i className="ti ti-eye" style={{ marginRight: '5px' }}></i>
                                                    </a>

                                                    {/* Download Certificate Link */}
                                                    <a
                                                        style={{ color: "blue", cursor: "pointer" }}
                                                        href={`${apiUrl}/downloadAcknowledgement/${data.Attachment1}`}
                                                        download={true}
                                                        className="cursor-pointer"
                                                    >
                                                        <i className="ti ti-download" style={{ marginRight: '5px' }}></i>
                                                    </a>
                                                </>
                                            ) : (
                                                <>
                                                    {/* Default Icons if no certificate is available */}
                                                    <i className="ti ti-eye-off" style={{ marginRight: '5px' }}></i>
                                                    <i className="ti ti-download-off" style={{ marginLeft: '5px' }}></i>
                                                </>
                                            )}
                                        </div>
                                    </div>
                                    <div style={{ flex: 1 }}>
                                        <div className="text-dark">
                                            <strong>Attachment 2:</strong>  {data.Attachment2 ? (
                                                <>
                                                    {/* View Certificate Link */}
                                                    <a
                                                        style={{ color: "blue", cursor: "pointer", marginRight: '8px' }}
                                                        href={`${apiUrl}/viewAcknowledgement/${data.Attachment2}`}
                                                        target="_blank"
                                                        rel="noopener noreferrer"
                                                        className="cursor-pointer"
                                                    >
                                                        <i className="ti ti-eye" style={{ marginRight: '5px' }}></i>
                                                    </a>

                                                    {/* Download Certificate Link */}
                                                    <a
                                                        style={{ color: "blue", cursor: "pointer" }}
                                                        href={`${apiUrl}/downloadAcknowledgement/${data.Attachment2}`}
                                                        download={true}
                                                        className="cursor-pointer"
                                                    >
                                                        <i className="ti ti-download" style={{ marginRight: '5px' }}></i>
                                                    </a>
                                                </>
                                            ) : (
                                                <>
                                                    {/* Default Icons if no certificate is available */}
                                                    <i className="ti ti-eye-off" style={{ marginRight: '5px' }}></i> 
                                                    <i className="ti ti-download-off" style={{ marginLeft: '5px' }}></i> 
                                                </>
                                            )}
                                        </div>
                                    </div>
                                    <div style={{ flex: 1 }}>
                                        <div className="text-dark">
                                            <strong>Attachment 3:</strong>  {data.Attachment3 ? (
                                                <>
                                                    {/* View Certificate Link */}
                                                    <a
                                                        style={{ color: "blue", cursor: "pointer", marginRight: '8px' }}
                                                        href={`${apiUrl}/viewAcknowledgement/${data.Attachment3}`}
                                                        target="_blank"
                                                        rel="noopener noreferrer"
                                                        className="cursor-pointer"
                                                    >
                                                        <i className="ti ti-eye" style={{ marginRight: '5px' }}></i>
                                                    </a>

                                                    {/* Download Certificate Link */}
                                                    <a
                                                        style={{ color: "blue", cursor: "pointer" }}
                                                        href={`${apiUrl}/downloadAcknowledgement/${data.Attachment3}`}
                                                        download={true}
                                                        className="cursor-pointer"
                                                    >
                                                        <i className="ti ti-download" style={{ marginRight: '5px' }}></i>
                                                    </a>
                                                </>
                                            ) : (
                                                <>
                                                    {/* Default Icons if no certificate is available */}
                                                    <i className="ti ti-eye-off" style={{ marginRight: '5px' }}></i>
                                                    <i className="ti ti-download-off" style={{ marginLeft: '5px' }}></i>
                                                </>
                                            )}
                                        </div>
                                    </div>
                                </div>
                                <div className="row" style={{ marginBottom: '10px' }}>
                                    <div style={{ flex: 1 }}>
                                        <div className="text-dark">
                                        <strong>Certificate No:</strong> {data.Certificate_Number}
                                        </div>
                                    </div>
                                    <div style={{ flex: 1 }}>
                                        <div className="text-dark">
                                            <strong>Certificate Date:</strong>  {data.Certificate_Date ? formatDate(data.Certificate_Date) : "Not Uploaded Yet"}
                                        </div>
                                    </div>
                                    <div style={{ flex: 1 }}>
                                        <div className="text-dark">
                                            <strong>Renewal Date:</strong>  {data.Renewal_Date ? formatDate(data.Renewal_Date) : "Not Uploaded Yet"}
                                        </div>
                                    </div>
                                </div>

                                {/* Fifth row with 3 columns */}
                                <div className="row" style={{ marginBottom: '10px' }}>
                                    <div style={{ flex: 1 }}>
                                        <div className="text-dark">
                                        <strong>Added On:</strong> {formatDate(data.Added_On)}
                                        </div>
                                    </div>
                                    {userData?.Role !== "Agent" ? (
    <>
        <div style={{ flex: 1 }}>
            <div className="text-dark">
                <strong>Vendor Name:</strong> {data.Vendor_Name || "Not Available"}
            </div>
        </div>
        <div style={{ flex: 1 }}>
            <div className="text-dark">
            </div>
        </div>
    </>
) : (
    <>
        <div style={{ flex: 1 }}>
            <div className="text-dark">
                <strong>Financial Year:</strong> {data.Financial_Year}
            </div>
        </div>
        <div style={{ flex: 1 }}>
            <div className="text-dark">
                <strong>Certificate:</strong> 
                {data.Upload_Certificate ? (
                    <>
                        {/* View Certificate Link */}
                        <a
                            style={{ color: "blue", cursor: "pointer", marginRight: '8px' }}
                            href={`${apiUrl}/viewCertificate/${data.Upload_Certificate}`}
                            target="_blank"
                            rel="noopener noreferrer"
                            className="cursor-pointer"
                        >
                            <i className="ti ti-eye" style={{ marginRight: '5px' }}></i>
                        </a>

                        {/* Download Certificate Link */}
                        <a
                            style={{ color: "blue", cursor: "pointer" }}
                            href={`${apiUrl}/downloadCetificate/${data.Upload_Certificate}`}
                            download={true}
                            className="cursor-pointer"
                        >
                            <i className="ti ti-download" style={{ marginRight: '5px' }}></i>
                        </a>
                    </>
                ) : (
                    <>
                        {/* Default Icons if no certificate is available */}
                        <i className="ti ti-eye-off" style={{ marginRight: '5px' }}></i>
                        <i className="ti ti-download-off" style={{ marginLeft: '5px' }}></i>
                    </>
                )}
            </div>
        </div>
    </>
)}
              
                                </div>
                                {(userData?.Role === "Admin" || userData?.Role === "Staff") && (
  <div className="row" style={{ marginBottom: '10px' }}>
    <div style={{ flex: 1 }}>
      <div className="text-dark">
        <strong>Financial Year:</strong> {data.Financial_Year}
      </div>
    </div>
    <div style={{ flex: 1 }}>
      <div className="text-dark">
        <strong>Certificate:</strong> 
        {data.Upload_Certificate ? (
          <>
            {/* View Certificate Link */}
            <a
              style={{ color: "blue", cursor: "pointer", marginRight: '8px' }}
              href={`${apiUrl}/viewCertificate/${data.Upload_Certificate}`}
              target="_blank"
              rel="noopener noreferrer"
              className="cursor-pointer"
            >
              <i className="ti ti-eye" style={{ marginRight: '5px' }}></i>
            </a>

            {/* Download Certificate Link */}
            <a
              style={{ color: "blue", cursor: "pointer" }}
              href={`${apiUrl}/downloadCetificate/${data.Upload_Certificate}`}
              download={true}
              className="cursor-pointer"
            >
              <i className="ti ti-download" style={{ marginRight: '5px' }}></i>
            </a>
          </>
        ) : (
          <>
            {/* Default Icons if no certificate is available */}
            <i className="ti ti-eye-off" style={{ marginRight: '5px' }}></i>
            <i className="ti ti-download-off" style={{ marginLeft: '5px' }}></i>
          </>
        )}
      </div>
    </div>
    <div style={{ flex: 1 }}>
      <div className="text-dark">
        {/* Additional Content Here */}
      </div>
    </div>
  </div>
)}

{userData?.Role==="Agent" && (
    <div className="row">
<div style={{ flex: 1 }}>
      <div className="text-dark">
      <strong>Export:</strong> 
        <Tooltip title="Export to Excel">
          <i
            style={{
              padding: "5px",
              cursor: "pointer",
            }}
            onClick={() => {
                DownloadExcel();
            }}
          >
            <Icon path={mdiFileExcel} size={1} />
          </i>
        </Tooltip>
      </div>
    </div>
    </div>
)}

                                

                                
                            </React.Fragment>
                        ))
                    ) : (
                        <div className="col-12">
                            <p>No data available</p>
                        </div>
                    )}

                    {userData?.Role !=="Agent" &&(
                        <>
                        <div className="container mt-4">
      {followup.length > 0 ? (
        <table className="table table-bordered">
          <thead>
            <tr>
              <th scope="col">Enquiry Id</th>
              <th scope="col">Remarks</th>
              <th scope="col">Role</th>
              <th scope="col">Added By</th>
              <th scope="col">Added On</th>
            </tr>
          </thead>
          <tbody>
            {followup.map((followup, index) => (
              <tr key={index}>
                <td>{followup.Enquiry_Id}</td>
                <td>{followup.Remarks}</td>
                <td>{followup.Role}</td>
                <td>{followup.Added_By}</td>
                <td>{formatDate(followup.Added_On)}</td>
              </tr>
            ))}
          </tbody>
        </table>
      ) : (
        <p>No follow-up data available.</p>
      )}
    </div>
            <form onSubmit={handleSubmit}>
  <div className="row">
    <div className="col-md-10">
      <div className="mb-3">
        <label htmlFor="Remarks" className="form-label">
          Remarks
        </label>
        <textarea
          id="Remarks"
          name="Remarks"
          className="form-control"
          placeholder="Enter Remarks"
          onChange={handleChange}
          value={formData.Remarks}
        />
      </div>
    </div>
    <div className="col-md-2 d-flex align-items-end mb-3">
      <button type="submit" className="btn btn-primary ">
        Submit
      </button>
    </div>
  </div>
</form>

                        </>
                    )}



                </div>


            </Modal.Body>
        </Modal>
    );
};

export default SummaryModal;
