import * as React from "react";
import * as XLSX from "xlsx";
import MoreVertIcon from "@mui/icons-material/MoreVert";
import { Link, useParams } from "react-router-dom";
import { useState, useEffect } from "react";
import axios from "../../components/axios";
import Select from "react-select";
import DeleteIcon from "@mui/icons-material/Delete";
import Stack from "@mui/material/Stack";
import Swal from "sweetalert2";
import TextField from "@mui/material/TextField";
import { useUser } from "../../components/UserContext";
import PictureAsPdfIcon from "@mui/icons-material/PictureAsPdf";
import jsPDF from "jspdf";
import Tooltip from "@mui/material/Tooltip";
import "jspdf-autotable";
import Icon from "@mdi/react";
import { styled } from "@mui/material/styles";
import { tooltipClasses } from "@mui/material/Tooltip";
import { mdiFileExcel } from "@mdi/js";
import useFullPageLoader from "../../components/useFullPageLoader";
import DataTable from "react-data-table-component";
import UploadCertificate from "../Cetificate/UploadCertificate";
import ApproveRejectDoc from "../Customer/ApproveRejectDoc";
import UploadDocument from "../Customer/UploadDocument";
import ApproveRejectPayment from "../Customer/ApproveRejectPayment";
import UpdateDocumentByFac from "../Customer/UpdateDocumentByFac";
import SetQuotation from "../Customer/SetQuotation";
import QuotationPreview from "../Customer/QuotationPreview";
import UploadAcknowledgement from "../Customer/UploadAcknowledgement";
import { formatDate } from "../../components/dateFormate";
import dayjs from "dayjs";
import AssignEnquiry from "../Customer/AssignEnquiry";
import CustomPasswordModal from "../Customer/CustomModalPassword";
import SummaryModal from "../Customer/SummaryModal";
import ApplyAgent from "./ApplyAgent";
import AgentCustomer from "../Customer/AgentCustomer";

const AgentEnquiry = () => {
  const { userData } = useUser();
  const { Customer_Id, Company_Name, condition } = useParams();
  const [loader, showLoader, hideLoader] = useFullPageLoader();
  const [data, setStaffData] = useState([]);
  const [dataa, setStaffDataa] = useState([]);
  const [isModal, setIsModal] = useState(false);
  const [isModal1, setIsModal1] = useState(false);
  const [isModal2, setIsModal2] = useState(false);
  const [isModal3, setIsModal3] = useState(false);
  const [isModal4, setIsModal4] = useState(false);
  const [isModal5, setIsModal5] = useState(false);
  const [isModal6, setIsModal6] = useState(false);
  const [isModal7, setIsModal7] = useState(false);
  const [isModal8, setIsModal8] = useState(false);
  const [isModal9, setIsModal9] = useState(false);
  const [isModal10, setIsModal10] = useState(false);
  const [isModal11, setIsModal11] = useState(false);
  const [showModal, setShowModal] = useState(false);
  const [Sr_No, setSrNo] = useState(null);
  const [Name, setName] = useState('');
  const [UserId, setUserId] = useState('');
  const [Modaldata, setModalData] = useState();
  const [docStatus, setDocStatus] = useState({});
  const [pendingStepFilter, setPendingStepFilter] = useState("All");
  const [certificateData, setCertificateData] = useState([]);
  const [customerData, setCustomerData] = useState([]);
  const [filter, setFilter] = useState({
    Customer_Id: Customer_Id ? parseInt(Customer_Id) : "",
    Company_Name: Company_Name ? Company_Name : "",
    Certificate_Id: "",
    Certificate_Name: "",
    Status:
      condition === "Active"
        ? "Active"
        : condition === "Pending"
        ? "Pending"
        : condition === "CertificateIssued"
        ? "Certificate Issued"
        : condition === "PendingCertificate"
        ? "Pending Certificate"
        : "",
    fromDate: dayjs().startOf("month").format("YYYY-MM-DD"),
    toDate: dayjs().endOf("month").format("YYYY-MM-DD"),
  });

  // console.log(filter);

  const closed = () => {
    setIsModal(false);
    loadStaffData();
    loadCertificateData();
  };

  const open = (e) => {
    setModalData(e);
    setIsModal(true);
  };

  const closed1 = () => {
    setIsModal1(false);
    loadStaffData();
    loadCertificateData();
    setPendingStepFilter("All");

  };

  const open1 = (e) => {
    setModalData(e);
    setIsModal1(true);
  };

  const closed2 = () => {
    setIsModal2(false);
    loadStaffData();
    loadCertificateData();
    setPendingStepFilter("All");

  };

  const open2 = (e) => {
    setModalData(e);
    setIsModal2(true);
  };

  const closed3 = () => {
    setIsModal3(false);
    loadStaffData();
    loadCertificateData();
    setPendingStepFilter("All");

  };

  const open3 = (e) => {
    setModalData(e);
    setIsModal3(true);
  };

  const closed4 = () => {
    setIsModal4(false);
    loadStaffData();
    loadCertificateData();
    setPendingStepFilter("All");

  };

  const open4 = (e) => {
    setModalData(e);
    setIsModal4(true);
  };

  const closed5 = () => {
    setIsModal5(false);
    loadStaffData();
    loadCertificateData();
    setPendingStepFilter("All");
  };

  const open5 = (e) => {
    setModalData(e);
    setIsModal5(true);
  };

  const closed6 = () => {
    setIsModal6(false);
    loadStaffData();
    loadCertificateData();
    setPendingStepFilter("All");

  };

  const open6 = (e) => {
    setModalData(e);
    setIsModal6(true);
  };

  const closed7 = () => {
    setIsModal7(false);
    loadStaffData();
    loadCertificateData();
    setPendingStepFilter("All");

  };

  const open7 = (e) => {
    setModalData(e);
    setIsModal7(true);
  };


  const open8 =(e) =>{
    setModalData(e);
    setIsModal8(true);
  }

  const closed8 =()=>{
    setIsModal8(false)
    loadStaffData();
    loadCertificateData();
    setPendingStepFilter("All");
  }

  const open9 =(e) =>{
    setModalData(e);
    setIsModal9(true);
  }

  const closed9 =()=>{
    setIsModal9(false)
    loadStaffData();
    loadCertificateData();
    setPendingStepFilter("All");
  }

  const open10 =(e) =>{
    setModalData(e);
    setIsModal10(true);
  }

  const closed10 =()=>{
    setIsModal10(false)
    loadStaffData();
    loadCertificateData();
    setPendingStepFilter("All");
  }

  const open11 =(e) =>{
    setModalData(e);
    setIsModal11(true);
  }

  const closed11 =()=>{
    setIsModal11(false)
    loadStaffData();
    loadCertificateData();
    setPendingStepFilter("All");
  }


  const showPasswordModal = (srNo, name, userId) => {
    setSrNo(srNo);
    setName(name);
    setUserId(userId);
    setShowModal(true);
  };

  const closePasswordModal = () => {
    setShowModal(false);
    loadStaffData();
    setPendingStepFilter("All");
  };

  const filterData = () => {
    loadStaffData();
  };

  useEffect(() => {
    loadStaffData();
}, [ userData?.Agent_Id]);



  

  const loadCertificateData = async () => {
    try {
      const result = await axios.get("/getCertificateType"); // Fetch data from API

      // Log the fetched data to the console
      // console.log("Fetched Certificate Data:", result.data);

      setCertificateData(result.data); // Update the certificate data state with fetched data
    } catch (error) {
      console.error("Error fetching certificate data:", error);
      alert("Failed to load certificate data. Please try again later.");
    }
  };

  // console.log(userData);
  

  const loadStaffData = async () => {
    showLoader();
    try {
        const url = `/getAllAgentEnq/${userData?.Agent_Id}?SrNo=${filter?.Customer_Id}&Certificate_Id=${filter?.Certificate_Id}&fromDate=${filter?.fromDate}&toDate=${filter?.toDate}&Status=${filter?.Status}`;

        const result = await axios.get(url);

        const enrichedStaffData = result.data.map((item) => ({
            ...item,
            pendingStep: getCurrentPendingStep(item),
        }));

        setStaffData(enrichedStaffData || []);
        setStaffDataa(enrichedStaffData || []);
    } catch (error) {
        console.error("Error fetching data:", error);
    } finally {
        hideLoader();
    }
};




  const getCurrentPendingStep = (row) => {
    if (
      (row.Gvt_Amount === "0.00" || row.Gvt_Amount === null) &&
      (row.Consultation === "0.00" || row.Consultation === null)
    ) {
      return "Set Quotation";
    } else if (
      row.Quotation === "No" &&
      row.Consultation !== "0.00" &&
      row.Net_Amount === "0.00" &&
      (row.Quotation_Preview === "No" || row.Quotation_Preview === null)
    ) {
      return "Quotation Preview"; // Quotation Preview step
    } else if (
      row.Send_Quotation !== null &&
      row.Quotation === "No" &&
      row.Quotation_Preview === "Yes"
    ) {
      return "Approve Quotation"; // Step 3: Approve Quotation
    } else if (row.Quotation === "Yes" && row.Document_Status === "Pending") {
      return "Upload Document"; // Step 4: Upload Document
    } else if (
      row.Quotation === "Yes" &&
      row.Document_Status !== "Pending" &&
      row.Net_Amount === "0.00"
    ) {
      return "Set Installment"; // Step 5: Set Installment
    } else if (row.Amount_Balance > "0.00") {
      return "Make Payment"; // Step 6: Make Payment
    } else if (row.Payment_Status === "Inprocess") {
      return "Verify Payment"; // Step 7: Verify Payment
    } else if (row.Acknowledgement === "No") {
      return "Upload Acknowledgement"; // Step 8: Upload Acknowledgement
    } else if (
      !row.Upload_Certificate ||
      row.Upload_Certificate === null ||
      row.Upload_Certificate === ""
    ) {
      return "Upload Certificate"; // Step 9: Upload Certificate
    }
  
    return "No Pending Tasks"; // If no conditions met
  };
  



  // const deleteUser = (Sr_No, Name, UserId) => {
  //   Swal.fire({
  //     title: "Are you sure?",
  //     text: `You are about to delete user: ${Name}. You won't be able to revert this!`,
  //     icon: "warning",
  //     showCancelButton: true,
  //     confirmButtonColor: "#3085d6",
  //     cancelButtonColor: "#d33",
  //     confirmButtonText: "Yes, delete it!",
  //   }).then((result) => {
  //     if (result.value) {
  //       // Show password confirmation modal
  //       showPasswordModal(Sr_No, Name, UserId);
  //     }
  //   });
  // };
  

  const deleteUser = (Sr_No, Name, UserId) => {
 
    // If not a Staff or Vendor with Vendor_Id, proceed with deletion
    Swal.fire({
      title: "Are you sure?",
      text: `You are about to delete user: ${Name}. You won't be able to revert this!`,
      icon: "warning",
      showCancelButton: true,
      confirmButtonColor: "#3085d6",
      cancelButtonColor: "#d33",
      confirmButtonText: "Yes, delete it!",
    }).then((result) => {
      if (result.value) {
        // Show password confirmation modal if the user confirms
        showPasswordModal(Sr_No, Name, UserId);
      }
    });
  };
  


  




  
  const handleSubmit = async (EnqId, Customer_Id, item) => {
    const validationErrors = {};

    const Data = {
      ...item,
      Customer_Id: Customer_Id,
    };
    if (Object.keys(validationErrors).length === 0) {
      showLoader();
      await axios
        .put(`/approveQuotation/${EnqId}`, Data)
        .then((res) => {
          Swal.fire(
            "Success!",
            "Quotation Approved Successfully!!",
            "success"
          ).then((result) => {
            if (result.isConfirmed) {
              loadStaffData();
            }
          });
          hideLoader();
        })
        .catch((err) => {
          //error("Server is Busy!!");
          Swal.fire({
            title: "Error",
            text: "Failed to Approve Quotation. Please try again later.",
            icon: "error",
            confirmButtonText: "OK",
          });
          hideLoader();
        });
    }
  };





  // console.log(certificateData);

  const columns = [
    {
      name: "Date",
      selector: (row) => formatDate(row.Added_On),
      sortable: true,
      width: "100px",
    },
    {
      name: "Enquiry ID",
      selector: (row) => row.Enquiry_Id,
      sortable: true,
      width: "100px",
    },
    // {
    //   name: "ID",
    //   selector: (row) => row.CustomerId,
    //   sortable: true,
    // },
    {
      name: "Company Name",
      cell: (row) => (
        <LightTooltip title={row.Customer_Company}>
          <span
            style={{
              maxWidth: "180px",
              whiteSpace: "nowrap",
              overflow: "hidden",
              textOverflow: "ellipsis",
              padding: "5px",
              cursor: "pointer",
            }}
          >
            {row.Customer_Company ? row.Customer_Company : "--"}
          </span>
        </LightTooltip>
      ),
      width:"160px",
    },
    {
      name: "Mobile",
      selector: (row) => row.Mobile,
      width: "120px",
    },
    {
      name: "Certificate",
      cell: (row) => (
        <LightTooltip title={row.Certificate_Name}>
          <span
            style={{
              maxWidth: "160px",
              whiteSpace: "nowrap",
              overflow: "hidden",
              textOverflow: "ellipsis",
              padding: "5px",
              cursor: "pointer",
            }}
          >
            {row.Certificate_Name ? row.Certificate_Name : "--"}
          </span>
        </LightTooltip>
      ),
      width: "140px",
    },
    {
      name: "Remarks",
      cell: (row) => (
        <LightTooltip title={row.Remarks}>
          <span
            style={{
              margin: "0px 0px",
              maxWidth: "120px",
              whiteSpace: "nowrap",
              overflow: "hidden",
              textOverflow: "ellipsis",
              padding: "5px",
              cursor: "pointer",
            }}
          >
            {row.Remarks ? row.Remarks : "--"}
          </span>
        </LightTooltip>
      ),
      width:"120px",
    },
    {
      name: "Status",
      cell: (row) => (
        <span
          className={`badge ${
            row.Status === "Active"
              ? "bg-label-success"
              : row.Status === "Pending"
              ? "bg-label-warning"
              : "bg-label-primary"
          } me-1`}
        >
          {row.Status}
        </span>
      ),
      width: "100px",
    },
    {
      name: "Pending Step",
      selector: (row) => getCurrentPendingStep(row),
      cell: (row) => {
        const currentPendingStep = getCurrentPendingStep(row);
        return (
          <span
            style={{
              color:
                currentPendingStep === "No Pending Tasks" ? "green" : "blue",
            }}
          >
            {currentPendingStep}
          </span>
        );
      },
      width: "120px",
    },
    
    {
      name: "",
      cell: (row) => {
        if (userData?.Agent_Id) {
          // Show only the required options
          return (
            <div className="dropdown">
              <a
                className="p-0"
                type="button"
                id="dropdownMenuButton"
                data-bs-toggle="dropdown"
                aria-haspopup="true"
                aria-expanded="false"
              >
                <i>
                  <MoreVertIcon
                    style={{
                      color: "grey",
                      fontSize: "18px",
                      cursor: "pointer",
                    }}
                  />
                </i>
              </a>
              <div className="dropdown-menu" aria-labelledby="dropdownMenuButton">
                {/* View Summary */}
                <Link className="dropdown-item" onClick={() => open9(row)}>
                  <i
                    className="fas fa-clipboard"
                    style={{ fontSize: "16px", marginRight: "5px", color: "#6495ED" }}
                  ></i>
                  &nbsp;View Summary
                </Link>
    
                {/* Approve Quotation */}
                {row.Send_Quotation !== null && row.Quotation === "No" && (
                  <Link
                    className="dropdown-item"
                    onClick={() =>
                      handleSubmit(row.Enquiry_Sr_No, row.Sr_No, row)
                    }
                  >
                    <i
                      className="fas fa-check-square"
                      style={{ fontSize: "16px", marginRight: "5px" }}
                    ></i>
                    Approve Quotation
                  </Link>
                )}
                {row.Quotation === "Yes" &&
  row.Document_Status !== "Pending" &&
  row.Net_Amount === "0.00" &&
  row.Role === "Agent" && (
    <Link className="dropdown-item" onClick={() => open11(row)}>
      <i
        className="fas fa-address-book"
        style={{
          fontSize: "16px",
          marginRight: "5px",
          color: "#6f42c1", // Blue for "information" or "details"
        }}
      ></i>
      &nbsp;Add Customer Details
    </Link>
  )}

    
                {/* Upload Documents */}
                {row.Quotation === "Yes" && row.Document_Status === "Pending" && (
                  <Link className="dropdown-item" onClick={() => open3(row)}
                  >
                    <i
                      className="ti ti-file-upload text-warning"
                      style={{
                        fontSize: "18px",
                        marginRight: "5px",
                      }}
                    ></i>
                    Upload Documents
                  </Link>
                )}
              </div>
            </div>
          );
        }
    
        return null; // Hide dropdown if userData.Agent_Id is not present
      },
      width: "45px",
    }
    
    
    
    
  ];

  const customStyles = {
    rows: {
      style: {
        minHeight: "30px",
      },
    },
  };

  const LightTooltip = styled(({ className, ...props }) => (
    <Tooltip {...props} classes={{ popper: className }} />
  ))(({ theme }) => ({
    [`& .${tooltipClasses.tooltip}`]: {
      backgroundColor: theme.palette.common.white,
      color: "rgba(0, 0, 0, 0.87)",
      boxShadow: theme.shadows[1],
      fontSize: 11,
    },
  }));

  

  const DownloadPDF = () => {
    const doc = new jsPDF({
        orientation: "landscape",
    });

    doc.text("Enquiry Details", 20, 10);

    // Define the columns (headers) for the table
    const columns = [
      "Sr No",
      "Enquiry Id",
      "Customer Id",
      "Certificate Name",
      "Remarks",
      "Added On",
      "State",
      "GST No.",
      "Government",
      "Consultation",
      "Total Consultation",
      "Net Amount",
      "GST Amount",
      "Application No",
      "Application Date",
      "Username",
      "Password",
      "Link",
      "Mobile Number",
      "Certificate Number",
      "Certificate Date",
      "Renewal Date",
      "Financial Year",
      "Vendor Name",
      "Staff Name"
    ];

    // Prepare the rows for the table with all fields
    const rows = data.map((row) => {
        return [
            row.Sr_No,
            row.Enquiry_Id,
            row.CustomerId,
            row.Certificate_Name,
            row.Remarks ? row.Remarks : "--",
            formatDate(row.Added_On), // Format date 
            row.State,
            row.GST_No,
            row.Government,
            row.Consultation,
            row.Total_Consultation,
            row.Net_Amount,
            row.Gst_Amount,
            row.Application_No ? row.Application_No : "--",
            formatDate(row.Application_Date), // Format date fields
            row.Application_Username,
            row.Application_Password,
            row.Link ? row.Link : "--",
            row.Mobile_Number,
            row.Certificate_Number ? row.Certificate_Number : "--",
            formatDate(row.Certificate_Date), // Format date fields
            formatDate(row.Renewal_Date), // Format date fields
            row.Financial_Year ? row.Financial_Year : "--",
            row.Vendor_Name ? row.Vendor_Name : "--",
            row.Staff_Name ? row.Staff_Name : "--"
        ];
    });

    // Add the table to the PDF
    doc.autoTable({
        head: [columns],
        body: rows,
        startY: 20, // Adjust starting Y position if needed
    });

    // Save the generated PDF
    doc.save("Enquiry_Details.pdf");
};



const DownloadExcel = () => {
  // Define the columns (headers) for the Excel sheet
  const columns = [
    "Sr No",
    "Enquiry Id",
    "Customer Id",
    "Certificate Name",
    "Remarks",
    "Added On",
    "State",
    "GST No.",
    "Government",
    "Consultation",
    "Total Consultation",
    "Net Amount",
    "GST Amount",
    "Application No",
    "Application Date",
    "Username",
    "Password",
    "Link",
    "Mobile Number",
    "Certificate Number",
    "Certificate Date",
    "Renewal Date",
    "Financial Year",
    "Vendor Name",
    "Staff Name"
  ];

  // Initialize rows with the headers
  const rows = [columns];

  // Map through the data to prepare each row for the Excel sheet
  data.forEach((row) => {
    rows.push([
      row.Sr_No,
      row.Enquiry_Id,
      row.CustomerId,
      row.Certificate_Name,
      row.Remarks ? row.Remarks : "--",
      formatDate(row.Added_On), // Format date fields
      row.State,
      row.GST_No,
      row.Government,
      row.Consultation,
      row.Total_Consultation,
      row.Net_Amount,
      row.Gst_Amount,
      row.Application_No ? row.Application_No : "--",
      formatDate(row.Application_Date), // Format date fields
      row.Application_Username,
      row.Application_Password,
      row.Link ? row.Link : "--",
      row.Mobile,
      row.Certificate_Number ? row.Certificate_Number : "--",
      formatDate(row.Certificate_Date), // Format date fields
      formatDate(row.Renewal_Date), // Format date fields
      row.Financial_Year ? row.Financial_Year : "--",
      row.Vendor_Name ? row.Vendor_Name : "--",
      row.Staff_Name ? row.Staff_Name : "--"
      ]);
  });

  // Create the worksheet and the workbook
  const workSheet = XLSX.utils.aoa_to_sheet(rows);
  const workBook = XLSX.utils.book_new();
  XLSX.utils.book_append_sheet(workBook, workSheet, "Enquiry Details");

  // Write the workbook to an Excel file
  XLSX.writeFile(workBook, "Enquiry Details.xlsx");
};


  const handleFilter = (event) => {
    const filterValue = event.target.value.toLowerCase();

    const filteredData = dataa.filter((row) => {
      const fullName = `${row.First_Name} ${row.Last_Name}`.toLowerCase();
      const currentPendingStep = getCurrentPendingStep(row).toLowerCase();

      // Filter based on the pending step and other fields
      return (
        (pendingStepFilter === "All" || currentPendingStep.includes(pendingStepFilter.toLowerCase())) &&
        (
          fullName.includes(filterValue) ||
          row.Mobile?.toLowerCase().includes(filterValue) ||
          row.Email?.toLowerCase().includes(filterValue) ||
          row.Address?.toLowerCase().includes(filterValue) ||
          row.CustomerId?.toString().toLowerCase().includes(filterValue) ||
          (row.Added_On && formatDate(row.Added_On).toLowerCase().includes(filterValue)) ||
          row.Certificate_Name?.toLowerCase().includes(filterValue) ||
          row.Company_Name?.toLowerCase().includes(filterValue) ||
          row.Remarks?.toLowerCase().includes(filterValue)
        )
      );
    });

    setStaffData(filteredData);
  };
  


  return (
    <div className="container-fluid" style={{ padding: "0" }}>
      <div className="row">
        <div className="col-12">
          <div className="page-title-box justify-content-between d-flex align-items-md-right flex-md-row flex-column">
            <h4 className="page-title">Enquiries</h4>
            <div className="page-title">
              <Link onClick={() => open10()}>
                <button className="btn btn-primary btn-sm text-center">
                  Add
                </button>
              </Link>
            </div>
          </div>
          <div className="card">
            <h5 className="page-title"></h5>
            <div className="card-body" style={{ paddingTop: "5px" }}>
              <div className="row justify-content-center mt-2">
                {/* <div className="col-2">
                  <label htmlFor="example-select" className="form-label">
                    Company
                  </label>
                  <Select
                    id="example-select"
                    value={
                      filter.Customer_Id
                        ? {
                            value: filter?.Customer_Id,
                            label: filter?.Company_Name,
                          }
                        : null
                    }
                    options={[
                      { value: "", label: "All" },
                      ...data.map((item, index) => ({
                        value: item.Sr_No,
                        label: item.Company_Name,
                      })),
                    ]}
                    onChange={(selectedOption) => {
                      // console.log(selectedOption.value, selectedOption.label);
                      setFilter({
                        ...filter,
                        Customer_Id: selectedOption.value,
                        Company_Name: selectedOption.label,
                      });
                    }}
                  />
                </div> */}
                <div className="col-3">
  <label htmlFor="example-select" className="form-label">
    Certificate Name
  </label>
  <Select
    id="example-select"
    value={
      filter.Certificate_Id
        ? {
            value: filter.Certificate_Id,
            label: filter.Certificate_Name,
          }
        : null
    }
    options={[
      { value: "", label: "All" }, // Default option for selecting all certificates
      ...Array.from(
        new Map(
          data.map((item) => [item.Certificate_Name, item]) // Use Certificate_Name as the key to remove duplicates
        ).values()
      ).map((uniqueItem) => ({
        value: uniqueItem.Certificate_Id, // Unique identifier for certificate
        label: uniqueItem.Certificate_Name, // Certificate name as label
      })),
    ]}
    onChange={(selectedOption) => {
      // Update the filter state with the selected certificate's value and label
      setFilter({
        ...filter,
        Certificate_Id: selectedOption.value,
        Certificate_Name: selectedOption.label,
      });
    }}
  />
</div>


                <div className="col-3">
                  <label htmlFor="example-select" className="form-label">
                    From Date
                  </label>
                  <input
                    type="date"
                    className="form-control"
                    value={filter.fromDate}
                    onChange={(e) => {
                      setFilter({
                        ...filter,
                        fromDate: e.target.value,
                      });
                    }}
                  ></input>
                </div>
                <div className="col-3">
                  <label htmlFor="example-select" className="form-label">
                    To Date
                  </label>
                  <input
                    type="date"
                    className="form-control"
                    value={filter.toDate}
                    onChange={(e) => {
                      setFilter({
                        ...filter,
                        toDate: e.target.value,
                      });
                    }}
                  ></input>
                </div>
                <div className="col-2">
                  <label htmlFor="example-select" className="form-label">
                    Status
                  </label>
                  <Select
                    id="example-select"
                    value={
                      filter.Status
                        ? { value: filter?.Status, label: filter?.Status }
                        : null
                    }
                    options={[
                      { value: "", label: "All" },
                      { value: "Active", label: "Active" },
                      { value: "Pending", label: "Pending" },
                      {
                        value: "Certificate Issued",
                        label: "Certificate Issued",
                      },
                      {
                        value: "Pending Certificate",
                        label: "Pending Certificate",
                      },
                    ]}
                    onChange={(selectedOption) => {
                      setFilter({
                        ...filter,
                        Status: selectedOption.value,
                      });
                    }}
                  />
                </div>
              </div>
              <div className="row justify-content-center mt-2">
                <div className="col-md-2">
                  <button
                    className="btn btn-sm btn-primary"
                    onClick={filterData}
                    style={{ width: "100%" }}
                  >
                    Filter
                  </button>
                </div>
              </div>
            </div>
          </div>
          <div className="card">
            <div className="card-body">
            <Stack
      direction="row"
      spacing={2}
      className="mx-2 my-2 mb-2"
      sx={{ zIndex: 0, position: "relative" }}
    >
      <TextField
        onChange={handleFilter}
        size="small"
        label="Search..."
      />
      <div>
        <Tooltip title="Export to PDF">
          <i
            style={{
              cursor: "pointer",
              padding: "5px",
            }}
            onClick={() => {
              DownloadPDF();
            }}
          >
            <PictureAsPdfIcon />
          </i>
        </Tooltip>

        <Tooltip title="Export to Excel">
          <i
            style={{
              padding: "5px",
              cursor: "pointer",
            }}
            onClick={() => {
              DownloadExcel();
            }}
          >
            <Icon path={mdiFileExcel} size={1} />
          </i>
        </Tooltip>
      </div>
    </Stack>

              <div style={{ zIndex: 0, position: "relative" }}>
                <DataTable
                  // title="Employee Data"
                  columns={columns}
                  data={data}
                  pagination
                  highlightOnHover
                  customStyles={customStyles}
                  fixedHeader
                />
              </div>
            </div>
          </div>
          <CustomPasswordModal
            showModal={showModal}
            onClose={closePasswordModal}
            Sr_No={Sr_No}
            Name={Name}
            UserId={UserId}
          />

        </div>

        {isModal && (
          <>
            <UploadCertificate
              open={isModal}
              onClose={closed}
              Modaldata={Modaldata}
            />
          </>
        )}
        {isModal1 && (
          <>
            <ApproveRejectDoc
              open={isModal1}
              onClose={closed1}
              updateData={Modaldata}
            />
          </>
        )}


        {isModal2 && (
          <>
            <ApproveRejectPayment
              open={isModal2}
              onClose={closed2}
              updateData={Modaldata}
            />
          </>
        )}

        {isModal3 && (
          <UploadDocument
            open={isModal3}
            onClose={closed3}
            updateData={Modaldata}
            Role={"Facility"}
          />
        )}

        {isModal4 && (
          <UpdateDocumentByFac
            open={isModal4}
            onClose={closed4}
            updateData={Modaldata}
          />
        )}
        {isModal5 && (
          <SetQuotation
            open={isModal5}
            onClose={closed5}
            updateData={Modaldata}
          />
        )}
        {isModal6 && (
          <QuotationPreview
            open={isModal6}
            onClose={closed6}
            updateData={Modaldata}
          />
        )}
        {isModal7 && (
          <UploadAcknowledgement
            open={isModal7}
            onClose={closed7}
            updateData={Modaldata}
          />
        )}
        {isModal8 && (
          <AssignEnquiry
            open={isModal8}
            onClose={closed8}
            updateData={Modaldata}
          />
        )}
        {isModal9 && (
          <SummaryModal
            open={isModal9}
            onClose={closed9}
            updateData={Modaldata}
          />
        )}
         {isModal10 && (
          <ApplyAgent
            open={isModal10}
            onClose={closed10}
            agentData={Modaldata}
          />
        )}
        {isModal11 && (
          <AgentCustomer
            open={isModal11}
            onClose={closed11}
            updateData={Modaldata}
          />
        )}
        {loader}
      </div>
    </div>
  );
};
export default AgentEnquiry;
