import React, { useState, useEffect } from "react";
import axios from "../../components/axios";
import { useUser } from "../../components/UserContext";
import Swal from "sweetalert2";
import useFullPageLoader from "../../components/useFullPageLoader";
import { Link, useNavigate, useParams } from "react-router-dom";

const VendorPayment = () => {
  const navigate = useNavigate();
  const { userData } = useUser();
  const [selectedid ,setSelectedId] =  useState(null)
  const [errors, setErrors] = useState({});
  const [vendorData, setVendorData] = useState([]);
  const [vendorPaymentData, setVendorPaymentData] = useState([]);
  const [loader, showLoader, hideLoader] = useFullPageLoader();
  const [total , setTotal] = useState([])
  const [paid , setPaid] = useState([])
  const [balance , setBalance] = useState([])
  const [formData, setFormData] = useState({
   Vendor_Name: "",
   Vendor_Id:"",
   Payment_Mode:"",
   Payment_Date:"",
   Payment_Amount:"",
   Remarks:"",
   Company_Name:"",
   Transaction_Id: '',
   Bank_Name: '',
   Cheque_No: '',
   Cheque_Date: '',
   Cheque_Status: ''

   
  });

  const loadVendorData = async () => {
    showLoader(); // Show loader at the start of the function

    try {
      const result = await axios.get(`/getAllVendors`);
      setVendorData(result.data.vendors || []);
    } catch (error) {
      console.error("Error fetching data: ", error);
      alert("Failed to load staff data. Please try again later.");
    } finally {
      hideLoader(); // Hide loader after the function finishes
    }
  };


  const loadVendorPayment = async (selectedVendorId) => {
    showLoader(); // Show loader at the start of the function
  
    try {
      const result = await axios.get(`/getVendors/${selectedVendorId}`);
      setBalance(result.data.vendorDetails.Balance || 0);
      setTotal(result.data.vendorDetails.Total || 0);
      setPaid(result.data.vendorDetails.Paid || 0);
    } catch (error) {
      console.error("Error fetching data: ", error);
      Swal.fire({
        icon: 'error',
        title: 'Error',
        text: 'No Payment Details found for the selected vendor.',
      });
    } finally {
      hideLoader(); // Hide loader after the function finishes
    }
  };
  
  const loadVendorPaymentData = async (selectedVendorId,selectedVendor) => {
    showLoader(); // Show loader at the start of the function
  
    try {
    const result = await axios.get(`/getAllVendorPayments/${selectedVendorId || formData.Vendor_Id}`);
      setVendorPaymentData(result.data.vendorPayments || []);
    } catch (error) {
      console.error("Error fetching data: ", error);
      Swal.fire({
        icon: 'info', // Changed to 'info' to indicate no payments made
        title: 'No Payments Found',
        text: `No payment has been found for Vendor Name: ${selectedVendor?.Name}.`,
      });
    } finally {
      hideLoader(); // Hide loader after the function finishes
    }
  };
  







  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData((prev) => ({
      ...prev,
      [name]: value,
    }));
  };



  
    // Handle Vendor selection change
    const handleVendorChange = (e) => {
      const selectedVendorId = e.target.value;
    
      const selectedVendor = vendorData.find(
        (vendor) => vendor.Vendor_Id.toString() === selectedVendorId.toString()
      );

      setSelectedId(selectedVendorId);
      setVendorPaymentData([]);
      
      if(selectedVendorId){
        loadVendorPayment(selectedVendorId)
        loadVendorPaymentData(selectedVendorId,selectedVendor)
      }

      setFormData({
        ...formData,
        Vendor_Name: selectedVendor ? selectedVendor.Name : "",
        Vendor_Id: selectedVendorId,
        Company_Name: selectedVendor ? selectedVendor.Company_Name : "",
      });
    };

    
    



  const validate = () => {
    let tempErrors = {};
  
    // Check for Vendor Name
    if (!formData.Vendor_Name) {
      tempErrors.Vendor_Name = "Vendor Name is required.";
    }
  
    // Check for Payment Amount
    if (!formData.Payment_Amount) {
      tempErrors.Payment_Amount = "Amount is required.";
    } else if (isNaN(formData.Payment_Amount)) {
      tempErrors.Payment_Amount = "Amount must be a valid number.";
    }
  
    // Check for Payment Date
    if (!formData.Payment_Date) {
      tempErrors.Payment_Date = "Payment Date is required.";
    }

    if (!formData.Payment_Mode) tempErrors.Payment_Mode = "Payment Mode is required.";

    if (formData.Payment_Mode === 'Cheque') {
      if (!formData.Bank_Name) tempErrors.Bank_Name = "Bank Name is required.";
      if (!formData.Cheque_No) tempErrors.Cheque_No = "Cheque No is required.";
      if (!formData.Cheque_Date) tempErrors.Cheque_Date = "Cheque Date is required.";
      if (!formData.Cheque_Status) tempErrors.Cheque_Status = "Cheque Status is required.";
    }

    if (formData.Payment_Mode === 'Online') {
      if (!formData.Transaction_Id) tempErrors.Transaction_Id = "Transaction Id is required.";
    }

    if (!formData.Payment_Amount || isNaN(formData.Payment_Amount)) {
      tempErrors.Payment_Amount = "Amount must be a valid number.";
    } else if (formData.Payment_Amount > balance) {
      tempErrors.Payment_Amount = `Amount cannot exceed the paid amount of ${balance}.`;
    }
    
  
    // Set the errors state
    setErrors(tempErrors);
  
    // Return true if no errors exist
    return Object.keys(tempErrors).length === 0;
  };
  


  const handleSubmit = async (e) => {
    e.preventDefault();
  
    // Show loader
    showLoader();
  
    // Validate form
    if (!validate()) {
      Swal.fire({
        icon: "error",
        title: "Validation Failed",
        text: "Please fill all required fields correctly.",
      });
      hideLoader();  // Ensure loader is hidden on validation failure
      return;
    }
  
    try {
      // Prepare the payload as an object
      const payload = {
        Vendor_Id: formData.Vendor_Id,
        Vendor_Name: formData.Vendor_Name,
        Company_Name: formData.Company_Name,
        Org_Id: userData?.Org_Id,
        Payment_Mode: formData.Payment_Mode,
        Payment_Date: formData.Payment_Date,
        Payment_Amount: formData.Payment_Amount,
        Added_By: `${userData?.First_Name} ${userData?.Last_Name}`,
        Added_By_Id: userData?.UserId,
        Remarks: formData.Remarks,
  
        // Add Payment_Status based on conditions
        Payment_Status:
          formData.Payment_Mode === "Cheque" &&
          (formData.Cheque_Status === "Cleared" || formData.Cheque_Status === "Honored")
            ? "Paid"
            : formData.Payment_Mode === "Online" || formData.Payment_Mode === "Cash"
            ? "Paid"
            : "Pending", // Default status if no condition matches
  
        ...(formData.Payment_Mode === "Cheque" && {
          Bank_Name: formData.Bank_Name,
          Cheque_No: formData.Cheque_No,
          Cheque_Date: formData.Cheque_Date,
          Cheque_Status: formData.Cheque_Status,
        }),
  
        ...(formData.Payment_Mode === "Online" && {
          Transaction_Id: formData.Transaction_Id,
        }),
      };
  
  
      // Send the data as JSON to the server
      const response = await axios.post('/makePayments', payload, {
        headers: {
          "Content-Type": "application/json", // Indicate we're sending JSON data
        },
      });
  
  
      if (response.status === 200) {
        Swal.fire({
          icon: "success",
          title: "Payment Successful",
          text: "Payment has been done successfully.",
        });
  
        // Reset the form after success
        setSelectedId(null)
        setVendorPaymentData([]);
        setFormData({
          Vendor_Name: "",
          Vendor_Id: "",
          Payment_Mode: "",
          Payment_Date: "",
          Payment_Amount: "",
          Remarks: "",
          Company_Name: "",
          Transaction_Id: '',
          Bank_Name: '',
          Cheque_No: '',
          Cheque_Date: '',
          Cheque_Status: ''
        });
      } else {
        Swal.fire({
          icon: "error",
          title: "Payment Failed",
          text: "Failed to do payment. Please try again.",
        });
      }
    } catch (error) {
      console.error("Error while submitting form:", error);
      Swal.fire({
        icon: "error",
        title: "Error",
        text: "Something went wrong. Please try again later.",
      });
    } finally {
      hideLoader();  // Ensure loader is hidden in the finally block
    }
  };


  const handleDelete = async (Sr_No) => {
    

    try {
      // Show confirmation dialog
      const result = await Swal.fire({
        title: "Are you sure?",
        text: "This will delete the Payment.",
        icon: "warning",
        showCancelButton: true,
        confirmButtonText: "Yes, delete it!",
        cancelButtonText: "No, cancel!",
        reverseButtons: true,
      });

      // If user clicks 'Yes, delete it!'
      if (result.isConfirmed) {
        showLoader();

        // Make the DELETE request using Axios to delete the GST category by Row_Id
        const response = await axios.delete(`/deleteVendorPayment/${Sr_No}`);

        // Check if the deletion was successful
        if (response.status === 200) {
          // Display success message
          Swal.fire({
            title: "Deleted!",
            text: "Vendor Payment has been deleted.",
            icon: "success",
          });

          setVendorPaymentData([]);
          setFormData({
            Vendor_Name: "",
            Vendor_Id: "",
            Payment_Mode: "",
            Payment_Date: "",
            Payment_Amount: "",
            Remarks: "",
            Company_Name: "",
            Transaction_Id: '',
            Bank_Name: '',
            Cheque_No: '',
            Cheque_Date: '',
            Cheque_Status: ''
          });

          

    
          } else {
          // Display error message if something goes wrong
          Swal.fire({
            title: "Error!",
            text: "There was a problem deleting the Vendor Payment.",
            icon: "error",
          });
        }
      }
    } catch (error) {
      console.error("Error in handleDelete:", error);
      Swal.fire({
        title: "Error!",
        text: "An unexpected error occurred.",
        icon: "error",
      });
    }finally {
      hideLoader();
    }
  };
  

  

  
  
  

  useEffect(() => {
   loadVendorData();
  }, []);

  return (
    <div className="row">
      <div className="col-12">
        <div className="page-title-box justify-content-between d-flex align-items-md-right flex-md-row flex-column">
          <h4 className="page-title">Vendor Payments </h4>
        </div>
        <div className="card">
          <div className="card-body">
            <form onSubmit={handleSubmit}>
              {/* Vendor Details Section */}
              <div className="row ">
                <div className="col-12">
                  <h5
                    className="section-title"
                    style={{
                      marginBottom: "-10px",
                      display: "flex",
                      justifyContent: "space-between",
                      alignItems: "center",
                    }}
                  >
                    Make Payments
                    <Link
                      to="/viewAllVendorPayments"
                      className="btn btn-primary"
                      style={{
                        fontSize: "14px",
                        padding: "12px",
                        textDecoration: "none",
                      }}
                    >
                      View All
                    </Link>
                  </h5>{" "}
                  <hr className="mb-1" />
                </div>
              </div>
              <div className="row">
              <div className="col-md-3">
              <div className="mb-3">
                <label htmlFor="Vendor_Name" className="form-label">
                  Vendor Name<span style={{ color: "red" }}>*</span>
                </label>
                <select
                  id="Vendor_Name"
                  name="Vendor_Name"
                  className="form-select"
                  value={formData.Vendor_Id} // Bind to Vendor_Id, not Vendor_Name
                  onChange={handleVendorChange} // Handle vendor selection change
                >
                  <option value="">Select Vendor Name</option>
                  {vendorData.map((vendor) => (
                    <option key={vendor.Vendor_Id} value={vendor.Vendor_Id}>
                      {vendor.Name}
                    </option>
                  ))}
                </select>
                {errors.Vendor_Name && (
                  <small className="text-danger">{errors.Vendor_Name}</small>
                )}
              </div>
            </div>
            {formData.Vendor_Id && (
        <>
        <div className="col-md-3">
          <div className="mb-3">
            <label htmlFor="Total" className="form-label">
              Total
            </label>
            <input
              type="text"
              className="form-control"
              id="Total"
              value={total}
              readOnly // Make the field read-only
              title="This field is read-only" // Tooltip for users
              style={{ backgroundColor: '#f0f0f0', cursor: 'not-allowed' }} // Visual cue
            />
          </div>
        </div>
      
        <div className="col-md-3">
          <div className="mb-3">
            <label htmlFor="Paid" className="form-label">
              Paid
            </label>
            <input
              type="text"
              className="form-control"
              id="Paid"
              value={paid}
              readOnly
              title="This field is read-only"
              style={{ backgroundColor: '#f0f0f0', cursor: 'not-allowed' }}
            />
          </div>
        </div>
      
        <div className="col-md-3">
          <div className="mb-3">
            <label htmlFor="Balance" className="form-label">
              Balance
            </label>
            <input
              type="text"
              className="form-control"
              id="Balance"
              value={balance}
              readOnly
              title="This field is read-only"
              style={{ backgroundColor: '#f0f0f0', cursor: 'not-allowed' }}
            />
          </div>
        </div>
      </>
      
      )}
<div className={selectedid ? "col-md-4" : "col-md-3"}>
<div className="mb-3">
                  <label htmlFor="Payment_Mode" className="form-label">
                    Payment Mode<span style={{ color: "red" }}>*</span>
                  </label>
                  <select
                    id="Payment_Mode"
                    name="Payment_Mode"
                    className="form-select"
                    value={formData.Payment_Mode}
                    onChange={handleChange}
                  >
                    <option value="" disabled>Select Payment Mode</option>
                    <option value="Online">Online</option>
                    <option value="Cash">Cash</option>
                    <option value="Cheque">Cheque</option>
                  </select>
                  {errors.Payment_Mode && (
                    <small className="text-danger">{errors.Payment_Mode}</small>
                  )}
                </div>
              </div>
              <div className={selectedid ? "col-md-4" : "col-md-3"}>
              <div className="mb-3">
                    <label htmlFor="Payment_Amount" className="form-label">
                      Payment Amount<span style={{ color: "red" }}>*</span>
                    </label>
                    <input
                      type="text"
                      maxLength={10}
                      id="Payment_Amount"
                      name="Payment_Amount"
                      className="form-control"
                      placeholder="Enter Payment Amount"
                      onChange={handleChange}
                      value={formData.Payment_Amount}
                    />
                    {errors.Payment_Amount && (
                      <small className="text-danger">{errors.Payment_Amount}</small>
                    )}
                  </div>
                </div>
                <div className={selectedid ? "col-md-4" : "col-md-3"}>
                <div className="mb-3">
                    <label htmlFor="Payment_Date" className="form-label">
                      Payment Date<span style={{ color: "red" }}>*</span>
                    </label>
                    <input
                      type="date"
                      id="Payment_Date"
                      name="Payment_Date"
                      className="form-control"
                      placeholder="Enter Payment Date "
                      onChange={handleChange}
                      value={formData.Payment_Date}
                      
                    />
                    {errors.Payment_Date && (
                  <small className="text-danger">{errors.Payment_Date}</small>
                )}
                  </div>
                </div>
              {formData.Payment_Mode === "Online" && (
        <div className="col-md-12">
          <div className="mb-3">
            <label htmlFor="Transaction_Id" className="form-label">
              Transaction Id<span style={{ color: "red" }}>*</span>
            </label>
            <input
              type="text"
              id="Transaction_Id"
              name="Transaction_Id"
              className="form-control"
              placeholder="Enter Transaction ID"
              value={formData.Transaction_Id}
              onChange={handleChange}
            />
            {errors.Transaction_Id && <small className="text-danger">{errors.Transaction_Id}</small>}
          </div>
        </div>
      )}

      {formData.Payment_Mode === "Cheque" && (
        <>
          <div className="col-md-3">
            <div className="mb-3">
              <label htmlFor="Bank_Name" className="form-label">
                Bank Name<span style={{ color: "red" }}>*</span>
              </label>
              <input
                type="text"
                id="Bank_Name"
                name="Bank_Name"
                className="form-control"
                value={formData.Bank_Name}
                placeholder="Enter Bank Name"
                onChange={handleChange}
                
              />
              {errors.Bank_Name && <small className="text-danger">{errors.Bank_Name}</small>}
            </div>
          </div>

          <div className="col-md-3">
            <div className="mb-3">
              <label htmlFor="Cheque_No" className="form-label">
                Cheque No<span style={{ color: "red" }}>*</span>
              </label>
              <input
                type="text"
                id="Cheque_No"
                name="Cheque_No"
                className="form-control"
                placeholder="Enter Cheque Number"
                value={formData.Cheque_No}
                onChange={handleChange}
              />
              {errors.Cheque_No && <small className="text-danger">{errors.Cheque_No}</small>}
            </div>
          </div>

          <div className="col-md-3">
            <div className="mb-3">
              <label htmlFor="Cheque_Date" className="form-label">
                Cheque Date<span style={{ color: "red" }}>*</span>
              </label>
              <input
                type="date"
                id="Cheque_Date"
                name="Cheque_Date"
                className="form-control"
                placeholder="Enter Cheque Date"
                value={formData.Cheque_Date}
                onChange={handleChange}
              />
              {errors.Cheque_Date && <small className="text-danger">{errors.Cheque_Date}</small>}
            </div>
          </div>

          <div className="col-md-3">
            <div className="mb-3">
              <label htmlFor="Cheque_Status" className="form-label">
                Cheque Status<span style={{ color: "red" }}>*</span>
              </label>
              <select
                id="Cheque_Status"
                name="Cheque_Status"
                className="form-select"
                value={formData.Cheque_Status}
                onChange={handleChange}
              >
                <option value="">Select Cheque Status</option>
                <option value="Honored">Honored</option>
                <option value="Dishonored">Dishonored</option>
                <option value="Uncleared">Uncleared</option>
              </select>
              {errors.Cheque_Status && <small className="text-danger">{errors.Cheque_Status}</small>}
            </div>
          </div>
        </>
      )}

               
              </div>
              <div className="row">
              <div className="col-md-12">
              <div className="mb-3">
                <label htmlFor="Remarks" className="form-label">
                  Remarks
                </label>
                <textarea
                  className="form-control"
                  name="Remarks"
                  id="Remarks"
                  placeholder="Enter Remarks"
                  rows="4" 
                  onChange={handleChange}
                  value={formData.Remarks}
                />
                {errors.Remarks && (
                  <small className="text-danger">{errors.Remarks}</small>
                )}
              </div>
            </div>
            </div>

            <div className="row justify-content-center">
  <div className="col-md-2 col-sm-3 col-xs-3 col-3 d-flex justify-content-center">
    <button type="submit" className="btn btn-primary">
      Pay
    </button>
  </div>
</div>


              
              
            </form>
            
{vendorPaymentData && vendorPaymentData.length > 0 && (
  <>
    <div className="row">
      <div className="col-12">
        <h5 className="section-title" style={{ marginBottom: "-10px" }}>
          All Vendor Payments
        </h5>
        <hr className="mb-1" />
      </div>
    </div>

    <div className="row mt-4">
      <div className="col-md-12">
        <div className="table-responsive">
          <table
            id="transactionTable"
            className="table table-striped table-bordered display dataTable"
          >
            <thead>
              <tr>
                <th>#</th>
                <th>Transaction Date</th>
                <th>Amount</th>
                <th>Pay Mode</th>
                <th>Bank Name</th>
                <th>Cheque No</th>
                <th>Cheque Date</th>
                <th>Cheque Status</th>
                <th>Pay Status</th>
                <th>Remarks</th>
                <th>Operations</th>
              </tr>
            </thead>
            <tbody>
              {vendorPaymentData.map((payment, index) => (
                <tr key={payment.Sr_No}>
                  <td>{index + 1}</td> {/* Display index + 1 as the row number */}
                  <td>{new Date(payment.Payment_Date).toLocaleDateString()}</td> {/* Format date */}
                  <td>{payment.Payment_Amount}</td>
                  <td>{payment.Payment_Mode}</td>
                  <td>{payment.Bank_Name || "N/A"}</td>
                  <td>{payment.Cheque_No || "N/A"}</td>
                  <td>{payment.Cheque_Date ? new Date(payment.Cheque_Date).toLocaleDateString() : "N/A"}</td>
                  <td>{payment.Cheque_Status || "N/A"}</td>
                  <td>{payment.Payment_Status}</td>
                  <td>{payment.Remarks || "N/A"}</td>
                  <td className="text-center">
                    <i
                      className="fas fa-trash-alt"
                      title="Delete"
                      style={{
                        fontSize: "18px",
                        cursor: "pointer",
                        color: "black",
                        transition: "transform 0.2s ease, color 0.2s ease",
                      }}
                      onMouseEnter={(e) => {
                        e.target.style.transform = "scale(1.1)";
                        e.target.style.color = "#ff0000";  // Red on hover
                      }}
                      onMouseLeave={(e) => {
                        e.target.style.transform = "scale(1)";
                        e.target.style.color = "black"; // Reset back to black when hover ends
                      }}
                      onClick={() => handleDelete(payment.Sr_No)}
                    ></i>
                  </td>
                </tr>
              ))}
            </tbody>
          </table>
        </div>
      </div>
    </div>
  </>
)}


            
          </div>
        </div>
        {loader}
      </div>
    </div>
  );
};

export default VendorPayment;
