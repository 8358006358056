import React, { useState, useEffect } from "react";
import axios from "../../components/axios";
import { Link } from "react-router-dom";
import useFullPageLoader from "../../components/useFullPageLoader";
import Stack from "@mui/material/Stack";
import TextField from "@mui/material/TextField";
import Tooltip from "@mui/material/Tooltip";
import Icon from "@mdi/react";
import PictureAsPdfIcon from "@mui/icons-material/PictureAsPdf";
import { mdiFileExcel } from "@mdi/js";
import { jsPDF } from "jspdf";
import * as XLSX from "xlsx";
import dayjs from "dayjs";
import Swal from "sweetalert2";



const ViewAllVendorPayments = () => {
  const [vendorPaymentData, setVendorPaymentData] = useState([]);
  const [filteredData, setFilteredData] = useState([]);
  const [vendors, setVendors] = useState([]);
  const [loader, showLoader, hideLoader] = useFullPageLoader();
  const [selectedVendor, setSelectedVendor] = useState("");
  const [fromDate, setFromDate] = useState(dayjs().startOf("month").format("YYYY-MM-DD"));
  const [toDate, setToDate] = useState(dayjs().endOf("month").format("YYYY-MM-DD"));
  const [searchQuery, setSearchQuery] = useState("");

  // Fetch the list of vendors
  const loadVendors = async () => {
    showLoader();
    try {
      const result = await axios.get(`/getAllVendors`);
      setVendors(result.data.vendors || []);
    } catch (error) {
      console.error("Error fetching vendors: ", error);
      alert("Failed to load vendors. Please try again later.");
    } finally {
      hideLoader();
    }
  };

  // Load filtered vendor payments based on selected vendor and date range
  const loadVendorPayments = async () => {
    showLoader();
    try {
      const result = await axios.get(`/getVendorPayments`, {
        params: { vendorName: selectedVendor, fromDate, toDate },
      });
  
      // Check if data exists and update the state accordingly
      if (result.data.vendorPayments && result.data.vendorPayments.length > 0) {
        setVendorPaymentData(result.data.vendorPayments);
        setFilteredData(result.data.vendorPayments);
      } else {
        // Reset the data if no payments are found
        setVendorPaymentData([]);
        setFilteredData([]);
  
        // Show SweetAlert2 message
        Swal.fire({
          icon: 'info',
          title: 'No Payments Found',
          text: 'There are no payments available for the selected date range.',
          confirmButtonText: 'OK'
        });
      }
    } catch (error) {
      // This will now only be triggered for actual errors, not for empty data
      console.error("Error fetching data: ", error);
      alert("Failed to load vendor payment data. Please try again later.");
    } finally {
      hideLoader();
    }
  };



  useEffect(() => {
    loadVendorPayments(); // Load vendors when the component mounts
    loadVendors();
  }, []);

  const filterData = () => {
    loadVendorPayments();
  };

  const handleSearch = (event) => {
    const query = event.target.value;
    setSearchQuery(query);
  
    if (query === "") {
      setFilteredData(vendorPaymentData); // Reset to full data if search is cleared
    } else {
      const filtered = vendorPaymentData.filter((payment) => {
        return (
          (payment.Payment_Amount?.toString().includes(query)) ||
          (payment.Payment_Mode?.toLowerCase().includes(query.toLowerCase())) ||
          (payment.Bank_Name?.toLowerCase().includes(query.toLowerCase())) ||
          (payment.Cheque_No?.toString().includes(query)) ||
          (payment.Remarks?.toLowerCase().includes(query.toLowerCase())) ||
          (payment.Payment_Status?.toLowerCase().includes(query.toLowerCase()))

        );
      });
      setFilteredData(filtered);
    }
  };
  

  // Export data to PDF
  const downloadPDF = () => {
    const doc = new jsPDF();
    const table = document.getElementById("transactionTable");
    doc.autoTable({ html: table });
    doc.save("vendor-payments.pdf");
  };

  // Export data to Excel
  const downloadExcel = () => {
    const ws = XLSX.utils.table_to_sheet(document.getElementById("transactionTable"));
    const wb = XLSX.utils.book_new();
    XLSX.utils.book_append_sheet(wb, ws, "Vendor Payments");
    XLSX.writeFile(wb, "vendor-payments.xlsx");
  };

  return (
    <div className="row">
      <div className="col-12">
        <div className="page-title-box justify-content-between d-flex align-items-md-right flex-md-row flex-column">
          <h4 className="page-title">Vendor Payments</h4>
          <div className="d-flex justify-content-end mb-3">
    <Link
      to="/vendorPayment"
      className="btn btn-primary"
      style={{
        fontSize: "14px",
        padding: "12px",
        textDecoration: "none",
      }}
    >
      New
    </Link>
  </div>
        </div>
        <div className="card">
          <div className="card-body">
            {/* Filter Section */}

            <div className="row mt-3">
              <div className="col-md-4">
                <label htmlFor="example-select" className="form-label">
                  Vendor Name
                </label>
                <select
                  className="form-select"
                  value={selectedVendor}
                  onChange={(e) => setSelectedVendor(e.target.value)}
                >
                  <option value="">Select Vendor</option>
                  {vendors.map((vendor, index) => (
                    <option key={index} value={vendor.Name}>
                      {vendor.Name}
                    </option>
                  ))}
                </select>
              </div>

              <div className="col-md-4">
                <label htmlFor="example-select" className="form-label">
                  From Date
                </label>
                <input
                  type="date"
                  className="form-control"
                  value={fromDate}
                  onChange={(e) => setFromDate(e.target.value)}
                />
              </div>

              <div className="col-md-4">
                <label htmlFor="example-select" className="form-label">
                  To Date
                </label>
                <input
                  type="date"
                  className="form-control"
                  value={toDate}
                  onChange={(e) => setToDate(e.target.value)}
                />
              </div>
            </div>
            <div className="row justify-content-center mt-4">
              <div className="col-md-2">
                <button
                  className="btn btn-sm btn-primary"
                  style={{ width: "100%" }}
                  onClick={filterData}
                >
                  Filter
                </button>
              </div>
            </div>

            {/* Search and Export Icons */}
            <Stack direction="row" spacing={2} className="mx-2 my-2 mb-2">
              <TextField
                size="small"
                label="Search..."
                value={searchQuery}
                onChange={handleSearch}
              />
              <div>
                <Tooltip title="Export to PDF">
                  <i
                    style={{
                      cursor: "pointer",
                      padding: "5px",
                    }}
                    onClick={downloadPDF}
                  >
                    <PictureAsPdfIcon />
                  </i>
                </Tooltip>

                <Tooltip title="Export to Excel">
                  <i
                    style={{
                      padding: "5px",
                      cursor: "pointer",
                    }}
                    onClick={downloadExcel}
                  >
                    <Icon path={mdiFileExcel} size={1} />
                  </i>
                </Tooltip>
              </div>
            </Stack>

            {/* Vendor Payments Table */}
            <div className="row mt-3">
              <div className="col-12">
                <h5
                  className="section-title"
                  style={{
                    marginBottom: "-10px",
                    display: "flex",
                    justifyContent: "space-between",
                    alignItems: "center",
                  }}
                >
                  All Vendor Payments
                </h5>
                <hr className="mb-1" />
              </div>
            </div>

            <div className="row mt-4">
              <div className="col-md-12">
                <div className="table-responsive">
                  <table
                    id="transactionTable"
                    className="table table-striped table-bordered display dataTable"
                  >
                    <thead>
                      <tr>
                        <th>#</th>
                        <th>Transaction Date</th>
                        <th>Amount</th>
                        <th>Pay Mode</th>
                        <th>Bank Name</th>
                        <th>Cheque No</th>
                        <th>Cheque Date</th>
                        <th>Cheque Status</th>
                        <th>Pay Status</th>
                        <th>Remarks</th>
                      </tr>
                    </thead>
                    <tbody>
                      {filteredData && filteredData.length > 0 ? (
                        filteredData.map((payment, index) => (
                          <tr key={payment.Sr_No}>
                            <td>{index + 1}</td>
                            <td>{new Date(payment.Payment_Date).toLocaleDateString()}</td>
                            <td>{payment.Payment_Amount}</td>
                            <td>{payment.Payment_Mode}</td>
                            <td>{payment.Bank_Name || "N/A"}</td>
                            <td>{payment.Cheque_No || "N/A"}</td>
                            <td>{payment.Cheque_Date ? new Date(payment.Cheque_Date).toLocaleDateString() : "N/A"}</td>
                            <td>{payment.Cheque_Status || "N/A"}</td>
                            <td>{payment.Payment_Status}</td>
                            <td>{payment.Remarks || "N/A"}</td>
                          </tr>
                        ))
                      ) : (
                        <tr>
                          <td colSpan="10">No payments available</td>
                        </tr>
                      )}
                    </tbody>
                  </table>
                </div>
              </div>
            </div>
          </div>
        </div>

        {loader}
      </div>
    </div>
  );
};

export default ViewAllVendorPayments;
